import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"
import { Module } from "vuex"
import { RootState } from "store/index"
import { ProductDetails } from "api/models/products"

const productModule: Module<ProductDetails, RootState> = {
	actions,
	getters,
	mutations,
	namespaced: true
}

export default productModule
