import { ConversionMap, optional, required } from "@lib/types/import"
import { Document, Download, FileType } from "./types"
import { alphanumeric, date, datetime, numeric } from "@lib/import/convert"
import { isUndefined } from "lodash-es"
import { one, many, def } from "@lib/import/extract"

function convertToByteArray(input: string): Array<Uint8Array> {
	const sliceSize = 512
	const bytes = []

	for (let offset = 0; offset < input.length; offset += sliceSize) {
		const slice = input.slice(offset, offset + sliceSize)
		const byteNumbers = new Array(slice.length)

		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i)
		}

		const byteArray = new Uint8Array(byteNumbers)

		bytes.push(byteArray)
	}

	return bytes
}

export function deserializeBase64File(encodedString: string): File | undefined {
	if (isUndefined(encodedString)) {
		return undefined
	}

	const blobBinary = atob(encodedString)
	const blob = new Blob(convertToByteArray(blobBinary))
	return new File([blob], "") // TODO: get filename from name field?
}

export function serializeBase64File(file: File): Promise<string> {
	const reader = new FileReader()
	return new Promise(resolve => {

		reader.onload = event => {
			let binaryString = ""
			const arrayBuffer = event.target!.result as ArrayBuffer
			const array = new Uint8Array(arrayBuffer)

			for (const bytes of array) {
				binaryString += String.fromCharCode(bytes)
			}

			return resolve(btoa(binaryString))
		}
		reader.readAsArrayBuffer(file)
	})
}

const fileType: ConversionMap<FileType> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, optional],
	name: ["name", alphanumeric, required],
	content: ["content", alphanumeric, optional],
	type: ["type", alphanumeric, optional],
	creationDate: ["createDateTime", datetime, optional]
}

export const oneFileType = one(fileType)

export const downloadConversionMap: ConversionMap<Download> = {
	at: ["at", datetime, required],
	by: ["by", alphanumeric, required]
}

export const documentConversionMap: ConversionMap<Document> = {
	id: ["id", alphanumeric, required],
	identifier: ["identifier", alphanumeric, required],
	bucket: ["bucket", alphanumeric, required],
	title: ["title", alphanumeric, optional],
	type: ["type", alphanumeric, optional],
	published: ["published", date, optional],
	fileName: ["fileName", alphanumeric, required],
	mimeType: ["mimeType", alphanumeric, optional],
	size: ["size", numeric, optional],
	created: ["created", datetime, required],
	modified: ["modified", datetime, required],
	downloads: ["downloads", def([], many(downloadConversionMap)), required]
}

export const manyDocuments = many(documentConversionMap)

export const oneDocument = one(documentConversionMap)
