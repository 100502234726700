
	import Vue from "vue"
	import Component from "vue-class-component"
	import Badge from "@components/supplemental/Badge.vue"
	import Hamburger from "custom/Hamburger.vue"
	import IcsIcon from "custom/ui/IcsIcon.vue"
	import IconLabel from "@components/supplemental/IconLabel.vue"
	import LinkButton from "@components/button/LinkButton.vue"
	import MenuModal from "custom/modal/MenuModal.vue"
	import BigSwitch from "custom/BigSwitch.vue"
	import { StoreAction } from "@lib/types/vuex"
	import { userModule, authenticationModule } from "store/modules"

	@Component({
		components: {
			Badge,
			Hamburger,
			LinkButton,
			MenuModal,
			IconLabel,
			BigSwitch,
			IcsIcon
		}
	})
	export default class PageHeader extends Vue {
		@authenticationModule.Getter loggedIn!: boolean
		@authenticationModule.Action logout!: StoreAction<void>
		@userModule.Getter name!: string

		readonly baseTranslationKey = "pageHeader"

		menuModalOpened = false
	}
