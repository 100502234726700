
	import Vue from "vue"
	import Component from "vue-class-component"
	import QuickAction from "custom/QuickAction.vue"
	import { ParticipantsDataChannel } from "store/request-product/types"
	import { productModule } from "store/modules"

	@Component({
		components: {
			QuickAction
		}
	})
	export default class ProductChanges extends Vue {
		@productModule.Getter dataDeliveryChannel?: ParticipantsDataChannel

		readonly baseTranslationKey = "product.productChanges"
		readonly participantsDataChannel = ParticipantsDataChannel

		pensiondisplay = false

		isChangePension(): void {
			this.pensiondisplay = true
		}
	}
