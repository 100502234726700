
	import Vue from "vue"
	import Component from "vue-class-component"

	import PageHeader from "custom/PageHeader.vue"
	import PageFooter from "custom/PageFooter.vue"
	import IcsToaster from "custom/ui/IcsToaster.vue"

	@Component({
		components: {
			PageHeader,
			PageFooter,
			IcsToaster
		}
	})
	export default class Page extends Vue {
		year = new Date().getFullYear()
	}
