import { resources } from "api/resources"
import { authenticationApi as api } from "api/index"

import { isJwtToken, JwtToken } from "@lib/types/security"

class TwoFactorAuthenticationClient {
	private readonly resources = resources.user

	async getQrCode(): Promise<string> {
		const response = await api.get(this.resources.getQrCode).response
		return await response.text()
	}

	async is2FaActivated(): Promise<boolean> {
		const response = await api.get(this.resources.is2FaActivated).response
		return await response.json()
	}

	async authenticate(code: string): Promise<JwtToken> {
		const response = await api.get(this.resources.authenticateTotp(code)).response
		if (response.status === 401) {
			throw (await response.text())
		}
		const jwtToken = await response.json()

		if (!isJwtToken(jwtToken)) {
			throw Error("invalid JwtToken")
		}

		return jwtToken
	}

	async authenticateFirstTime(code: string): Promise<JwtToken> {
		const response = await api.get(this.resources.authenticateTotpFirstTime(code)).response
		if (response.status === 401) {
			throw (await response.text())
		}
		const jwtToken = await response.json()

		if (!isJwtToken(jwtToken)) {
			throw Error("invalid JwtToken")
		}

		return jwtToken
	}

	async isEnabled(): Promise<boolean> {
		const response = await api.get(this.resources.is2faEnabled).response

		return await response.json()
	}

}

export default new TwoFactorAuthenticationClient()
