
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"
	import { Insurance, Quotation, YieldType } from "store/quotation/types"
	import { AgreementConstraints, CoverageOption } from "store/agreement/constraints/types"

	import eventBus from "custom/quotation/event"
	import { SmoothScrollOptions, scrollToElement } from "@components/@directives/smoothscroll"
	import { MaximumSalary, Franchise, SurvivorsPensionType, ScaleType } from "api/models/shared"
	import { ProductFrameworkType } from "api/models/products"
	import { quotationModule, constraintsModule } from "store/modules"

	enum AgreementScalePercentage {
		TEN = 10,
		HUNDRED = 100
	}

	@Component
	export default class QuotationSummaryPanel extends Vue {
		@Prop({ type: Object, required: true }) model!: Quotation

		@quotationModule.Getter productName!: string
		@quotationModule.Getter quotationProductType!: string
		@constraintsModule.Getter("state") constraints!: AgreementConstraints

		readonly baseTranslationKey: string = "newQuotation.summary"
		readonly scaleType = ScaleType

		SurvivorsPensionType = SurvivorsPensionType
		Franchise = Franchise
		MaximumSalary = MaximumSalary
		YieldType = YieldType
		AgreementScalePercentage = AgreementScalePercentage
		ProductFrameworkType = ProductFrameworkType

		private readonly scrollOptions: SmoothScrollOptions = {
			offset: -108,
			duration: 1000,
			delay: 100
		}

		mounted(): void {
			eventBus.$on("scrollToRef", (ref: string, event: MouseEvent) => {
				const element: HTMLElement = this.$refs[ref] as HTMLElement
				scrollToElement(element, this.scrollOptions, event)
			})
		}

		getValueForFranchise(franchise: Franchise): number {
			return this.constraints.franchise.options.find(option => option.constant === franchise)?.value || this.model.franchise?.value || 0
		}

		getCoverageDefaults(insurance: Insurance): object {
			if (!insurance.anwGap || !insurance.anwGap.coverageDefault) {
				return { percentage: 0, amount: 0 }
			}

			return { percentage: insurance.anwGap.coverageDefault.percentage, amount: insurance.anwGap.coverageDefault.amount ?? 0, year: this.currentYear }
		}

		formatDecimalNumber(percentage: number): string {
			return percentage.toFixed(this.maximumDecimalPlaces(percentage) + 1).replace(".", ",").slice(0,-1)
		}

		translateTransitionalName(transitional: any): string {
			switch (transitional) {
				case 2: return "2,00%"
				case 2.5: return "2,50%"
				case 3: return "3,00%"
				case 4: return "4,00%"
				case 38: return "38r"
				default: return ""
			}
		}

		maximumDecimalPlaces(percentage: number): number {
			if (!this.isWTP) {
				if (percentage.toString().includes('.')) {
					return Math.min(percentage.toString().split('.')[1].length, 3)
				}
				return 3
			}
			return 2
		}

		get isWTP(): boolean {
			return this.quotationProductType === ProductFrameworkType.WTP
		}

		get franchiseValues(): any {
			return {
				unMarriedAveragePayValue: this.getValueForFranchise(Franchise.UNMARRIED_AVERAGE_PAY),
				marriedAveragePayValue: this.getValueForFranchise(Franchise.MARRIED_AVERAGE_PAY),
				unMarriedFinalPayValue: this.getValueForFranchise(Franchise.UNMARRIED_FINAL_PAY),
				marriedFinalPayValue: this.getValueForFranchise(Franchise.MARRIED_FINAL_PAY),
				unMarriedWitteveenValue: this.getValueForFranchise(Franchise.UNMARRIED_WITTEVEEN),
				marriedWitteveenValue: this.getValueForFranchise(Franchise.MARRIED_WITTEVEEN)
			}
		}

		get currentYear(): number {
			 return new Date(this.model.startDate!).getFullYear()
		}

		get maximumSalaryValues(): any {
			return {
				year: new Date(this.model.startDate!).getFullYear(),
				fiscalMax: this.model.maximumSalary?.value || 0
			}
		}

		get scaleDecimals(): number {
			return this.model.scale?.type === ScaleType.AGE_DEPENDENT ? 0 : 1
		}

		get activeCoverageOptions(): Array<CoverageOption> {
			return this.model.insurance?.anwGap?.coverageOptions?.filter(option => option.active) || []
		}

		get agreementScalePercentage(): number {
			const rate = this.model?.scale?.utilizationRate || 0
			return rate / 10
		}
	}
