export interface Content {
	title: string,
	options: Array<ContentOption>
}

export interface ContentOption {
	ref: string,
	children?: Array<ContentOption>
}

export const initTableOfContents: Array<ContentOption> = [
	{
		ref: "baseData"
	}
]

export const summaryTableOfContents: Array<ContentOption> = [
	{
		ref: "summary"
	}
]

export const formTableOfContents: Array<ContentOption> = [
	{
		ref: "baseData"
	},
	{
		ref: "customerData"
	},
	{
		ref: "pensionParticipation",
		children: [
			{
				ref: "enrollmentAge"
			},
			{
				ref: "pensionAge"
			}
		]
	},
	{
		ref: "pensionableSalary",
		children: [
			{
				ref: "maxPensionableSalary"
			},
			{
				ref: "salaryComponents"
			},
			{
				ref: "salaryPeriod"
			},
			{
				ref: "franchise"
			}
		]
	},
	{
		ref: "availablePremium",
		children: [
			{
				ref: "scale"
			},
			{
				ref: "paymentTerm"
			}
		]
	},
	{
		ref: "employeeContribution"
	},
	{
		ref: "partnerAndOrphanPension"
	},
	{
		ref: "additionalArrangements"
	},
	{
		ref: "investmentChoices",
		children: [
			{
				ref: "investmentFreedom"
			}
		]
	},
	{
		ref: "participantData",
		children: [
			{
				ref: "numberOfParticipants"
			},
			{
				ref: "uploadFile"
			}
		]
	},
	{
		ref: "financialYield"
	}
]
