
	import Vue from "vue"
	import Component from "vue-class-component"

	import { Prop, Watch } from "vue-property-decorator"
	import { isEqual } from "lodash-es"
	import { OptionModel } from "custom/quotation/model/option-model"

	@Component
	export default class IcsRadiobuttonGroup<T extends OptionModel> extends Vue {
		@Prop({ type: Boolean, default: false }) tooltip!: boolean
		@Prop({ type: Boolean, default: false }) labelTooltip!: boolean
		@Prop({ type: Boolean, default: false }) inline!: boolean
		@Prop({ type: Boolean, default: false }) disabled!: boolean
		@Prop({ type: Array, default: () => [] }) options!: Array<T>
		@Prop({ type: [String, Number, Boolean] }) value?: string | number | boolean
		@Prop({ type: Boolean, default: false }) noLabel!: boolean

		internalValue: number = -1

		@Watch("options", { immediate: true })
		@Watch("value", { immediate: true })
		valueChanged(): void {
			// this.value can be false, which is why we check like this.
			if (this.value === null || this.value === undefined) {
				return
			}

			this.internalValue = this.options.findIndex(option => isEqual(option.value, this.value))
		}

		onChange(): void {
			const selected = this.options[this.internalValue]?.value
			this.$emit("input", selected)
			this.$emit("change", selected)
		}
	}
