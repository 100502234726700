
	import Vue from "vue"
	import Component from "vue-class-component"

	import { Prop, Watch } from "vue-property-decorator"
	import { isUndefined, isEqual } from "lodash-es"
	import { OptionModel } from "custom/quotation/model/option-model"

	@Component
	export default class IcsDropdown<T extends OptionModel> extends Vue {
		@Prop({ type: Boolean, default: false }) required!: boolean
		@Prop({ type: Boolean, default: false }) inline!: boolean
		@Prop({ type: Boolean, default: false }) disabled!: boolean
		@Prop({ type: Boolean, default: false }) noLabel!: boolean
		@Prop({ type: Boolean, default: false }) alignRight!: boolean
		@Prop({ type: Boolean, default: false }) tooltip!: boolean
		@Prop({ type: Array, default: () => [] }) options!: Array<T>
		@Prop({ type: [String, Number, Boolean, Object] }) value?: string | number | boolean | object

		internalValue: number = -1

		@Watch("options", { immediate: true })
		@Watch("value", { immediate: true })
		valueChanged(): void {
			if (isUndefined(this.value) || this.value === "") {
				return
			}

			this.internalValue = this.options.findIndex(option => isEqual(option.value, this.value))
		}

		get hidePlaceholder(): boolean {
			return !!this.value && this.required
		}

		onChange(): void {
			const selected = this.options[this.internalValue]?.value
			this.$emit("input", selected)
			this.$emit("change", selected)
		}
	}
