import { api } from "api/index"
import { resources } from "api/resources"

import eventBus from "@lib/vue/eventBus"

import { CalculationSuccessResult, CalculationErrorResult, CalculationStatus, CalculationUpdate, Costs, Quotation, ParticipantsFileResponse } from "store/quotation/types"
import { oneCalulation, oneParticipantsFileResponse, oneQuotation } from "store/quotation/conversion"

import localStorageClient from "src/api/clients/local-storage-client"
import { RequestProductModel, SignProductRequestModel } from "store/request-product/types"
import { serializeBase64File } from "store/conversion"

export const CALCULATION_NEVER_STARTED_CODE = 404
export const QUOTE_CALCULATION_FINISHED = "QUOTE_CALCULATION_FINISHED"
export const QUOTE_CALCULATION_FAILED = "QUOTE_CALCULATION_FAILED"
export const QUOTE_CALCULATION_STATUS_UPDATE = "QUOTE_CALCULATION_STATUS_UPDATE"

const CALCULATION_CHECK_TIMEOUT = 1000 * 10
const STORAGE_KEY = "calculations"
const FILE_ALREADY_UPLOADED_CODE = 409

class QuotationClient {
	private readonly quotesCalculating: Map<string, NodeJS.Timeout> = new Map()

	constructor(private readonly storage: Storage) {
		this.restartLoaders()
	}

	async getQuotation(id: string): Promise<Quotation> {
		const url = resources.quotation.getQuote(id)
		const response = await api.get(url).response
		return oneQuotation(await response.json())
	}

	async deleteQuotation(id: string): Promise<boolean> {
		const url = resources.quotation.deleteQuote(id as string)
		try {
			await api.delete(url).response
			return true
		} catch (error) {
			return false
		}
	}

	async deleteCosts(id: string): Promise<void> {
		const url = resources.quotation.calculationCosts(id)
		await api.delete(url).response
	}

	async createQuote(id: string): Promise<Quotation> {
		const url = resources.quotation.createQuote(id)
		const response = await api.post(url).response
		return oneQuotation(await response.json())
	}

	async declineQuote(id: string): Promise<Quotation> {
		const url = resources.quotation.declineQuote(id)
		const response = await api.post(url).response
		return oneQuotation(await response.json())
	}

	async checkCalculationStatus(id: string): Promise<Costs> {
		const statusUrl = resources.quotation.calculationCosts(id)

		const response = await api.get(statusUrl).response
		return oneCalulation(await response.json())
	}

	async createConceptCopy(id: string): Promise<Quotation> {
		const url = resources.quotation.createConceptCopy(id)
		const response = await api.post(url).response
		return oneQuotation(await response.json())
	}

	async requestProduct(id: string): Promise<Quotation> {
		const url = resources.quotation.requestProduct(id)
		const response = await api.post(url).response
		return oneQuotation(await response.json())
	}

	async createImplementationAgreement(id: string, data: RequestProductModel): Promise<Quotation> {
		const url = resources.quotation.createImplementationAgreement(id)
		const response = await api.post(url, data).response
		return oneQuotation(await response.json())
	}

	async createProcessAgreementSignature(id: string, data: SignProductRequestModel): Promise<Quotation> {
		const url = resources.quotation.processAgreementSignature(id)
		const response = await api.post(url, data).response
		return oneQuotation(await response.json())
	}

	async uploadParticipantFile(file: File, quotationId: string): Promise<ParticipantsFileResponse> {
		const url = resources.quotation.uploadFile(quotationId)
		const payload = { content: await serializeBase64File(file), name: file.name }

		let response = await api.post(url, payload).response
		if (response.status === FILE_ALREADY_UPLOADED_CODE) {
			response = await api.put(url, payload).response
		}
		return oneParticipantsFileResponse(await response.json())
	}

	async calculateQuote(id: string): Promise<void> {
		const url = resources.quotation.calculate(id)

		try {
			const response = await api.post(url).response

			if (!response.ok) {
				const { errors } = await response.json()
				if (errors) {
					eventBus.emit<CalculationErrorResult>(QUOTE_CALCULATION_FAILED, {
						quoteId: id,
						error: new Error(CalculationStatus.CALCULATION_FAILED),
						fileErrors: errors
					})
				}

				return
			}

			eventBus.emit<CalculationUpdate>(QUOTE_CALCULATION_STATUS_UPDATE, { quoteId: id, status: CalculationStatus.CALCULATING })
			this.handleCalculationForQuote(id)
		} catch (error) {
			eventBus.emit<CalculationErrorResult>(QUOTE_CALCULATION_FAILED, { quoteId: id, error })
		}
	}

	private handleCalculationForQuote(id: string): void {
		const timeout = setTimeout(async () => {
			try {
				this.quotesCalculating.delete(id)
				this.saveCalculatingQuotes()
				const costs = await this.checkCalculationStatus(id)

				eventBus.emit<CalculationUpdate>(QUOTE_CALCULATION_STATUS_UPDATE, { quoteId: id, status: costs.status })

				switch (costs.status) {
					case CalculationStatus.CALCULATED:
						eventBus.emit<CalculationSuccessResult>(QUOTE_CALCULATION_FINISHED, { quoteId: id, costs })
						break
					case CalculationStatus.CALCULATING:
						this.handleCalculationForQuote(id)
						break
					default:
						eventBus.emit<CalculationErrorResult>(QUOTE_CALCULATION_FAILED, { quoteId: id, error: new Error(CalculationStatus.CALCULATION_FAILED) })
						break
				}
			} catch (error) {
				eventBus.emit<CalculationErrorResult>(QUOTE_CALCULATION_FAILED, { quoteId: id, error })
			}
		}, CALCULATION_CHECK_TIMEOUT)

		this.quotesCalculating.set(id, timeout)
		this.saveCalculatingQuotes()
	}

	private restartLoaders(): void {
		const quoteIds = this.storage.getItem(STORAGE_KEY)?.split(",").filter(id => id.length > 0)
		if (!quoteIds) {
			return
		}

		for (const id of quoteIds) {
			this.handleCalculationForQuote(id)
		}
	}

	private saveCalculatingQuotes(): void {
		const keys = Array.from(this.quotesCalculating.keys())
		localStorage.setItem(STORAGE_KEY, keys.toString())
	}
}

export default new QuotationClient(localStorageClient)
