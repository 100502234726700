
	import Vue from "vue"
	import Component from "vue-class-component"

	import { Prop } from "vue-property-decorator"

	@Component
	export default class IcsButton extends Vue {
		@Prop({ type: Boolean, default: false }) primary!: boolean
		@Prop({ type: Boolean, default: false }) secondary!: boolean
		@Prop({ type: Boolean, default: false }) textLink!: boolean
		@Prop({ type: Boolean, default: false }) wide!: boolean
		@Prop({ type: Boolean, default: false }) disabled!: boolean
		@Prop({ type: String, default: "button" }) type!: "button" | "submit" | "reset"
		@Prop({ type: Boolean }) loading?: boolean

		onClick(event: MouseEvent): void {
			if (this.disabled) {
				return
			}

			this.$emit("click", event)
		}
	}
