var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"ics-button",class:{ 'primary': _vm.primary || (!_vm.secondary && !_vm.textLink),
					'secondary': _vm.secondary, 
					'text-link font-icon font-icon--arrow-left': _vm.textLink,
					'wide': _vm.wide, 
					'disabled': _vm.disabled || _vm.loading === true,
					'pending-indicator--active': _vm.loading === true
				},attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){return _vm.onClick($event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }