import { DataSource, Cardinality, ServerState, Identifiable, ObjectId } from "../../types/model"
import RestApi from "../../request/RestApi"
import { ConversionFunction } from "../../types/import"
import { Request } from "../../types/request"
import { many } from "../../import/extract"

import { isUndefined } from "lodash-es"

/**
 * Implements `DataSource` over a REST interface.
 */
export default class RestDataSource<T extends Identifiable, C extends Cardinality> implements DataSource<T, C> {
	private retrieval?: Promise<ServerState<T, C> | undefined>
	private readonly convert: ConversionFunction<ServerState<T, C>>
	private readonly saveRequests = new Map<ObjectId, Request>()

	constructor(
		private readonly restApi: RestApi,
		private readonly resource: string,
		private readonly convertOne: ConversionFunction<T>,
		private readonly cardinality: C
	) {
		this.convert = (cardinality === Cardinality.ONE ? convertOne : many(convertOne)) as ConversionFunction<ServerState<T, C>>
	}

	retrieve(): Promise<ServerState<T, C> | undefined> {
		if (!this.retrieval) {
			this.retrieval = this.restApi.getJson(this.resource).then(this.convert)
		}
		return this.retrieval
	}

	async save(data: T): Promise<T | undefined> {
		// The id of a singleton object can be null when stored. So only use post when the id is undefined.
		const request = isUndefined(data.id) ? this.post(data) : this.put(data)
		this.saveRequests.set(data.id, request)

		try {
			const response = await request.response
			this.saveRequests.delete(data.id)

			if (!response.ok) {
				return undefined
			}

			const result = await response.json()
			return this.convertOne(result)
		} catch (error) {
			if (error instanceof DOMException) {
				console.warn(error)
			} else {
				throw error
			}
			return undefined
		}
	}

	private post(data: T): Request {
		const runningRequest = this.saveRequests.get(data.id)
		if (runningRequest) {
			throw new Error("Parallel POST requests not allowed")
		}

		return this.restApi.post(this.resource, data)
	}

	private put(data: T): Request {
		const runningRequest = this.saveRequests.get(data.id)
		if (runningRequest) {
			runningRequest.abort()
		}

		return this.restApi.put(`${ this.resource }${ this.cardinality === Cardinality.ONE ? "" : `/${ data.id }` }`, data)
	}

	async delete(id?: ObjectId): Promise<boolean> {
		const response = await this.restApi.delete(`${ this.resource }${ this.cardinality === Cardinality.ONE ? "" : `/${ id }` }`).response
		return response.ok
	}

}
