
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"
	import { Content, ContentOption } from "custom/quotation/model/table-of-contents"

	@Component
	export default class TableOfContents extends Vue {
		@Prop({ type: Object, required: true }) content!: Content
		@Prop({ type: String, default: "" }) translationKey!: string

		get baseTranslationKey(): string {
			const tableOfContentsTranslationKey = "tableOfContents"
			if (this.translationKey === "") {
				return tableOfContentsTranslationKey
			}

			return `${this.translationKey}.${tableOfContentsTranslationKey}`
		}

		optionSelected(option: ContentOption, event: MouseEvent): void {
			this.$emit("optionSelected", option.ref, event)
		}
	}
