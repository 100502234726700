
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	@Component
	export default class QuickAction extends Vue {
		@Prop({ type: String, required: true }) href!: string
		@Prop({ type: String, default: "_self" }) target!: string
	}
