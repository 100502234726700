
	import LoadingComponent from "custom/LoadingComponent"
	import Component from "vue-class-component"
	import { Column } from "@components/data/view/types"
	import DataView from "@components/data/view/DataView.vue"
	import Grid from "custom/table/Grid.vue"
	import FilterSelect from "custom/table/FilterSelect.vue"
	import FilterField from "custom/table/FilterField.vue"
	import { Prop, Watch } from "vue-property-decorator"
	import { Document, FileType } from "store/types"
	import productClient from "api/clients/product-client"
	import { deserializeBase64File } from "store/conversion"
	import toaster from "custom/utils/toaster"
	import { productModule } from "store/modules"
	import Loader from "custom/Loader.vue"

	@Component({
		components: {
			DataView,
			Grid,
			FilterField,
			FilterSelect,
			Loader
		}
	})
	export default class ProductFile extends LoadingComponent {
		@Prop({ type: String, required: true }) companyId!: string

		@productModule.Getter agreementId!: string

		readonly baseTranslationKey = "product.productFile"
		readonly columns: Array<Column<Document>> = [
			{ title: `${ this.baseTranslationKey }.column.date`, field: "created" },
			{ title: `${ this.baseTranslationKey }.column.name`, field: "fileName" }
		]

		documents: Array<Document> = []
		showNoFilesError = false

		@Watch("agreementId")
		async agreementIdChanged(agreementId: string): Promise<void> {
			try {
				this.documents = await this.manageLoadingState(productClient.getDocumentsByProductId(agreementId, this.companyId))
				if (!this.documents.length) {
					this.showNoFilesError = true
				}
			} catch (error) {
				toaster.error({ title: "general.error" })
				throw new Error(error)
			}
		}

		async downloadDocument(document: Document): Promise<void> {
			if (!document.identifier) {
				return
			}

			const documentDownload: FileType | undefined = await productClient.getDocumentContentById(this.agreementId, document.identifier, this.companyId)
			this.extractFile(documentDownload)
		}

		private extractFile(documentDownload: FileType | undefined): void {
			if (!documentDownload?.content) {
				return
			}

			const file = deserializeBase64File(documentDownload.content)

			if (!file) {
				return
			}

			this.downloadBlob(file, documentDownload)
		}

		private downloadBlob(file: File, fileInfo: FileType): void {
			const url = URL.createObjectURL(new File([file], fileInfo.name))

			const downloadLink: HTMLAnchorElement = document.createElement("a")
			downloadLink.href = url
			downloadLink.download = `${ fileInfo.name }.${ fileInfo.type }`
			downloadLink.hidden = true

			document.body.appendChild(downloadLink)
			downloadLink.click()

			document.body.removeChild(downloadLink)
			URL.revokeObjectURL(url)
		}
	}
