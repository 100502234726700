import { GetterTree } from "vuex"
import { RootState } from "store/index"
import { User, UserState } from "./types"

export enum USER_GETTERS {
	USER = "user",
	IS_USER_LOADING = "isUserLoading",
	HAS_ERROR = "hasError",
	ID = "id",
	IS_ACTIVATING = "isActivating",
	NAME = "name",
	EMAIL = "email"
}

const getterTree: GetterTree<UserState, RootState> = {
	[USER_GETTERS.USER](state: UserState): User {
		return state.user
	},
	[USER_GETTERS.IS_USER_LOADING](state: UserState): boolean {
		return state.loading
	},
	[USER_GETTERS.HAS_ERROR](state: UserState): boolean {
		return state.hasError
	},
	[USER_GETTERS.ID](state: UserState): string | undefined {
		return state.user.id
	},
	[USER_GETTERS.IS_ACTIVATING](state: UserState): boolean {
		return state.isActivating
	},
	[USER_GETTERS.NAME](state: UserState): string | undefined {
		return state.user.fullName
	},
	[USER_GETTERS.EMAIL](state: UserState): string | undefined {
		return state.user.email
	}
}

export default getterTree
