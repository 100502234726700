
	import Vue from "vue"
	import Component from "vue-class-component"

	import {Prop, Watch} from "vue-property-decorator"

	@Component
	export default class IcsCheckbox extends Vue {
		@Prop({type: Boolean, default: false}) toggle!: boolean
		@Prop({type: Boolean, default: false}) inline!: boolean
		@Prop({type: Boolean, default: false}) disabled!: boolean
		@Prop({type: Boolean, default: false}) value!: boolean

		internalValue: boolean = false

		@Watch("value", {immediate: true})
		valueChanged(): void {
			this.internalValue = this.value
		}

		onChange(): void {
			this.$emit("input", this.internalValue)
		}
	}
