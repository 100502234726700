
	import Vue from "vue"
	import Component from "vue-class-component"

	import IcsButton from "custom/ui/IcsButton.vue"
	import IcsInput from "custom/ui/IcsInput.vue"
	import userClient from "api/clients/user-client"

	@Component({
		components: {
			IcsButton,
			IcsInput
		}
	})
	export default class EmailVerification extends Vue {
		readonly baseTranslationKey = "login.emailVerification"

		verificationCode: string | null = null
		invalidVerificationCode = false

		async submitVerification(): Promise<void> {
			if (!this.verificationCode) {
				return
			}

			const tokenValid = await userClient.checkTokenValidity(this.verificationCode)

			if (tokenValid) {
				this.$emit("emailVerified", this.verificationCode)
			} else {
				this.invalidVerificationCode = true
			}
		}
	}
