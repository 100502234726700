
	import Vue from "vue"
	import Component from "vue-class-component"
	import { SHOW_TOAST, Toast, eventBus } from "custom/utils/toaster"

	@Component
	export default class IcsToaster extends Vue {
		readonly toasts: Array<Toast> = []

		mounted(): void {
			eventBus.$on(SHOW_TOAST, async (toast: Toast) => {
				await this.show(toast)
			})
		}

		private show(toast: Toast): Promise<void> {
			return new Promise<void>(resolve => {
				this.toasts.push(toast)

				setTimeout(() => {
					this.toasts.splice(this.toasts.indexOf(toast), 1)
					resolve()
				}, toast.durationMs)
			})
		}

		destroyed(): void {
			eventBus.$off(SHOW_TOAST)
		}
	}
