import { ConversionMap, required, optional } from "@lib/types/import"
import {
	Quotation,
	QuotationStatus,
	PaymentTerm,
	PayrollPeriod,
	SalaryComponent,
	YieldType,
	Company,
	Costs,
	Scale,
	Insurance,
	SurvivorsPension,
	AnwGap,
	CoverageDefault,
	YearsOfServiceDetermination,
	ParticipantsFile,
	CalculationStatus,
	ParticipantsFileResponse
} from "./types"
import { alphanumeric, enumConstant, numeric, bool, datetime, alphanumericDate } from "@lib/import/convert"
import { one, many, def, mandatory } from "@lib/import/extract"
import { EnumOption } from "store/agreement/constraints/types"
import { Franchise, MaximumSalary, ScaleType, SurvivorsPensionType, InvestmentFreedom } from "api/models/shared"
import { coverageOption } from "store/agreement/constraints/conversion"

const company: ConversionMap<Company> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, optional],
	name: ["name", alphanumeric, required]
}

// TODO: Make a generic conversion map using a generic enum type.
export const franchise: ConversionMap<EnumOption<Franchise, number>> = {
	constant: ["constant", enumConstant(Franchise), required],
	value: ["value", numeric, optional]
}

export const maximumSalary: ConversionMap<EnumOption<MaximumSalary, number>> = {
	constant: ["constant", enumConstant(MaximumSalary), required],
	value: ["value", numeric, optional]
}

export const scale: ConversionMap<Scale> = {
	type: ["type", enumConstant(ScaleType), optional],
	percentage: ["percentage", numeric, optional],
	utilizationRate: ["utilizationRate", numeric, optional],
	fixedBasePremium: ["fixedBasePremium", numeric, optional]
}

export const coverageDefault: ConversionMap<CoverageDefault> = {
	amount: ["amount", numeric, optional],
	percentage: ["percentage", numeric, required]
}

export const anwGap: ConversionMap<AnwGap> = {
	active: ["active", bool, required],
	coverageOptions: ["coverageOptions", many(coverageOption), optional],
	coverageDefault: ["coverageDefault", one(coverageDefault), optional],
	defaultInsured: ["defaultInsured", def(false, bool), optional],
	hasEmployeeContribution: ["hasEmployeeContribution", bool, optional],
	indexation: ["indexation", numeric, optional]
}

export const insurance: ConversionMap<Insurance> = {
	anwGap: ["anwGap", one(anwGap), optional],
	premiumWaiver: ["premiumWaiver", bool, required],
	extraSavings: ["extraSavings", bool, required]
}

export const survivorsPension: ConversionMap<SurvivorsPension> = {
	type: ["type", enumConstant(SurvivorsPensionType), optional],
	partnerAccrual: ["partnerAccrual", numeric, optional],
	orphanAccrual: ["orphanAccrual", numeric, optional],
	indexation: ["indexation", numeric, optional],
	yearsOfServiceDetermination: ["yearsOfServiceDetermination", enumConstant(YearsOfServiceDetermination), optional]
}

const yieldType: ConversionMap<EnumOption<YieldType, number>> = {
	constant: ["constant", enumConstant(YieldType), required],
	value: ["value", numeric, optional]
}

const costs: ConversionMap<Costs> = {
	status: ["status", enumConstant(CalculationStatus), required],
	pensionAmount: ["pensionAmount", numeric, optional],
	partnerPensionAmount: ["partnerPensionAmount", numeric, optional],
	orphanPensionAmount: ["orphanPensionAmount", numeric, optional],
	anwPensionAmount: ["anwPensionAmount", numeric, optional],
	administrationCosts: ["administrationCosts", numeric, optional],
	totalCosts: ["totalCosts", numeric, optional],
	participantsContribution: ["participantsContribution", numeric, optional]
}

const participantsFileType: ConversionMap<ParticipantsFile> = {
	participantsFileName: ["participantsFileName", alphanumeric, optional]
}

const participantsFileResponse: ConversionMap<ParticipantsFileResponse> = {
	...participantsFileType,
	errors: ["errors", many(alphanumeric), optional]
}

const quotation: ConversionMap<Quotation> = {
	id: ["id", alphanumeric, required],
	status: ["status", def(QuotationStatus.UNKNOWN, enumConstant(QuotationStatus)), required],
	agreementQuoteId: ["agreementQuoteId", alphanumeric, optional],
	productAgreementId: ["productAgreementId", alphanumeric, optional],
	startDate: ["startDate", alphanumericDate, optional],
	quoteReference: ["quoteReference", alphanumeric, optional],
	company: ["company", one(company), optional],
	enrollmentAge: ["enrollmentAge", numeric, optional],
	pensionAge: ["pensionAge", numeric, optional],
	franchise: ["franchise", one(franchise), optional],
	maximumSalary: ["maximumSalary", one(maximumSalary), optional],
	scale: ["scale", one(scale), optional],
	paymentTerm: ["paymentTerm", enumConstant(PaymentTerm), optional],
	payrollPeriod: ["payrollPeriod", enumConstant(PayrollPeriod), optional],
	pensionableSalaryComponents: ["pensionableSalaryComponents", many(enumConstant(SalaryComponent)), optional],
	insurance: ["insurance", one(insurance), optional],
	investmentFreedom: ["investmentFreedom", enumConstant(InvestmentFreedom), optional],
	survivorsPension: ["survivorsPension", one(survivorsPension), optional],
	participantCount: ["participantCount", numeric, optional],
	financialYield: ["financialYield", one(yieldType), optional],
	employeeContributionPercentage: ["employeeContributionPercentage", numeric, optional],
	costs: ["costs", one(costs) , optional],
	lastUpdated: ["lastUpdated", datetime, required],
	agreementId: ["agreementId", alphanumeric, optional],
	prognosisFileId: ["prognosisFileId", alphanumeric, optional],
	...participantsFileType
}

export const oneParticipantsFileResponse = mandatory(one(participantsFileResponse))
export const oneCalulation = mandatory(one(costs))
export const oneQuotation = mandatory(one(quotation))
export default one(quotation)
