
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	import PButton from "@portal-components/ui/input/PButton.vue"
	import PTextInput from "@portal-components/ui/input/PTextInput.vue"

	import LoadingComponent from "custom/LoadingComponent"

	import twoFactorAuthenticationClient from "api/clients/2fa-client"

	import { JwtToken } from "lib/types/security"
	import { authenticationModule } from "store/modules"
	import { StoreAction } from "@lib/types/vuex"

	@Component({
		components: {
			PButton,
			PTextInput
		}
	})
	export default class ResponseCode extends LoadingComponent {
		@Prop({ type: Boolean, default: false }) firstTime!: boolean
		@authenticationModule.Action logout!: StoreAction<string>

		readonly baseTranslationKey = "login.responseCode"

		locale = Vue.filter("locale")

		responseCode: string | null = null
		invalidResponseCode = false
		errorMessage = ""

		async timeOut(): Promise<void> {
			await this.logout()
			location.href = "login.html"
		}

		created(): void {
			setTimeout(() => this.timeOut(), 300000)
		}

		async authenticate2Fa(): Promise<void> {
			if (!this.responseCode) {
				return
			}

			this.invalidResponseCode = false

			try {
				let token: JwtToken
				if (this.firstTime) {
					token = await this.manageLoadingState(twoFactorAuthenticationClient.authenticateFirstTime(this.responseCode))
				} else {
					token = await this.manageLoadingState(twoFactorAuthenticationClient.authenticate(this.responseCode))
				}

				this.$emit("response-code-verified", token)
			} catch (error) {
				if (error.message === "Account Locked") {
					this.errorMessage = this.locale("login.error.ACCOUNT_LOCKED")
				} else {
					this.errorMessage = this.locale("login.responseCode.error")
				}
				this.invalidResponseCode = true
			}
		}
	}
