import RestDataSource from "@lib/model/datasource/RestDataSource"
import { Cardinality, ImmutableModuleConfig } from "@lib/types/model"
import { api } from "api/index"
import { resources } from "api/resources"
import { RootState } from "store/index"
import convert from "./conversion"
import { Agreement } from "./types"

const moduleConfig: Partial<ImmutableModuleConfig<Agreement, Cardinality.MANY, RootState>> = {
	dataSource: new RestDataSource<Agreement, Cardinality.MANY>(
		api,
		resources.agreement.getAgreements,
		convert,
		Cardinality.MANY
	)
}

export default moduleConfig
