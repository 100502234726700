
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop, Watch} from "vue-property-decorator"
	import {isDate} from "lodash-es"
	import formatYmd from "@lib/date/formatYmd"
	import adjust, {Adjust} from "@lib/date/adjust"

	@Component
	export default class IcsInput extends Vue {
		@Prop({ type: String, default: "text" }) type!: string
		@Prop({ type: String, default: " "}) placeholder!: string
		@Prop({ type: Boolean, default: false }) required!: boolean
		@Prop({ type: Boolean, default: false }) readonly!: boolean
		@Prop({ type: Boolean, default: false }) inline!: boolean
		@Prop({ type: Boolean, default: false }) floatLabel!: boolean
		@Prop({ type: Boolean, default: false }) noLabel!: boolean
		@Prop({ type: Boolean, default: false }) disabled!: boolean
		@Prop({ type: Boolean, default: false }) appendLabel!: boolean
		@Prop({ type: Boolean, default: false }) tooltip!: boolean
		@Prop({ type: Boolean, default: false }) autofocus!: boolean
		@Prop({ default: "" }) value!: string | number
		@Prop({ type: String, default: "" }) validationErrorMessage!: string

		// Number / date options
		@Prop({ type: [Number, Date] }) min?: number | Date
		@Prop({ type: [Number, Date] }) max?: number | Date
		@Prop({ type: Number, default: 1 }) stepSize!: number

		internalType: string = ""
		internalValue: string | number = ""

		@Watch("type", {immediate: true})
		typeChanged(): void {
			this.internalType = this.toInternalType(this.type)
		}

		@Watch("value", {immediate: true})
		valueChanged(): void {
			if (this.internalType === "date" && isDate(this.value)) {
				this.internalValue = formatYmd(this.value)
			} else {
				this.internalValue = this.value
			}
		}

		get normalizedValue(): string | number | Date | undefined {
			let value: string | number | Date | undefined = this.internalValue
			switch (this.internalType) {
				case "date": {
					const date = new Date(value)
					if (isNaN(date.getTime()) || date.getFullYear() < 1000) {
						return
					}
					value = adjust(Adjust.UTC_TO_LOCAL, date)
					break
				}
				case "number": {
					value = parseFloat(value as string)
					break
				}
			}

			return value
		}

		toInternalType(type: string): string {
			if (type === "text" || type === "date" || type === "password" || type === "email") {
				return type
			}

			return "number"
		}

		onValidate(): void {
			this.$emit("blur", this.normalizedValue)
		}

		onChange(): void {
			this.$emit("input", this.normalizedValue)
		}
	}
