import { many, one, merge, mandatory, def } from "@lib/import/extract"
import { numeric, enumConstant, bool, date, alphanumeric } from "@lib/import/convert"
import { ConversionMap, required, ConversionFunction, optional } from "@lib/types/import"
import { PayrollPeriod, PaymentTerm, SalaryComponent, YieldType, YearsOfServiceDetermination} from "store/quotation/types"
import { AgreementConstraints, Default, Options, Boundaries, EnumOption, Range, InsuranceConstraints,
	AnwGapConstraints, CoverageOption, AgeDependentOptions, Percentages, Percentage, TransitionalArrangementOptions, TransitionalArrangementOption } from "./types"
import { Franchise, MaximumSalary, ScaleType, InvestmentFreedom, SurvivorsPensionType } from "api/models/shared"
import { ProductFrameworkType } from "api/models/products"

const defaultObject = <T>(convert: ConversionFunction<T>): ConversionFunction<Default<T>> => {
	const map: ConversionMap<Default<T>> = {
		default: ["default", convert, optional]
	}
	return one(map)
}

const defaultOptionObject = <T>(map: ConversionMap<T>): ConversionFunction<Default<T>> => {
	const defaultMap: ConversionMap<Default<T>> = {
		default: ["default", one(map), optional]
	}
	return one(defaultMap)
}

const optionsObject = <T>(mapOrConvert: ConversionMap<T> | ConversionFunction<T>): ConversionFunction<Options<T>> => {
	const map: ConversionMap<Options<T>> = {
		options: ["options", many(mapOrConvert), required]
	}
	return one(map)
}

const range = <T>(convert: ConversionFunction<T>): ConversionMap<Range<T>> => ({
	minimum: ["minimum", convert, optional],
	maximum: ["maximum", convert, optional]
})

const boundaries = <T>(convert: ConversionFunction<T>): ConversionFunction<Boundaries<T>> => one({
	boundaries: ["boundaries", one(range(convert)), required]
})

const franchise = enumConstant(Franchise)
const maximumSalary = enumConstant(MaximumSalary)
const payrollPeriod = enumConstant(PayrollPeriod)
const paymentTerm = enumConstant(PaymentTerm)
const salaryComponent = enumConstant(SalaryComponent)
const scaleType = enumConstant(ScaleType)
const investmentFreedom = enumConstant(InvestmentFreedom)
const survivorsPensionType = enumConstant(SurvivorsPensionType)
const yieldType = enumConstant(YieldType)
const yearsOfServiceDeterminationType = enumConstant(YearsOfServiceDetermination)
const productFrameworkType = enumConstant(ProductFrameworkType)

const franchiseOption: ConversionMap<EnumOption<Franchise, number>> = {
	constant: ["constant", franchise, required],
	value: ["value", numeric, optional]
}

const maximumSalaryOption: ConversionMap<EnumOption<MaximumSalary, number>> = {
	constant: ["constant", maximumSalary, required],
	value: ["value", numeric, optional]
}

const percentage: ConversionMap<Percentage> = {
	ageRange: ["ageRange", alphanumeric, required],
	percentage: ["percentage", numeric, required],
	derivedPercentage: ["derivedPercentage", numeric, required]
}

const percentages: ConversionMap<Percentages> = {
	"2.0": ["2.0", many(percentage), required],
	// tslint:disable-next-line: object-literal-key-quotes
	"2.5": ["2.5", many(percentage), required],
	"3.0": ["3.0", many(percentage), required],
	"4.0": ["4.0", many(percentage), required],
	"38.0": ["38.0", many(percentage), optional]
}

const ageDependentOptions: ConversionMap<AgeDependentOptions> = {
	values: ["values", merge(defaultObject(numeric), optionsObject(numeric)), required],
	percentages: ["percentages", one(percentages), required]
}

const transitionalArrangementOptions: ConversionMap<TransitionalArrangementOptions> = {
	default: ["default", enumConstant(TransitionalArrangementOption), optional],
	options: ["options", many(enumConstant(TransitionalArrangementOption)), required]
}
const defaultTransitionArrangementOptions: TransitionalArrangementOptions = {
	default: undefined,
	options: [
		TransitionalArrangementOption["2%"],
		TransitionalArrangementOption["2.5%"],
		TransitionalArrangementOption["3%"],
		TransitionalArrangementOption["4%"],
		TransitionalArrangementOption["38r"]
	]
}

const scale: ConversionMap<AgreementConstraints["scale"]> = {
	type: ["type", merge(defaultObject(scaleType), optionsObject(scaleType)), required],
	ageDependentOptions: ["ageDependentOptions", one(ageDependentOptions), optional],
	transitionalArrangementOptions:
		["transitionalArrangementOptions", def(defaultTransitionArrangementOptions, one(transitionalArrangementOptions)), optional],
	ageDependentUtilizationRate: ["ageDependentUtilizationRate", merge(defaultObject(numeric), boundaries(numeric)), optional],
	fixedPremiumUtilizationRate: ["fixedPremiumUtilizationRate", merge(defaultObject(numeric), boundaries(numeric)), required],
	fixedBasePremium: ["fixedBasePremium", merge(defaultObject(numeric), boundaries(numeric)), optional]
}

const survivorsPension: ConversionMap<AgreementConstraints["survivorsPension"]> = {
	type: ["type", merge(defaultObject(survivorsPensionType), optionsObject(survivorsPensionType)), required],
	partnerAccrual: ["partnerAccrual", merge(defaultObject(numeric), boundaries(numeric)), required],
	orphanAccrual: ["orphanAccrual", merge(defaultObject(numeric), boundaries(numeric)), required],
	indexation: ["indexation", merge(defaultObject(numeric), optionsObject(numeric)), required],
	yearsOfServiceDetermination: ["yearsOfServiceDetermination", merge(
		defaultObject(enumConstant(YearsOfServiceDetermination)), optionsObject(yearsOfServiceDeterminationType)
	), optional]
}

const financialYield: ConversionMap<EnumOption<YieldType, number>> = {
	constant: ["constant", yieldType, required],
	value: ["value", numeric, optional]
}

export const coverageOption: ConversionMap<CoverageOption> = {
	active: ["active", bool, optional],
	percentage: ["percentage", numeric, optional],
	amount: ["amount", numeric, optional]
}

const anwGapConstraints: ConversionMap<AnwGapConstraints> = {
	indexation: ["indexation", optionsObject(numeric), required],
	coverage: ["coverage", many(coverageOption), required]
}

const insuranceConstraints: ConversionMap<InsuranceConstraints> = {
	anwGap: ["anwGap", one(anwGapConstraints), required]
}

const quotationConstraints: ConversionMap<AgreementConstraints> = {
	id: [null, () => null, required],
	productType: ["productType", productFrameworkType, required],
	strict: [null, () => false, required],
	startDate: ["startDate", boundaries(date), required],
	enrollmentAge: ["enrollmentAge", merge(defaultObject(numeric), boundaries(numeric)), required],
	pensionAge: ["pensionAge", merge(defaultObject(numeric), boundaries(numeric)), required],
	franchise: ["franchise", merge(defaultOptionObject(franchiseOption), optionsObject(franchiseOption), boundaries(numeric)), required],
	maximumSalary: ["maximumSalary", merge(defaultOptionObject(maximumSalaryOption), optionsObject(maximumSalaryOption), boundaries(numeric)), required],
	payrollPeriod: ["payrollPeriod", merge(defaultObject(payrollPeriod), optionsObject(payrollPeriod)), required],
	paymentTerm: ["paymentTerm", merge(defaultObject(paymentTerm), optionsObject(paymentTerm)), required],
	pensionableSalaryComponents: ["pensionableSalaryComponents", merge(defaultObject(many(salaryComponent)), optionsObject(salaryComponent)), required],
	scale: ["scale", one(scale), required],
	investmentFreedom: ["investmentFreedom", merge(defaultObject(investmentFreedom), optionsObject(investmentFreedom)), required],
	survivorsPension: ["survivorsPension", one(survivorsPension), required],
	amountOfParticipants: ["amountOfParticipants", boundaries(numeric), required],
	financialYield: ["financialYield", merge(defaultOptionObject(financialYield), optionsObject(financialYield), boundaries(numeric)), required],
	employeeContributionPercentage: ["employeeContributionPercentage", merge(defaultObject(numeric), boundaries(numeric)), required],
	insuranceConstraints: ["insuranceConstraints", one(insuranceConstraints), required]
}

export default mandatory(one(quotationConstraints))
