
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	import { api } from "api/index"
	import { deserializeBase64File, oneFileType } from "store/conversion"
	import { FileType } from "store/types"

	@Component
	export default class DownloadLink extends Vue {
		@Prop({ type: String, required: true }) url!: string
		@Prop({ type: Boolean, default: false }) disabled!: boolean

		async downloadDocument(): Promise<void> {
			const fileType: FileType | undefined = oneFileType(await api.getJson(this.url))
			if (!fileType) {
				return
			}

			this.downloadFile(fileType)
		}

		private downloadFile(fileType: FileType): void {
			if (!fileType?.content) {
				return
			}

			const file: File | undefined = deserializeBase64File(fileType.content)
			if (!file) {
				return
			}

			DownloadLink.downloadBlob(file, fileType)
		}

		private static downloadBlob(file: File, fileInfo: FileType): void {
			const url = URL.createObjectURL(new File([file], fileInfo.name))

			const downloadLink: HTMLAnchorElement = document.createElement("a")
			downloadLink.href = url
			downloadLink.download = `${ fileInfo.name }.${ fileInfo.type }`
			downloadLink.hidden = true

			document.body.appendChild(downloadLink)
			downloadLink.click()

			document.body.removeChild(downloadLink)
			URL.revokeObjectURL(url)
		}
	}
