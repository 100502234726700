import { isUndefined } from "lodash-es"
import { ObjectRules, Datatype, CustomRules } from "@lib/types/validation"
import { many, one } from "@lib/import/extract"
import { enumConstant } from "@lib/import/convert"
import { Function2 } from "@lib/types/function"
import { AgreementConstraints, Boundaries } from "store/agreement/constraints/types"
import { Quotation, PayrollPeriod, PaymentTerm, SalaryComponent, YieldType, YearsOfServiceDetermination } from "./types"
import { Franchise, MaximumSalary, ScaleType, InvestmentFreedom, SurvivorsPensionType } from "api/models/shared"
import { anwGap, coverageDefault } from "./conversion"
import { ProductFrameworkType } from "api/models/products"

const required = (constraints: AgreementConstraints) => constraints.strict

/**
 * Helper that constructs the custom checks for validation of boundaries.
 *
 * The function `get` returns the `Boundaries` object to check against.
 */
const boundaries = <F extends keyof T, T>(
	get: Function2<AgreementConstraints, Partial<T>, Boundaries<T[F]>>
): CustomRules<F, T, AgreementConstraints> => {
	return {
		minimum(value: NonNullable<T[F]>, constraints: AgreementConstraints): boolean {
			const bound = get(constraints, this).boundaries.minimum
			return isUndefined(bound) || value >= bound
		},
		maximum(value, constraints: AgreementConstraints): boolean {
			const bound = get(constraints, this).boundaries.maximum
			return isUndefined(bound) || value <= bound
		}
	}
}

const isDateValid = (date: Date): boolean => {
	return date instanceof Date && !isNaN(date.getTime())
}

const rules: ObjectRules<Quotation, AgreementConstraints> = {
	// Hidden fields:
	id: false,
	agreementQuoteId: false,
	status: false,
	lastUpdated: false,
	agreementId: false,
	costs: false,
	prognosisFileId: false,
	// Editable fields:
	productAgreementId: {
		required,
		type: Datatype.STRING
	},
	startDate: {
		required,
		type: Datatype.STRING,
		custom: {
			valid(dateString: string): boolean {
				const date = new Date(dateString)
				return isDateValid(date)
			},
			minimum(date: string, constraints: AgreementConstraints): boolean {
				const converted = new Date(date)

				if (!isDateValid(converted)) {
					return true
				}

				const bound = constraints.startDate.boundaries.minimum
				return isUndefined(bound) || converted.getTime() >= bound.getTime()
			},
			maximum(date: string, constraints: AgreementConstraints): boolean {
				const converted = new Date(date)

				if (!isDateValid(converted)) {
					return true
				}

				const bound = constraints.startDate.boundaries.maximum
				return isUndefined(bound) || converted.getTime() <= bound.getTime()
			},

			firstOfMonth(date: string): boolean {
				const firstOfMonth = 1
				const converted = new Date(date)

				if (!isDateValid(converted)) {
					return true
				}

				return converted.getDate() === firstOfMonth
			}
		}
	},
	quoteReference: {
		required,
		type: Datatype.STRING
	},
	company: {
		required,
		type: Datatype.OBJECT,
		fields: {
			externalIdentifier: {
				required: false,
				type: Datatype.STRING
			},
			name: {
				required,
				type: Datatype.STRING
			}
		}
	},
	enrollmentAge: {
		required,
		type: Datatype.NUMBER,
		custom: boundaries(constraints => constraints.enrollmentAge)
	},
	pensionAge: {
		required,
		type: Datatype.NUMBER,
		custom: boundaries(constraints => constraints.pensionAge)
	},
	franchise: {
		required,
		type: Datatype.OBJECT,
		fields: {
			constant: {
				required,
				type: enumConstant(Franchise)
			},
			value: {
				required(constraints): boolean {
					return required(constraints) && this.constant === Franchise.CUSTOM
				},
				type: Datatype.NUMBER,
				custom: boundaries(constraints => constraints.franchise)
			}
		}
	},
	maximumSalary: {
		required,
		type: Datatype.OBJECT,
		fields: {
			constant: {
				required,
				type: enumConstant(MaximumSalary)
			},
			value: {
				required(constraints): boolean {
					return required(constraints) && this.constant === MaximumSalary.CUSTOM
				},
				type: Datatype.NUMBER,
				custom: boundaries(constraints => constraints.maximumSalary)
			}
		}
	},
	scale: {
		required,
		type: Datatype.OBJECT,
		fields: {
			type: {
				required,
				type: enumConstant(ScaleType)
			},
			percentage: {
				required(): boolean {
					return this.type !== ScaleType.FIXED_PREMIUM
				},
				type: Datatype.NUMBER
			},
			utilizationRate: {
				required(constraints): boolean {
					return constraints.productType === ProductFrameworkType.WNP
				},
				type: Datatype.NUMBER,
				custom: {
					maxDecimals(rate: number): boolean {
						const regex = /^\d{1,2}$|^\d*\.*\d{0,2}$/
						return regex.test(rate.toString())
					},
					minimum(rate: number, constraints: AgreementConstraints): boolean {
						const bound = (this.type === ScaleType.AGE_DEPENDENT || this.type === ScaleType.TRANSITIONAL_ARRANGEMENT) ?
							constraints.scale.ageDependentUtilizationRate?.boundaries.minimum : constraints.scale.fixedPremiumUtilizationRate.boundaries.minimum
						return isUndefined(bound) || rate >= bound
					},
					maximum(rate: number, constraints: AgreementConstraints): boolean {
						const bound = (this.type === ScaleType.AGE_DEPENDENT || this.type === ScaleType.TRANSITIONAL_ARRANGEMENT) ?
							constraints.scale.ageDependentUtilizationRate?.boundaries.maximum : constraints.scale.fixedPremiumUtilizationRate.boundaries.maximum
						return isUndefined(bound) || rate <= bound
					}
				}
			},
			fixedBasePremium: {
				required(constraints): boolean {
					return constraints.productType === ProductFrameworkType.WTP && this.type === ScaleType.FIXED_PREMIUM
				},
				type: Datatype.NUMBER,
				custom: {
					maxDecimals(value: number): boolean {
						const regex = /^\d{1,2}$|^\d*\.*\d{0,2}$/
						return regex.test(value.toString())
					},
					minimum(value: number, constraints: AgreementConstraints): boolean {
						const bound = constraints.scale?.fixedBasePremium?.boundaries.minimum
						return isUndefined(bound) || value >= bound
					},
					maximum(value: number, constraints: AgreementConstraints): boolean {
						const bound = constraints.scale?.fixedBasePremium?.boundaries.maximum
						return isUndefined(bound) || value <= bound
					}
				}
			}
		}
	},
	paymentTerm: {
		required,
		type: enumConstant(PaymentTerm)
	},
	payrollPeriod: {
		required,
		type: enumConstant(PayrollPeriod)
	},
	pensionableSalaryComponents: {
		required: false,
		type: many(enumConstant(SalaryComponent))
	},
	insurance: {
		required,
		type: Datatype.OBJECT,
		fields: {
			anwGap: {
				required,
				type: one(anwGap),
				fields: {
					active: {
						required: true,
						type: Datatype.BOOLEAN
					},
					coverageOptions: {
						required: false,
						type: Datatype.ARRAY
					},
					coverageDefault: {
						required(): boolean {
							return !!this.active
						},
						type: one(coverageDefault),
						fields: {
							amount: {
								required: false,
								type: Datatype.NUMBER
							},
							percentage: {
								required,
								type: Datatype.NUMBER
							}
						}
					},
					defaultInsured: {
						required: false,
						type: Datatype.BOOLEAN
					},
					hasEmployeeContribution: {
						required: false,
						type: Datatype.BOOLEAN
					},
					indexation: {
						required: false,
						type: Datatype.NUMBER
					}
				}
			},
			premiumWaiver: {
				required,
				type: Datatype.BOOLEAN
			},
			extraSavings: {
				required,
				type: Datatype.BOOLEAN
			}
		}
	},
	investmentFreedom: {
		required,
		type: enumConstant(InvestmentFreedom)
	},
	survivorsPension: {
		required,
		type: Datatype.OBJECT,
		fields: {
			type: {
				required,
				type: enumConstant(SurvivorsPensionType)
			},
			partnerAccrual: {
				required(constraints): boolean {
					return this.type === SurvivorsPensionType.RISK_INSURANCE && required(constraints)
				},
				type: Datatype.NUMBER,
				custom: {
					...boundaries(constraints => constraints.survivorsPension.partnerAccrual),
					maxDecimals(value: number, constraints: AgreementConstraints): boolean {
						if (constraints.productType === ProductFrameworkType.WTP) {
							const regex = /^\d{1,2}$|^\d*\.*\d{0,2}$/
							return regex.test(value.toString())
						}
						return true
					}
				}
			},
			orphanAccrual: {
				required(constraints): boolean {
					return this.type === SurvivorsPensionType.RISK_INSURANCE && required(constraints)
				},
				type: Datatype.NUMBER,
				custom: {
					...boundaries(constraints => constraints.survivorsPension.orphanAccrual),
					maxDecimals(value: number, constraints: AgreementConstraints): boolean {
						if (constraints.productType === ProductFrameworkType.WTP) {
							const regex = /^\d{1,2}$|^\d*\.*\d{0,2}$/
							return regex.test(value.toString())
						}
						return true
					}
				}
			},
			indexation: {
				required(constraints): boolean {
					return this.type === SurvivorsPensionType.RISK_INSURANCE && required(constraints)
				},
				type: Datatype.NUMBER
			},
			yearsOfServiceDetermination: {
				required(constraints): boolean {
					return constraints.survivorsPension?.yearsOfServiceDetermination ? this.type === SurvivorsPensionType.RISK_INSURANCE && required(constraints) : false
				},
				type: enumConstant(YearsOfServiceDetermination)
			}
		}
	},
	participantCount: {
		required,
		type: Datatype.NUMBER,
		custom: boundaries(constraints => constraints.amountOfParticipants)
	},
	participantsFileName: {
		required,
		type: Datatype.STRING
	},
	financialYield: {
		required,
		type: Datatype.OBJECT,
		fields: {
			constant: {
				required,
				type: enumConstant(YieldType)
			},
			value: {
				required(constraints): boolean {
					return required(constraints) && this.constant === YieldType.CUSTOM
				},
				type: Datatype.NUMBER,
				custom: boundaries(constraints => constraints.financialYield)
			}
		}
	},
	employeeContributionPercentage: {
		required,
		type: Datatype.NUMBER,
		custom: boundaries(constraints => constraints.employeeContributionPercentage)
	}
}

export default rules
