
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"
	import { get } from "lodash-es"
	import { ObjectErrors } from "@lib/types/validation"

	@Component
	export default class ValidationErrors<T> extends Vue {
		@Prop({ type: String, required: true }) field!: string
		@Prop({ type: Object }) errors?: ObjectErrors<T>

		get errorCodes(): ReadonlyArray<string> | undefined {
			return this.errors && get(this.errors, this.field)
		}

	}
