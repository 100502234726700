import { ActionContext } from "vuex"
import { namespaces } from "."
import { AuthenticationMethod, AUTHENTICATION_METHOD_KEY } from "utils/session-factory"
import localStorageClient from "api/clients/local-storage-client"

export default async (store: ActionContext<void, void>): Promise<void> => {
	const authMethod: AuthenticationMethod = localStorageClient.getItem(AUTHENTICATION_METHOD_KEY) as AuthenticationMethod

	// We can't retrieve a controlroom user for e-herkenning login.
	if (authMethod === AuthenticationMethod.CREDENTIALS  || authMethod === AuthenticationMethod.CONTROL_ROOM) {
		await store.dispatch(`${ namespaces.user }/getCurrentUser`)
	}

	await store.dispatch(`${ namespaces.company }/getCurrentCompany`)
}
