import Vue from "vue"
import Vuex, { Store } from "vuex"

import agreementConfig from "./agreement/index"
import quotationConfig from "./quotation/index"
import requestProductConfig from "./request-product/index"
import constraintsModule from "./agreement/constraints/index"
import messages from "./messages"
import authenticationModule from "./authentication"
import productModule from "./product"
import companyModule from "./company"
import userModule from "./user"
import { MutationType } from "./authentication/mutations"
import load from "./load"
import { USER_MUTATIONS } from "./user"

import persist from "@lib/vuex/plugins/persist"
import eventBus from "@lib/vue/eventBus"
import { PreserveState } from "@lib/types/model"
import { IDENTIFICATION_FAILED, AUTHORIZATION_FAILED, ACCESS_REVOKED, ACCESS_GRANTED } from "@lib/vue/events"
import ModelFactory from "@lib/model/store/ModelFactory"
import { storageOptions } from "@lib/storage/session"

import { AUTHENTICATION_METHOD_KEY, AuthenticationMethod } from "utils/session-factory"

import { QUOTE_CALCULATION_FINISHED, QUOTE_CALCULATION_STATUS_UPDATE } from "api/clients/quotation-client"
import localStorageClient from "api/clients/local-storage-client"
import { storageController } from "lib/storage/session"

Vue.use(Vuex)

export type RootState = void

export const namespaces = {
	agreement: "agreement",
	quotation: "quotation",
	requestProduct: "requestProduct",
	agreementConstraints: "agreement/constraints",
	messages: "messages",
	authentication: "authentication",
	company: "company",
	product: "product",
	user: "user"
}

const plugins = [
	persist({
		...storageOptions,
		key: "persist",
		whitelist: [
			namespaces.agreementConstraints,
			namespaces.messages,
			namespaces.authentication,
			namespaces.company,
			namespaces.user
		],
		deactivate: s => !s.state[namespaces.authentication].loggedIn
	}),
	async (store: Store<RootState>) => {
		eventBus.on([IDENTIFICATION_FAILED, AUTHORIZATION_FAILED, ACCESS_REVOKED], async () => {
			if (!process.env.SERVER) {
				storageController.discard("session")
				sessionStorage.clear()
				if (!location.href.includes("login.html") && !location.href.includes("activate-account.html")) {
					await store.dispatch("authentication/logout")
					location.href = "./login.html"
				}
			}
		})

		eventBus.on(QUOTE_CALCULATION_FINISHED, async calculationResult => {
			await store.dispatch(`${ namespaces.quotation }/storeCalculationResult`, calculationResult)
		})

		eventBus.on(QUOTE_CALCULATION_STATUS_UPDATE, async calculationUpdate => {
			await store.dispatch(`${ namespaces.quotation }/storeCalculationUpdate`, calculationUpdate)
		})

		eventBus.on(ACCESS_GRANTED, async () => {
			await store.dispatch(ROOT_ACTIONS.INITIALIZE)
		})
	}
]

export enum ROOT_ACTIONS {
	INITIALIZE = "INITIALIZE"
}

const loadingTimeout = 500

const rootStore = new Store<RootState>({
	modules: {
		[namespaces.agreementConstraints]: constraintsModule,
		[namespaces.messages]: messages,
		[namespaces.authentication]: authenticationModule,
		[namespaces.company]: companyModule,
		[namespaces.product]: productModule,
		[namespaces.user]: userModule
	},
	actions: {
		async [ROOT_ACTIONS.INITIALIZE](context): Promise<void> {
			context.commit(`${ namespaces.authentication }/${ MutationType.LOGIN }`)
			context.commit(`${ namespaces.authentication }/${ MutationType.TIMESTAMP }`)
			context.commit(`${ namespaces.user }/${ USER_MUTATIONS.SET_LOADING }`, true)
			const faAuthentication = localStorageClient.getItem("2faAuthentication")
			if (faAuthentication === "1") {
				context.commit(`${namespaces.user}/${USER_MUTATIONS.SET_LOADING}`, false)
			} else {
				return load(context)
				.then(() =>  {
					const authMethod = localStorageClient.getItem(AUTHENTICATION_METHOD_KEY) as AuthenticationMethod
					if (authMethod === AuthenticationMethod.CREDENTIALS || authMethod === AuthenticationMethod.CONTROL_ROOM) {
						context.commit(`${ namespaces.user }/${ USER_MUTATIONS.SET_LOADING }`, false)
						if (!process.env.SERVER && (location.href.includes("login.html") || location.href.includes("impersonate.html") || location.href.includes("activate-account.html"))) {
							location.href = "./index.html"
						}
					}
				})
				.catch(async error => {
					await context.dispatch(`${ namespaces.authentication }/logout`) // Logout the user since his/her data is not loaded correctly.
					context.commit(`${ namespaces.user }/${ USER_MUTATIONS.SET_ERROR }`, true)

					throw error
				})
				.finally(() => setTimeout(() => context.commit(`${ namespaces.user }/${ USER_MUTATIONS.SET_LOADING }`, false), loadingTimeout))
			}
		}
	},
	plugins
})

export default rootStore

const factory = new ModelFactory<RootState>(rootStore, storageOptions)

export const agreementModel = factory.immutableCollection({
	namespace: namespaces.agreement,
	initialState: [],
	...agreementConfig
})

export const quotationModel = factory.mutableCollection({
	namespace: namespaces.quotation,
	preserveState: PreserveState.COMPLETE,
	...quotationConfig,
	context: () => rootStore.getters[namespaces.agreementConstraints + "/state"]
})

export const requestProductContext = { postalAddressRequired: false }

export const requestProductModel = factory.mutableCollection({
	namespace: namespaces.requestProduct,
	...requestProductConfig,
	context: requestProductContext
})

export const expire = (): void => factory.expire()
