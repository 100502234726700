import always from "../function/always"
import keys from "../misc/keys"
import { ConversionMap, ConversionFunction } from "../types/import"
import { Predicate, Function1 } from "../types/function"
import entries from "../misc/entries"

import { get, isArray, isNil, isPlainObject, isUndefined, identity } from "lodash-es"

/**
 * Extracts fields from the data object and produces a `T`. If a value cannot be converted, an error is thrown.
 */
export const extract = <T extends any>(map: ConversionMap<T>, data: any): T => {
	const result: Partial<T> = {}

	for (const field of keys(map)) {
		const [path, convert, required] = map[field]
		const value = path ? get(data, path) : data
		const converted = convert(value)

		if ((required || !isNil(value)) && isUndefined(converted)) {
			throw new TypeError(`
- Field [${ path }] ${ required ? "does not exist or" : "" } contains an improper value.
- The current value is ${ value }.
- The field is part of this datastructure: ${ JSON.stringify(data) }`
			)
		}

		result[field] = converted
	}

	return result as T
}

/**
 * Returns a conversion function based on the conversion map.
 */
export const one = <T>(map: ConversionMap<T>): ConversionFunction<T> => data => isNil(data) ? undefined : extract(map, data)

/**
 * Returns a `ConversionFunction` that converts an incoming array to an array of `T`'s.
 */
export const many = <T>(
	mapOrConvert: ConversionMap<T> | ConversionFunction<T>,
	predicate: Predicate<any> = always
): ConversionFunction<Array<T>> => {
	const convert = typeof mapOrConvert === "function" ? mapOrConvert : one(mapOrConvert)
	return values => isArray(values) ? values.filter(predicate).map(mandatory(convert)) : undefined
}

/**
 * Returns a `ConversionFunction` that converts only the last element of an incoming array to an instance of type `T`.
 *
 * The optional predicate function is used to filter the incoming array before conversion.
 */
export const last = <T>(map: ConversionMap<T>, predicate: Predicate<unknown> = always): ConversionFunction<T> =>
	values => {
		const array = isArray(values) && values.filter(predicate)

		if (array && array.length) {
			return extract(map, array[array.length - 1])
		}

		return undefined
	}

/**
 * Returns a `ConversionFunction` that converts an object's values and optionally its keys.
 */
export function obj<T, V>(convertValue: ConversionFunction<V>): ConversionFunction<Record<keyof T, V>>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function obj<T, K extends keyof any, V>(
	convertKey: ConversionFunction<K>,
	convertValue: ConversionFunction<V>
): ConversionFunction<Record<K, V>>

export function obj<T extends Object, K extends keyof any, V>(
	convert1: ConversionFunction<K> | ConversionFunction<V>,
	convert2?: ConversionFunction<V>
): ConversionFunction<Record<K, V>> {
	const [convertKey, convertValue] = convert2 ? [convert1, convert2] : [identity, convert1]

	return data => isPlainObject(data) ?
		Object.fromEntries(entries<T>(data).map(([key, value]) => [convertKey(key), convertValue(value)])) as Record<K, V> :
		undefined
}

/**
 * Returns a function that enforces that the value can be converted, or throws an error.
 */
export const mandatory = <T>(f: ConversionFunction<T>): Function1<any, T> =>
	data => {
		const result = f(data)

		if (isUndefined(result)) {
			throw new TypeError(`Missing value or cannot convert: ${ JSON.stringify(data) }`)
		}

		return result
	}

/**
 * Returns a function that returns the default value if the value is undefined.
 */
export const def = <T>(defaultValue: T, f: ConversionFunction<T>): ConversionFunction<T> =>
	data => isUndefined(data) ? defaultValue : f(data)

/**
 * Returns a `ConversionFunction` that returns the result of the first successful conversion.
 */
export function or<T1, T2>(f1: ConversionFunction<T1>, f2: ConversionFunction<T2>): ConversionFunction<T1 | T2>
export function or<T1, T2, T3>(f1: ConversionFunction<T1>, f2: ConversionFunction<T2>, f3: ConversionFunction<T3>): ConversionFunction<T1 | T2 | T3>

export function or(...fs: Array<ConversionFunction<any>>): ConversionFunction<unknown> {
	return data => fs.reduce(
		(value, f) => isUndefined(value) ? f(data) : value,
		undefined
	)
}

/**
 * Returns a `ConversionFunction` that returns the object that contains the keys from both conversions.
 */
export function merge<T1, T2>(f1: ConversionFunction<T1>, f2: ConversionFunction<T2>): ConversionFunction<T1 & T2>

// eslint-disable-next-line max-len
// tslint:disable-next-line: max-line-length
export function merge<T1, T2, T3>(
	f1: ConversionFunction<T1>,
	f2: ConversionFunction<T2>,
	f3: ConversionFunction<T3>
): ConversionFunction<T1 & T2 & T3>

export function merge(...fs: Array<ConversionFunction<any>>): ConversionFunction<unknown> {
	return data => {
		const result = {}

		for (const f of fs) {
			const converted = f(data)

			if (isUndefined(converted)) {
				return undefined
			}

			Object.assign(result, converted)
		}

		return result
	}
}

/**
 * Returns a `ConversionFunction` that returns the value if it is contained in the given values.
 */
export const oneOf = <T>(...values: Array<T>): ConversionFunction<T> => {
	const set = new Set(values)
	return value => set.has(value) ? value : undefined
}
