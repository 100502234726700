import { ConversionMap, required, optional, ConversionFunction } from "@lib/types/import"
import { alphanumeric, numeric, date, enumConstant } from "@lib/import/convert"
import { many, mandatory, one, def } from "@lib/import/extract"
import { AgreementState, AgreementType, Product, ProductDetails, Participant, PremiumFrequency, EmployeeContributionType, EmployeeContribution, ProductFrameworkType } from "api/models/products"
import { franchise, maximumSalary, scale, survivorsPension, insurance } from "store/quotation/conversion"
import { InvestmentFreedom } from "api/models/shared"
import { CivilStatusType } from "@lib/types/personal/policyholder"
import { ParticipantsDataChannel } from "store/request-product/types"
import { PaymentTerm, SalaryComponent, PayrollPeriod } from "store/quotation/types"

const product: ConversionMap<Product> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, required],
	name: ["name", alphanumeric, optional],
	clientName: ["clientName", alphanumeric, optional],
	clientId: ["clientId", alphanumeric, optional],
	agreementState: ["agreementState", enumConstant(AgreementState), optional],
	agreementType: ["agreementType", enumConstant(AgreementType), optional],
	startDate: ["startDate", date, optional],
	amountOfParticipants: ["amountOfParticipants", numeric, optional],
	actions: ["actions", alphanumeric, optional],
	originalStartDate: ["originalStartDate", date, optional]
}

const employeeContribution: ConversionMap<EmployeeContribution> = {
	type: ["type", enumConstant(EmployeeContributionType), required],
	percentage: ["percentage", numeric, required]
}

const productDetails: ConversionMap<ProductDetails> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, required],
	name: ["name", alphanumeric, required],
	agreementState: ["agreementState", enumConstant(AgreementState), required],
	agreementType: ["agreementType", enumConstant(AgreementType), optional],
	startDate: ["startDate", date, required],
	endDate: ["endDate", date, optional],
	originalStartDate: ["originalStartDate", date, required],
	clientName: ["clientName", alphanumeric, optional],
	participantsCount: ["participantsCount", numeric, required],
	enrollmentAge: ["enrollmentAge", numeric, required],
	pensionAge: ["pensionAge", numeric, required],
	packageCharacteristic: ["packageCharacteristic", alphanumeric, required],
	packageVariant: ["packageVariant", alphanumeric, required],
	insurance: ["insurance", one(insurance), required],
	franchise: ["franchise", one(franchise), required],
	maximumSalary: ["maximumSalary", one(maximumSalary), required],
	scale: ["scale", one(scale), required],
	survivorsPension: ["survivorsPension", one(survivorsPension), required],
	investmentFreedom: ["investmentFreedom", enumConstant(InvestmentFreedom), required],
	employeeContribution: ["employeeContribution", one(employeeContribution), optional],
	dataDeliveryChannel: ["dataDeliveryChannel", def(ParticipantsDataChannel.PORTAL, enumConstant(ParticipantsDataChannel)), optional],
	paymentTerm: ["paymentTerm", enumConstant(PaymentTerm), optional],
	pensionableSalaryComponents: ["pensionableSalaryComponents", many(enumConstant(SalaryComponent)), optional],
	payrollPeriod: ["payrollPeriod", enumConstant(PayrollPeriod), optional],
	premiumFrequency: ["premiumFrequency", enumConstant(PremiumFrequency), optional],
	productType: ["productType", enumConstant(ProductFrameworkType), optional]
}

export const civilStatusType: ConversionFunction<CivilStatusType> = (value: CivilStatusType) =>
	["SINGLE", "COHABITATION", "LEGAL_COHABITATION", "CIVIL_PARTNERSHIP", "MARRIED"].includes(value) ? value : undefined

const participant: ConversionMap<Participant> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, required],
	name: ["fullName", alphanumeric, required],
	dateOfBirth: ["dateOfBirth", date, required],
	civilStatus: ["civilStatus", civilStatusType, required],
	employmentStartDate: ["employmentStartDate", date, required],
	parttimePercentage: ["partTimePercentage", numeric, required],
	policyNumber: ["policyNumber", alphanumeric, required],
	salary: ["salary", numeric, required]
}

export const convertParticipant = mandatory(one(participant))
export const convertParticipants = mandatory(many(participant))
export const convertProductDetails = mandatory(one(productDetails))
export const convertProducts = mandatory(many(product))
