import {
	Address,
	CivilStatusType,
	Gender,
	Investment,
	InvestmentAllocation,
	InvestmentAsset,
	InvestmentsInfo,
	Participant,
	PensionInfo,
	PersonalInfo,
	PreferredBenefitType,
	PrivateParticipantInfo,
	RiskAppetiteType
 } from "api/models/participant"

import { alphanumeric, bool, date, numeric, enumConstant } from "@lib/import/convert"
import { ConversionMap, optional, required } from "@lib/types/import"
import { mandatory, many, one } from "@lib/import/extract"

const addressModel: ConversionMap<Address> = {
	countryCode: ["countryCode", alphanumeric, required],
	street: ["street", alphanumeric, required],
	number: ["number", alphanumeric, required],
	postalCode: ["postalCode", alphanumeric, required],
	city: ["city", alphanumeric, required]
}

const personalModel: ConversionMap<PersonalInfo> = {
	email: ["email", alphanumeric, optional],
	phoneNumber: ["phoneNumber", alphanumeric, optional],
	partnerKnown: ["partnerKnown", bool, required],
	childrenKnown: ["childrenKnown", bool, required]
}

const pensionModel: ConversionMap<PensionInfo> = {
	accruedPensionCapital: ["accrued", numeric, required],
	expectedPensionCapital: ["target", numeric, required],
	anwInsurance: ["anwInsurance", numeric, required],
	partnerPension: ["partnerPension", numeric, required],
	orphanPension: ["orphanPension", numeric, required],
	restitutionCapital: ["restitution", numeric, required]
}

const investAllocationModel: ConversionMap<InvestmentAllocation> = {
	target: ["target", numeric, required],
	current: ["current", numeric, required]
}

const investmentAssetModel: ConversionMap<InvestmentAsset> = {
	name: ["name", alphanumeric, required],
	price: ["price", numeric, required],
	category: ["category", alphanumeric, required]
}

const investmentsModel: ConversionMap<Investment> = {
	investmentId: ["id", alphanumeric, required],
	units: ["units", numeric, required],
	value: ["value", numeric, required],
	currency: ["currency", alphanumeric, required],
	allocation: ["allocation", one(investAllocationModel), required],
	asset: ["asset", one(investmentAssetModel), required]
}

const investmentsInfoModel: ConversionMap<InvestmentsInfo> = {
	currentRiskProfile: ["riskProfile", enumConstant(RiskAppetiteType), optional],
	determinationDateRiskProfile: ["riskProfileChangeDate", date, optional],
	preferredBenefit: ["preferredBenefit", enumConstant(PreferredBenefitType), optional],
	determinationDatePreferredBenefit: ["preferredBenefitChangeDate", date, optional],
	currentInvestmentMix: ["investmentMix", many(investmentsModel), required]
}

const privateInfoModel: ConversionMap<PrivateParticipantInfo> = {
	gender: ["gender", enumConstant(Gender), required],
	address: ["email", one(addressModel), required],
	personal: ["personal", one(personalModel), optional],
	pension: ["pension", one(pensionModel), optional],
	investments: ["pension", one(investmentsInfoModel), optional]
}

const participantModel: ConversionMap<Participant> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, required],
	identificationNumber: ["identificationNumber", alphanumeric, required],
	policyNumber: ["policyNumber", alphanumeric, required],
	initials: ["initials", alphanumeric, required],
	fullName: ["fullName", alphanumeric, required],
	firstName: ["firstName", alphanumeric, required],
	lastName: ["lastName", alphanumeric, required],
	dateOfBirth: ["dateOfBirth", date, required],
	civilStatus: ["civilStatus", enumConstant(CivilStatusType), required],
	salary: ["salary", numeric, required],
	parttimePercentage: ["partTimePercentage", numeric, required],
	employmentStartDate: ["employmentStartDate", date, required],
	privateInfo: ["privateInfo", one(privateInfoModel), optional]
}

export const convertParticipant = mandatory(one(participantModel))
export const convertPersonalInfo = mandatory(one(personalModel))
export const convertPensionInfo = mandatory(one(pensionModel))
export const convertInvestmentsInfo = mandatory(one(investmentsInfoModel))
