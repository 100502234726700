import { ActionTree, ActionContext } from "vuex"
import { RootState, expire, namespaces } from "store/index"
import { JwtToken, UsernamePassword } from "@lib/types/security"
import { AuthenticationState } from "./types"
import { MutationType } from "./mutations"
import { save as saveLocale } from "@lib/i18n/session"
import load from "src/i18n/load"
import { AUTHENTICATION_METHOD_KEY, AuthenticationMethod, sessionFactory } from "utils/session-factory"
import clientCache from "api/cache/client-cache"
import userClient from "api/clients/user-client"
import { AccountActivation } from "api/models/account"
import { USER_MUTATIONS } from "store/user"
import eventBus from "lib/vue/eventBus"
import { ACCESS_DENIED, ACCESS_GRANTED } from "lib/vue/events"
import localStorageClient from "api/clients/local-storage-client"

export interface LoginActionPayload {
	authenticationMethod: AuthenticationMethod
	credentials?: UsernamePassword
}

type Context = ActionContext<AuthenticationState, RootState>

const actions: ActionTree<AuthenticationState, RootState> = {
	async activateUser(context: Context, payload: AccountActivation): Promise<void> {
		const loadingTimeout = 500
		context.commit(`${ namespaces.user }/${ USER_MUTATIONS.SET_ACTIVATION_STATUS  }`, true, { root: true })
		try {
			const response = await userClient.activateUser(payload)

			if (response.ok) {
				await context.dispatch(`${ namespaces.authentication }/login`, {
					credentials: {
						username: payload.username,
						password: payload.password
					},
					authenticationMethod: AuthenticationMethod.CREDENTIALS
				}, { root: true })
			}
		} finally {
			setTimeout(() => context.commit(`${ namespaces.user }/${ USER_MUTATIONS.SET_ACTIVATION_STATUS  }`, false, { root: true }), loadingTimeout)
		}
	},
	async login(_context: Context, payload: LoginActionPayload): Promise<void> {
		localStorageClient.setItem(AUTHENTICATION_METHOD_KEY, payload.authenticationMethod)
		await sessionFactory.session.login(payload.credentials)
		await saveLocale(load, "nl-NL")
	},
	async logout(context: Context): Promise<boolean> {
		await sessionFactory.session.logout()
		context.commit(MutationType.LOGOUT)
		expire()
		clientCache.invalidateCache()
		return true
	},
	async impersonate(_context, token: JwtToken): Promise<void> {
		if (sessionFactory.session.impersonate(token.username, token)) {
			eventBus.emit(ACCESS_GRANTED, token.username)
			localStorageClient.setItem(AUTHENTICATION_METHOD_KEY,  AuthenticationMethod.CONTROL_ROOM)
		} else {
			eventBus.emit(ACCESS_DENIED)
		}
	}
}

export default actions
