import { AuthorizationStrategy, IdentificationStrategy } from "@lib/types/security"
import eventBus from "@lib/vue/eventBus"
import {
	ACCESS_DENIED,
	ACCESS_RESTORED,
	AUTHORIZATION_FAILED,
	ACCESS_GRANTED,
	IDENTIFICATION_FAILED,
	ACCESS_REVOKED,
	REQUIRES_2FA
} from "@lib/vue/events"
import HttpStatus from "@lib/request/status"
import RequestError from "@lib/request/RequestError"
import { StorageOptions } from "@lib/types/storage"

import { BroadcastChannel, OnMessageHandler } from "broadcast-channel"

import { isNull, throttle } from "lodash-es"

import { AUTHENTICATION_METHOD_KEY, AuthenticationMethod } from "utils/session-factory"
import localStorageClient from "api/clients/local-storage-client"

interface Broadcaster<T>  {
	onmessage: OnMessageHandler<T>

	postMessage(msg: T): Promise<void>
	close(): Promise<void>
	addEventListener(type: string, handler: OnMessageHandler<T>): void
	removeEventListener(type: string, handler: OnMessageHandler<T>): void
}

const enum BroadcastType {
	TOKEN_REQUEST, TOKEN_RESPONSE, LOGOUT_REQUEST
}

interface BroadcastMessage {
	type: BroadcastType
	state?: SessionState
}

interface SessionState {
	token: unknown
	identified: boolean
	identifier: string | undefined
}

const DEFAULT_KEY = "session"
const BROADCAST_CHANNEL_NAME = "AUTH_CHANNEL"
const MAX_TOKEN_RESPONSE_DURATION = 500

export default class Session<Credentials, Token = any> {
	private readonly key: string
	private state?: SessionState
	private readonly authChannel?: Broadcaster<BroadcastMessage>

	constructor(
		private readonly options: StorageOptions,
		private readonly identification: IdentificationStrategy<Credentials, Token>,
		private readonly authorization: AuthorizationStrategy,
		private readonly timeout: number = Infinity,
		authChannel?: Broadcaster<BroadcastMessage>
	) {
		this.key = options.key || DEFAULT_KEY
		this.state = options.storage.retrieve(this.key)

		if (!process.env.SERVER) {
			this.authChannel = authChannel ?? new BroadcastChannel<BroadcastMessage>(BROADCAST_CHANNEL_NAME)
			const authMethod = localStorageClient.getItem(AUTHENTICATION_METHOD_KEY) as AuthenticationMethod
			if (authMethod !== AuthenticationMethod.CONTROL_ROOM) {
			 	this.listenToBroadcastMessages()
			}
		}

		if (this.state) {
			authorization.authorize(this.state.token)

			if (this.state.identified) {
				identification.identifier = this.state.identifier
			}
		} else {
			// tslint:disable-next-line:no-console
			this.authChannel?.postMessage({ type: BroadcastType.TOKEN_REQUEST }).catch(e => console.error(e))
		}
	}

	/**
	 * Verifies that the user is authenticated for the roles.
	 */
	verify(roles?: ReadonlyArray<string>): void {
		this.checkUserAccess(roles)
	}

	isAuthorizedAll(roles: ReadonlyArray<string>): boolean {
		return this.authorization.isAuthorizedAll(roles)
	}

	isAuthorizedAny(roles: ReadonlyArray<string>): boolean {
		return this.authorization.isAuthorizedAny(roles)
	}

	async authorizeWithToken(token: Token): Promise<void> {
		await this.identification.unidentify()
		this.authorization.unauthorize()

		this.identification.setIdentifierFromToken(token)
		const success = this.authorization.authorize(token)

		if (success) {
			this.store(token)
			return eventBus.emit(ACCESS_GRANTED, this.identification.identifier)
		}

		return eventBus.emit(ACCESS_DENIED)
	}

	async login(credentials: Credentials): Promise<void> {
		try {
			const token = await this.identification.identify(credentials)
			const success = this.authorization.authorize(token)

			const is2FARequired = await this.identification.is2FARequired()
			if (is2FARequired) {
				return eventBus.emit(REQUIRES_2FA, token)
			}

			if (success) {
				this.store(token)
				return eventBus.emit(ACCESS_GRANTED, this.identification.identifier)
			}

			return eventBus.emit(ACCESS_DENIED)
		} catch (error) {
			if (error instanceof RequestError) {
				const reason = await error.response.text()

				eventBus.emit(
					error.response.status === HttpStatus.FORBIDDEN ? ACCESS_DENIED : IDENTIFICATION_FAILED,
					reason
				)
			} else {
				eventBus.emit(IDENTIFICATION_FAILED, error)
			}
		}
	}

	impersonate(identifier: string | undefined, token: unknown): boolean {
		localStorageClient.setItem("2faAuthentication", "0")
		if (!this.authorization.authorize(token)) {
			return false
		}

		this.identification.identifier = identifier
		this.store(token)
		return true
	}

	async logout(): Promise<void> {
		if (!this.authorization.isAuthorized) {
			return
		}

		this.invalidateToken()
		await this.identification.unidentify()
		// tslint:disable-next-line:no-console
		this.authChannel?.postMessage({ type: BroadcastType.LOGOUT_REQUEST }).catch(e => console.error(e))
	}

	/**
	 * Forgets the identity of the user, but keeps the token. After this, the session is no longer valid
	 * but secured calls to the backend are still possible. This can be useful if the user is unknown.
	 */
	async forget(): Promise<void> {
		await this.identification.unidentify()
		this.store(this.state?.token)
	}

	private listenToBroadcastMessages(): void {
		if (!this.authChannel) {
			return
		}

		this.authChannel.onmessage = (message: BroadcastMessage) => {
			switch (message.type) {
				case BroadcastType.LOGOUT_REQUEST:
					localStorageClient.setItem("LOGOUT", "LOGOUT")
					this.invalidateToken()
					break
				case BroadcastType.TOKEN_REQUEST:
					if (!this.state) {
						break
					}
					// tslint:disable-next-line:no-console
					this.authChannel?.postMessage({ type: BroadcastType.TOKEN_RESPONSE, state: this.state }).catch(e => console.error(e))
					break
				case BroadcastType.TOKEN_RESPONSE:
					if (!!this.state || !message.state) {
						break
					}
					if (isNull(localStorageClient.getItem("LOGOUT"))) {
						this.applySessionState(message.state)
					}
					break
				default:
					throw new Error(`Unknown Broadcastmessage type: ${ message.type }!`)
			}
		}
	}

	private applySessionState(state: SessionState): boolean {
		if (!this.authorization.authorize(state.token)) {
			eventBus.emit(ACCESS_DENIED)
			return false
		}

		this.identification.identifier = state.identifier
		this.store(state.token)
		eventBus.emit(ACCESS_GRANTED, this.identification.identifier)
		return true
	}

	private invalidateToken(): void {
		this.authorization.unauthorize()
		this.options.storage.discard(this.key)
		eventBus.emit(ACCESS_REVOKED)
	}

	private store(token: unknown): void {
		this.state = {
			token,
			identified: this.identification.isIdentified,
			identifier: this.identification.identifier
		}

		this.options.storage.store(this.key, this.state)
	}

	private watch(): void {
		if (!process.env.SERVER) {
			const start = (): NodeJS.Timeout => setTimeout(async () => {
				await this.logout()
			}, this.timeout * 60 * 1000)

			let timer = start()

			const reset = throttle((event: any) => { // Duck type event, because the Event interface does not have altKey etc.
				if (!event.altKey && !event.ctrlKey && !event.metaKey) {
					clearTimeout(timer)
					timer = start()
				}
			}, 1000, { leading: true })

			for (const event of ["mousedown", "keydown", "touchstart"]) {
				document.body.addEventListener(event, reset, { capture: true })
			}
		}
	}

	private checkUserAccess(roles?: ReadonlyArray<string>, checkForBroadcast = true): void {
		if (this.authorization.isAuthorized && this.identification.isIdentified) {
			if (this.authorization.isAuthorizedAny(["2FA"])) {
				eventBus.emit(AUTHORIZATION_FAILED)
			} else if (roles && roles.length && !this.authorization.isAuthorizedAny(roles)) {
				eventBus.emit(ACCESS_DENIED)
			} else {
				eventBus.emit(ACCESS_RESTORED)
			}
		} else if (checkForBroadcast) {
			setTimeout(() => this.checkUserAccess(roles, false), MAX_TOKEN_RESPONSE_DURATION)
		} else {
			eventBus.emit(AUTHORIZATION_FAILED)
		}

		if (this.timeout < Infinity) {
			this.watch()
		}
	}
}
