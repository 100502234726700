import { CompanyOverviewModel } from "api/models/company"
import { ConversionMap, required, optional } from "@lib/types/import"
import { alphanumeric, numeric, date, datetime } from "@lib/import/convert"
import { mandatory, many } from "@lib/import/extract"

const companyOverviewModel: ConversionMap<CompanyOverviewModel> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, required],
	name: ["name", alphanumeric, required],
	agreementCount: ["agreementCount", numeric, required],
	clientSince: ["clientSince", date, optional],
	lastUpdated: ["lastUpdated", datetime, optional],
	actions: ["actions", alphanumeric, optional]
}

export const convertCompanyOverviewModels = mandatory(many(companyOverviewModel))
