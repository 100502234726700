import { PayrollPeriod, PaymentTerm, SalaryComponent, YieldType, YearsOfServiceDetermination} from "store/quotation/types"
import { Identifiable } from "@lib/types/model"
import { Enum } from "@lib/types/tuple"
import { Franchise, MaximumSalary, ScaleType, InvestmentFreedom, SurvivorsPensionType } from "api/models/shared"
import { ProductFrameworkType } from "api/models/products"

export interface AgreementConstraints extends Identifiable {
	strict: boolean
	productType: ProductFrameworkType,
	startDate: Boundaries<Date>
	enrollmentAge: Default<number> & Boundaries<number>
	pensionAge: Default<number> & Boundaries<number>
	franchise: Default<EnumOption<Franchise, number>> & Options<EnumOption<Franchise, number>> & Boundaries<number>
	maximumSalary: Default<EnumOption<MaximumSalary, number>> & Options<EnumOption<MaximumSalary, number>> & Boundaries<number>
	payrollPeriod: Default<PayrollPeriod> & Options<PayrollPeriod>
	paymentTerm: Default<PaymentTerm> & Options<PaymentTerm>
	pensionableSalaryComponents: Default<ReadonlyArray<SalaryComponent>> & Options<SalaryComponent>
	scale: {
		type: Default<ScaleType> & Options<ScaleType>
		ageDependentOptions?: AgeDependentOptions
		transitionalArrangementOptions?: TransitionalArrangementOptions
		ageDependentUtilizationRate?: Default<number> & Boundaries<number>
		fixedPremiumUtilizationRate: Default<number> & Boundaries<number>
		fixedBasePremium?: Default<number> & Boundaries<number>
	}
	investmentFreedom: Default<InvestmentFreedom> & Options<InvestmentFreedom>
	survivorsPension: {
		type: Default<SurvivorsPensionType> & Options<SurvivorsPensionType>
		partnerAccrual: Default<number> & Boundaries<number>
		orphanAccrual: Default<number> & Boundaries<number>
		indexation: Default<number> & Options<number>
		yearsOfServiceDetermination?: Default<YearsOfServiceDetermination> & Options<YearsOfServiceDetermination>
	},
	amountOfParticipants: Boundaries<number>,
	financialYield: Default<EnumOption<YieldType, number>> & Options<EnumOption<YieldType, number>> & Boundaries<number>
	employeeContributionPercentage: Default<number> & Boundaries<number>
	insuranceConstraints: InsuranceConstraints
}

export interface Percentage {
	ageRange?: string
	percentage?: number
	derivedPercentage?: number
}

export interface Percentages {
	"2.0"?: Array<Percentage>
	"2.5"?: Array<Percentage>
	"3.0"?: Array<Percentage>
	"4.0"?: Array<Percentage>
	"38.0"?: Array<Percentage>
}

export interface AgeDependentOptions {
	values: Default<number> & Options<number>
	percentages: Percentages
}

export enum TransitionalArrangementOption {
	"2%" = 2,
	"2.5%" = 2.5,
	"3%" = 3,
	"4%" = 4,
	"38r" = 38
}
export type TransitionalArrangementOptions = Default<TransitionalArrangementOption> & Options<TransitionalArrangementOption>

export interface Default<T> {
	default?: T
}

export interface Options<T> {
	options: ReadonlyArray<T>
}

export interface Boundaries<T> {
	boundaries: Range<T>
}

export interface Range<T> {
	minimum?: T
	maximum?: T
}

export interface EnumOption<E extends keyof Enum, T> {
	constant: E
	value?: T // Required if name is CUSTOM.
}

export interface InsuranceConstraints {
	anwGap: AnwGapConstraints
}

export interface AnwGapConstraints {
	coverage: Array<CoverageOption>
	indexation: Options<number>
}

export interface CoverageOption {
	active?: boolean
	percentage?: number
	amount?: number
}
