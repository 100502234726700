
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"
	import { Quotation } from "store/quotation/types"
	import { resources } from "api/resources"
	import DownloadLink from "custom/ui/DownloadLink.vue"

	@Component({
		components: {
			DownloadLink
		}
	})
	export default class QuotationResult extends Vue {
		@Prop({ type: Object, required: true }) model!: Quotation

		get year(): number {
			return new Date(this.model.startDate!).getFullYear()
		}

		get prognosisDocumentUrl(): string {
			const companyId = this.model.company?.externalIdentifier
			const agreementId = this.model.agreementId
			const documentId = this.model.prognosisFileId

			return resources.companies.getDocumentContentByCompanyAndAgreementId(companyId, agreementId, documentId)
		}
	}
