import FetchGateway from "@lib/request/FetchGateway"
import { Parameters, RequestMethod } from "@lib/types/request"
import Session from "utils/security/Session"
import JwtAuthorization from "@lib/security/authorization/JwtAuthorization"
import CredentialsIdentification from "@lib/security/identification/CredentialsIdentification"
import { storageOptions } from "@lib/storage/session"
import AuthorizedGateway from "@lib/request/AuthorizedGateway"
import RestApi from "@lib/request/RestApi"
import { loginUrl, refreshTokenEndpoint, api as apiConfig } from "./settings"
import {  impersonateUrl as impConfig } from "./settings"

import { noop } from "lodash-es"

export const openGateway = new FetchGateway()
const identification = new CredentialsIdentification(openGateway, { identification: loginUrl })
export const authorization = new JwtAuthorization(refreshTokenEndpoint)

const gateway = new AuthorizedGateway(authorization)
export const session = new Session(storageOptions, identification, authorization)

export const openApi = new RestApi(apiConfig.authenticationEndpoint, openGateway)
export const authenticationApi = new RestApi(apiConfig.authenticationEndpoint, gateway)
export const api = new RestApi(apiConfig.endpoint, gateway)
export const impersonateUrl = impConfig
export const permissionUrl = new RestApi(apiConfig.permissionEndPoint, gateway)

// ============================================
// The following functions are hopefully no longer needed.

export const get = (input: string, data?: Parameters): Promise<Response> => gateway.request(RequestMethod.GET, input, data).response
export const getOpen = (input: string, data?: Parameters): Promise<Response> => openGateway.request(RequestMethod.GET, input, data).response
export const getJson = async <T>(input: string, data?: Parameters): Promise<T> => (await get(input, data)).json()
export const getBlob = async (input: string, data?: Parameters): Promise<Blob> => (await get(input, data)).blob()

// Not all POSTs return json, so catch.
const request = async <T>(method: RequestMethod, input: string, data?: Parameters): Promise<T> =>
	(await gateway.request(method, input, data).response).json().catch(noop)

export const post = async <T>(input: string, data?: Parameters): Promise<T> => request(RequestMethod.POST, input, data)
export const put = async <T>(input: string, data?: Parameters): Promise<T> => request(RequestMethod.PUT, input, data)
export const del = async <T>(input: string, data?: Parameters): Promise<T> => request(RequestMethod.DELETE, input, data)
