
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Inject, Prop, Watch } from "vue-property-decorator"

	import IcsInput from "custom/ui/IcsInput.vue"
	import { Field, View } from "@components/data/view/types"
	import { filterFn, equals } from "./utils/filter-functions"

	@Component({
		components: {
			// AutoComplete,
			IcsInput
		}
	})
	export default class FilterField<T, F extends Field<T>> extends Vue {
		@Prop({ type: String, required: true }) field!: F
		// @Prop({required: false, type: Boolean, default: false}) autoComplete!: boolean
		@Prop({ type: Function, default: (v: T[F]) => String(v) }) transform!: (value: T[F]) => string
		@Prop({ type: Function, default: equals }) filterFn!: filterFn
		@Prop({ type: String }) placeholder?: string

		@Inject() view!: View<T>

		internalValue: string = ""

		@Watch("internalValue", { immediate: true })
		valueChanged(newValue: string): void {
			if (newValue === "") {
				this.view.filter(this.field, null)
				return
			}

			this.view.filter(this.field, colValue => {
				const transformed = this.transform(colValue)
				return this.filterFn(transformed, newValue)
			})
		}
	}
