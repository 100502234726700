import { resources } from "api/resources"
import { api, permissionUrl } from "api/index"
import { CompanyOverviewModel, CompanyPermissions } from "api/models/company"
import { Company } from "store/company/types"
import { convertProducts } from "api/conversion/products/product-conversion"
import { Product } from "api/models/products"
import { convertCompanyOverviewModels } from "api/conversion/company/company-conversion"
import convertCompany from "store/company/conversion"
import clientCache from "api/cache/client-cache"

class CompanyClient {
	private readonly resources = resources.companies

	async getCompanies(): Promise<Array<CompanyOverviewModel>> {
		if (clientCache.clients) {
			return clientCache.clients
		}

		clientCache.clients = convertCompanyOverviewModels(await api.getJson(this.resources.getCompanies))
		return clientCache.clients
	}

	async getCompany(companyId: string): Promise<Company> {
		return convertCompany(await api.getJson(this.resources.getCompany(companyId)))
	}

	async getProductsByCompany(companyId: string): Promise<Array<Product>> {
		return convertProducts(await api.getJson(this.resources.getProductsByCompany(companyId)))
	}

	async getPermissions(): Promise<Array<CompanyPermissions>> {
		const response = permissionUrl.get(this.resources.getPermissions()).response
		return (await response).json()	}
	}

export default new CompanyClient()
