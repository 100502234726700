import { storageController } from "lib/storage/session"
import { Product } from "api/models/products"
import { CompanyOverviewModel } from "api/models/company"

class ClientCache {
	private static readonly CACHE_KEYS = {
		products: "portfolio-products",
		clients: "client-products"
	}

	get products(): Array<Product> {
		return storageController.retrieve(ClientCache.CACHE_KEYS.products)
	}

	set products(products: Array<Product>) {
		storageController.store(ClientCache.CACHE_KEYS.products, products)
	}

	get clients(): Array<CompanyOverviewModel> {
		return storageController.retrieve(ClientCache.CACHE_KEYS.clients)
	}

	set clients(clients: Array<CompanyOverviewModel>) {
		storageController.store(ClientCache.CACHE_KEYS.clients, clients)
	}

	invalidateCache(): void {
		for (const cacheKey of Object.values(ClientCache.CACHE_KEYS)) {
			storageController.discard(cacheKey)
		}
	}
}

export default new ClientCache()
