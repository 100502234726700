import { EnumOption } from "store/agreement/constraints/types"
import { Franchise, MaximumSalary, InvestmentFreedom } from "api/models/shared"
import { Scale, Insurance, SurvivorsPension, PaymentTerm, SalaryComponent, PayrollPeriod } from "store/quotation/types"
import { CivilStatusType } from "@lib/types/personal/policyholder"
import { ParticipantsDataChannel } from "store/request-product/types"

export interface ProductDetails {
	externalIdentifier: string
	name: string
	agreementState: AgreementState
	agreementType?: AgreementType
	startDate: Date
	endDate?: Date
	originalStartDate: Date
	clientName?: string
	participantsCount: number
	enrollmentAge: number
	pensionAge: number
	packageCharacteristic: string
	packageVariant: string
	insurance: Insurance
	franchise: EnumOption<Franchise, number>
	maximumSalary: EnumOption<MaximumSalary, number>
	scale: Scale
	survivorsPension: SurvivorsPension
	investmentFreedom: InvestmentFreedom
	employeeContribution?: EmployeeContribution
	dataDeliveryChannel?: ParticipantsDataChannel
	paymentTerm?: PaymentTerm
	pensionableSalaryComponents?: Array<SalaryComponent>
	payrollPeriod?: PayrollPeriod
	premiumFrequency?: PremiumFrequency
	productType?: ProductFrameworkType
}

export enum PremiumFrequency {
	MONTHLY = "MONTHLY",
	QUARTERLY = "QUARTERLY",
	YEARLY = "YEARLY",
	BI_ANNUALLY = "BI_ANNUALLY"
}

export enum ProductFrameworkType {
    WTP = "WTP",
    WNP = "WNP"
}

export interface Product {
	externalIdentifier: string
	name?: string
	agreementState?: AgreementState
	agreementType?: AgreementType
	startDate?: Date
	originalStartDate?: Date
	clientName?: string
	clientId?: string
	actions?: string
	amountOfParticipants?: number
}

export enum AgreementState {
	ACTIVE = "ACTIVE",
	DRAFT = "DRAFT",
	QUOTE = "QUOTE",
	TERMINATED = "TERMINATED"
}

export enum AgreementType {
	MASTER_PRODUCT = "MASTER_PRODUCT",
	CONCEPT = "CONCEPT",
	EMPLOYER = "EMPLOYER",
	EMPLOYEE_GROUP = "EMPLOYEE_GROUP",
	LABOR_UNION_AGREEMENT = "LABOR_UNION_AGREEMENT",
	PENSION_FUND = "PENSION_FUND",
	PRIVATE_AGREEMENT = "PRIVATE_AGREEMENT",
	COMPANY_OWNED_AGREEMENT = "COMPANY_OWNED_AGREEMENT"
}

export interface Participant {
	externalIdentifier: string
	policyNumber: string
	name: string
	dateOfBirth: Date
	civilStatus: CivilStatusType
	employmentStartDate: Date
	salary: number
	parttimePercentage: number
}

export interface ParticipantResponse {
	participants: Array<Participant>
	responseCode: number
}

export interface EmployeeContribution {
	type: EmployeeContributionType
	percentage?: number
}

export enum EmployeeContributionType {
	PREMIUM_BASE = "PREMIUM_BASE",
	PREMIUM = "PREMIUM"
}
