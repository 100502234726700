
	import Vue from "vue"
	import Component from "vue-class-component"
	import IcsButton from "custom/ui/IcsButton.vue"
	import { isUndefined } from "lodash-es"

	import { IcsModalOptions, ModalPosition, ModalType } from "./model/ics-modal-options"

	@Component({
		components: {
			IcsButton
		}
	})
	export default class IcsModal extends Vue {
		showModal = false
		backdrop = true
		position: ModalPosition = ModalPosition.CENTER
		type: ModalType = ModalType.DEFAULT
		data: any = null

		private resolve?: (result: boolean) => void

		show(options: IcsModalOptions = {}): Promise<boolean> {
			this.backdrop = isUndefined(options.backdrop) ? this.backdrop : options.backdrop
			this.position = options.position || this.position
			this.type = options.type || this.type
			this.data = options.data
			this.showModal = true
			return new Promise<boolean>(resolve => this.resolve = resolve)
		}

		cancel(): void {
			this.showModal = false
			if (!!this.resolve) {
				this.resolve(false)
			}
		}

		confirm(): void {
			this.showModal = false
			if (!!this.resolve) {
				this.resolve(true)
			}
		}
	}
