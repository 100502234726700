import Vue from "vue"

import { Mode, Identifiable, ObjectId, Cardinality, ComponentState, Condition, PartialState } from "../types/model"
import { Function0 } from "../types/function"

import Model from "../model/Model"
import MutableModel from "../model/MutableModel"

import { cloneDeep, isUndefined } from "lodash-es"

/**
 * Facilitates the use of the model in a Vue component.
 */
export default class ModelProxy<T extends Identifiable, C extends Cardinality, R> implements ComponentState<T> {
	state: PartialState<T>
	errors = undefined
	condition = Condition.PRISTINE

	private previousMode?: Mode

	constructor(
		private readonly model: Model<T, C, R>,
		private modelMode: Mode,
		private readonly component: Vue
	) {
		this.state = cloneDeep(model.get())
	}

	get mode(): Mode {
		return this.modelMode
	}

	get hydrating(): boolean {
		return this.model.hydrating
	}

	freeze(): void {
		if (this.modelMode === this.previousMode) {
			return
		}

		this.previousMode = this.modelMode
		this.modelMode = Mode.READONLY
	}

	unfreeze(): void {
		if (isUndefined(this.previousMode)) {
			return
		}

		this.modelMode = this.previousMode
		this.previousMode = undefined
	}

	/**
	 * Saves the state to the model.
	 */
	save(): Promise<boolean> {
		return this.model.save(this)
	}

	/**
	 * Deletes the object with the given id from the model. If no id is given, the id of the focused object is used.
	 * This method is not named `delete` because it breaks the Vue build.
	 */
	remove(id?: ObjectId): Promise<void> {
		const objectId = id || this.state.id
		if (objectId) {
			return this.model.delete(this, objectId as string)
		}
		return Promise.reject(new ReferenceError("Cannot delete"))
	}

	/**
	 * Retrieves the data of the object with the given id from the model and populates the state with it.
	 */
	focus(id: ObjectId): boolean {
		return this.model.focus(id)
	}

	/**
	 * Removes the current focus.
	 */
	unfocus(): void {
		this.model.unfocus()
	}

	/**
	 * Forces a validation on the model.
	 *
	 * Validation is performed automatically on every update to the model. Calling this method should only be needed if the
	 * context of the validation has changed.
	 */
	async validate(): Promise<void> {
		if (this.model instanceof MutableModel) {
			await this.model.validate(this)
		}
	}

	/**
	 * Adds a deep watcher to the state. Intended for internal use only (in Vue components, just use `$watch`).
	 */
	subscribe(callback: Function0<void>): void {
		this.component.$watch(
			() => this.state,
			callback,
			{ deep: true }
		)
	}
}
