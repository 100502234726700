const mockCompanyId = "*"

export const resources = {
	quotation: {
		getQuotations: "",
		calculate: (id: string): string => `/${ id }/costs/calculate`,
		calculationCosts: (id: string): string => `/${ id }/costs`,
		getQuote: (id: string): string => `/${ id }`,
		createQuote: (id: string): string => `/${ id }/create-quote`,
		deleteQuote: (id: string): string =>  `/${ id }`,
		declineQuote: (id: string): string => `/${ id }/decline-quote`,
		uploadFile: (id: string): string => `/participants-file/${ id }`,
		createConceptCopy: (id: string): string => `/${ id }/create-concept-copy`,
		requestProduct: (id: string): string => `/${ id }/request`,
		createImplementationAgreement: (id: string): string =>  `/${ id }/implementation-agreement`,
		processAgreementSignature: (id: string): string => `/${ id }/process-agreement-signature`
	},
	agreement: {
		getAgreements: "/products",
		constraints: (id: string, date?: string): string => `/products/${ id }/constraints/${ date ?? "" }`
	},
	products: {
		getProducts: `/intermediary/clients/${ mockCompanyId }/agreements`,
		getProduct: (id: string, companyId: string): string => `/intermediary/clients/${ companyId }/agreements/${ id }`,
		getParticipantsByProductId: (productId: string, companyId: string): string => {
			return `/intermediary/clients/${ companyId }/agreements/${ productId }/participants`
		},
		getParticipantById: (productId: string, participantId: string, companyId: string): string => {
			return  `/intermediary/clients/${ companyId }/agreements/${ productId }/participants/${ participantId }`
		},
		getParticipantPersonalInfo: (productId: string, participantId: string, companyId: string): string => {
			return `/intermediary/clients/${ companyId }/agreements/${ productId }/participants/${ participantId }/private-info/personal`
		},
		getParticipantPension: (productId: string, participantId: string, companyId: string): string => {
			return `/intermediary/clients/${ companyId }/agreements/${ productId }/participants/${ participantId }/private-info/pension`
		},
		getParticipantEvents: (productId: string, participantId: string, companyId: string): string => {
			return `/intermediary/clients/${ companyId }/agreements/${ productId }/participants/${ participantId }/private-info/events`
		},
		getParticipantInvestments: (productId: string, participantId: string, companyId: string): string => {
			return `/intermediary/clients/${ companyId }/agreements/${ productId }/participants/${ participantId }/private-info/investments`
		},
		getDocumentsByProductId: (productId: string, companyId: string) => {
			return `/intermediary/clients/${ companyId }/agreements/${ productId }/documents`
		},
		getDocumentById: (productId: string, documentId: string, companyId: string) => {
			return `/intermediary/clients/${ companyId }/agreements/${ productId }/documents/${ documentId }`
		},
		getDocumentContentById: (productId: string, documentId: string, companyId: string) => {
			return `/intermediary/clients/${ companyId }/agreements/${ productId }/documents/content/${ documentId }`
		}
	},
	companies: {
		getCompanies: "/intermediary/clients",
		getPermissions: () => "/to" ,
		getCompany: (id: string): string => `/intermediary/clients/${ id }`,
		getProductsByCompany: (id: string): string => `/intermediary/clients/${ id }/agreements`,
		getDocumentsByCompanyAndAgreementId: (companyId: string, agreementId: string): string => `/intermediary/clients/${ companyId }/agreements/${ agreementId }/documents`,
		getDocumentContentByCompanyAndAgreementId: (companyId?: string, agreementId?: string, documentId?: string): string => `/intermediary/clients/${ companyId }/agreements/${ agreementId }/documents/content/${ documentId }`
	},
	intermediary: {
		intermediary: "/intermediary"
	},
	user: {
		verificationMail: "/token",
		checkTokenValidity: "/token/validity",
		getCurrentUser: "/identity",
		updateUser: "/portal-users",
		activate: "/activate",
		forgotPassword: "/password/forgot",
		resetPassword: "/password/reset",
		forgotUsername: "/username/forgot",
		usernameUnique: "/exists/username",
		resetUsername: "/username/reset",
		getQrCode: "/2fa/qr-code",
		is2FaActivated: "/2fa/is-activated",
		is2faEnabled: "/2fa/enabled",
		changePassword: "/password/forgot/username",
		getImpersonateTokenByCompany: `/identity/impersonate/company`,
		authenticateTotp: (code: string) => `/2fa/authenticate-totp/${ code }`,
		authenticateTotpFirstTime: (code: string) => `/2fa/authenticate-totp-first-time/${ code }`
	}
}
