import { JwtToken } from "lib/types/security"

export function impersonate(token: JwtToken): void {
	const MESSAGE_EVENT_NAME = "message"
	const IMPERSONATE_TIMEOUT = 1000 * 10
	const handler = createHandler(token)
	addEventListener(MESSAGE_EVENT_NAME, handler)
	setTimeout(() => removeEventListener(MESSAGE_EVENT_NAME, handler), IMPERSONATE_TIMEOUT)
}

function createHandler(token: JwtToken): (event: MessageEvent) => Promise<void> {
	const MESSAGE_EVENT_NAME = "message"
	const impersonateHandler = async (event: MessageEvent): Promise<void> => {
		const port = event.ports[0]
		port.postMessage(token)
		removeEventListener(MESSAGE_EVENT_NAME, impersonateHandler)
	}
	return impersonateHandler
}
