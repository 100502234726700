export enum Franchise {
	UNMARRIED_AVERAGE_PAY = "UNMARRIED_AVERAGE_PAY",
	MARRIED_AVERAGE_PAY = "MARRIED_AVERAGE_PAY",
	UNMARRIED_FINAL_PAY = "UNMARRIED_FINAL_PAY",
	MARRIED_FINAL_PAY = "MARRIED_FINAL_PAY",
	UNMARRIED_WITTEVEEN = "UNMARRIED_WITTEVEEN",
	MARRIED_WITTEVEEN = "MARRIED_WITTEVEEN",
	CUSTOM = "CUSTOM"
}

export enum MaximumSalary {
	FISCAL_MAXIMUM = "FISCAL_MAXIMUM",
	CUSTOM = "CUSTOM"
}

export enum ScaleType {
	AGE_DEPENDENT = "AGE_DEPENDENT",
	FIXED_PREMIUM = "FIXED_PREMIUM",
	TRANSITIONAL_ARRANGEMENT = "TRANSITIONAL_ARRANGEMENT"
}

export enum SurvivorsPensionType {
	RISK_INSURANCE = "RISK_INSURANCE",
	RESTITUTION = "RESTITUTION"
}

export enum InvestmentFreedom {
	NONE = "NONE",
	LIMITED = "LIMITED",
	COMPLETE = "COMPLETE"
}
