
import { GetterTree } from "vuex"
import { RootState } from "store/index"
import { Company, Contact } from "./types"

const getters: GetterTree<Company, RootState> = {
	company(state: Company): Company {
		return state
	},
	companyName(state: Company): string | undefined {
		return state.name
	},
	contact(state: Company): Contact | undefined {
		return state.contact
	}
}

export default getters
