export interface CompanyOverviewModel {
	externalIdentifier: string
	name: string
	agreementCount: number
	clientSince?: Date
	lastUpdated?: Date,
	actions?: string
}

export interface CompanyDetails {
	externalIdentifier: string
	name: string
	address: Addresses
	wageTaxNumber: string
	contact: Contact
	accountManagers: Array<string>
}

export interface CompanyPermissions {
	id: string
	from: string
	to: string
	permission?: Permission | string
}

export interface Addresses {
	business: Business
	postal: Business
}

export interface Business {
	street: string
	number: string
	postalCode: string
	city: string
}

export interface Contact {
	name: string
	email: string
	phoneNumber: string
}

export enum Permission {
	HANDLE_EMPLOYER_ADMINISTRATION = "HANDLE_EMPLOYER_ADMINISTRATION"
}
