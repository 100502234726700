
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Inject, Prop, Watch } from "vue-property-decorator"

	import { Compare } from "@lib/types/function"
	import { Field, View } from "@components/data/view/types"

	import { filterFn, equals } from "./utils/filter-functions"
	import { sortOptionModelsDesc } from "./utils/sort-functions"
	import IcsDropdown from "custom/ui/IcsDropdown.vue"
	import { OptionModel, toOptionModel } from "custom/quotation/model/option-model"

	@Component({
		components: {
			IcsDropdown
		}
	})
	export default class FilterSelect<T, F extends Field<T>> extends Vue {
		@Prop({ type: String, required: true }) field!: F
		@Prop({ type: String, default: "id" }) idField!: F
		@Prop({ type: Function, default: sortOptionModelsDesc }) sort!: Compare<OptionModel>
		@Prop({ type: Function, default: (v: T[F]) => String(v) }) transform!: (value: T[F]) => any
		@Prop({ type: Function, default: equals }) filterFn!: filterFn

		@Inject() view!: View<T>

		internalValue: string | null = null

		get options(): Array<OptionModel> {
			const data = this.view.data.all

			const uniqueItems = [... new Set(data.map(record => record[this.field]))]

			const mapped = uniqueItems.map(record => {
				const value = record ? this.transform(record) : undefined
				return toOptionModel(value, value)
			})

			return mapped.sort(this.sort)
		}

		@Watch("internalValue", { immediate: true })
		valueChanged(selectedOption: string): void {
			if (selectedOption === null) {
				this.view.filter(this.field, null)
				return
			}

			this.view.filter(this.field, colValue => {
				const transformed = this.transform(colValue)
				return this.filterFn(transformed, selectedOption)
			})
		}
	}
