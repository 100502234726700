import { OptionModel } from "custom/quotation/model/option-model"

export function sortOptionModelsDesc(a: OptionModel, b: OptionModel): number {
	if (a.value > b.value) {
		return 1
	}

	if (a.value < b.value) {
		return -1
	}

	return 0
}
