import { ConversionMap, required, optional } from "@lib/types/import"
import { Company, Addresses, Address, Contact } from "./types"
import { alphanumeric } from "@lib/import/convert"
import { one, many, mandatory, def } from "@lib/import/extract"

const address: ConversionMap<Address> = {
	street: ["street", alphanumeric, required],
	number: ["number", alphanumeric, required],
	letter: ["letter", alphanumeric, optional],
	suffix: ["suffix", alphanumeric, optional],
	postalCode: ["postalCode", alphanumeric, required],
	city: ["city", alphanumeric, required]
}

const addresses: ConversionMap<Addresses> = {
	business: ["business", one(address), required],
	postal: ["postal", one(address), required]
}

const contact: ConversionMap<Contact> = {
	initials: ["initials", def("", alphanumeric), optional],
	infix: ["infix", def("", alphanumeric), optional],
	surname: ["surname", alphanumeric, required],
	email: ["email", def("", alphanumeric), optional],
	phoneNumber: ["phoneNumber", alphanumeric, optional]
}

const company: ConversionMap<Company> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, required],
	name: ["name", alphanumeric, required],
	address: ["address", one(addresses), optional],
	wageTaxNumber: ["wageTaxNumber", alphanumeric, optional],
	accountManagers: ["accountManagers", many(alphanumeric), optional],
	contact: ["contact", one(contact), optional],
	license: ["license", alphanumeric, optional]
}

export default mandatory(one(company))
