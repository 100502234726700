import { ActionTree, ActionContext } from "vuex"
import { RootState } from "store/index"
import { Company } from "./types"
import { api } from "api/index"
import convert from "./conversion"
import { MutationType } from "@lib/model/store/mutations"
import { resources } from "api/resources"

const intermediaryResources = resources.intermediary

async function storeResponse(context: ActionContext<Company, void>, response: Response): Promise<void> {
	const state: Company | undefined = convert(await response.json())
	if (!!state) {
		context.commit(MutationType.HYDRATE, state)
	}
}

const actions: ActionTree<Company, RootState> = {
	async getCurrentCompany(context: ActionContext<Company, RootState>): Promise<void> {
		const response = await api.get(intermediaryResources.intermediary).response
		await storeResponse(context, response)
	},
	async updateCompany(context: ActionContext<Company, RootState>, payload: Company): Promise<void> {
		const response = await api.put(intermediaryResources.intermediary, payload).response
		await storeResponse(context, response)
	}
}

export default actions
