import { ConversionMap, required } from "@lib/types/import"
import { Agreement } from "./types"
import { alphanumeric } from "@lib/import/convert"
import { one } from "@lib/import/extract"

const agreement: ConversionMap<Agreement> = {
	id: ["externalIdentifier", alphanumeric, required],
	name: ["name", alphanumeric, required],
	productType: ["productType", alphanumeric, required]
}

export default one(agreement)
