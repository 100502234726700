import { loadGoogleTagManager } from "tracking/google-tag-manager"

export interface Config {
	loginUrl: string
	refreshTokenEndpoint: string
	api: {
		endpoint: string
		authenticationEndpoint: string
		permissionEndPoint: string
	}
	eRecognition: {
		loginUrl: string
		samlUrl: string
		tokenUrl: string
	}
	impersonateUrl: string
	googleTagManager?: {
		id: string
	}
}

declare const config: Config

if (process.env.SERVER) {
	global.config = {
		loginUrl: "",
		refreshTokenEndpoint: "",
		api: {
			endpoint: "",
			authenticationEndpoint: "",
			permissionEndPoint: ""
		},
		impersonateUrl: ""
	}
}

export const loginUrl = config.loginUrl
export const refreshTokenEndpoint = config.refreshTokenEndpoint
export const api = config.api
export const eRecognitionConfig = config.eRecognition
export const impersonateUrl = config.impersonateUrl

loadGoogleTagManager(config)
