import { Identifiable } from "@lib/types/model"
import { FileType } from "store/types"

export interface Contact {
	initials: string
	infix?: string
	surname: string
	phoneNumber?: string
	email: string
}

export interface Address {
	street: string
	number: string
	postalCode: string
	city: string
}

export interface Addresses {
	business: Address
	postal: Address
}

export interface Company {
	name: string
	address: Addresses
	contact: Contact
	wageTaxNumber: string
	chamberOfCommerceNumber: string
}

export enum ParticipantsDataChannel {
	UPA = "UPA",
	PORTAL = "PORTAL",
	BENEFITS_PLAZA = "BENEFITS_PLAZA"
}

export interface RequestProductModel extends Identifiable {
	company: Company
	participantsDataChannel: ParticipantsDataChannel
}

export interface SignProductRequestModel {
	signedAgreement: FileType
	idScan: FileType
}
