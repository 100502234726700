import Vue from "vue"
import Component from "vue-class-component"

import { Mode, Identifiable, Cardinality } from "../types/model"
import { Function0 } from "../types/function"

import Model from "../model/Model"
import ModelProxy from "../model/ModelProxy"

import { noop } from "lodash-es"

/**
 * Base class for components that need access to models.
 */
@Component
export default class VueModel extends Vue {

	attach<T extends Identifiable, C extends Cardinality, R>(
		model: Model<T, C, R>,
		mode: Mode = Mode.READONLY,
		ready: Function0<void> = noop
	): ModelProxy<T, C, R> {
		const modelProxy = new ModelProxy(model, mode, this)

		if (!process.env.SERVER) {
			// Attach the model. This happens asynchronously, and we can't await it because the model state
			// must be returned immediately to be included in the component data.
			// Store hydration occurs on the next tick. See comments in the Store class.
			// Unfortunately we have to take that into account here.
			Vue.nextTick(async () => {
				await model.attach(modelProxy)
				ready()
			})
		}

		return modelProxy
	}
}
