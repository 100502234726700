import Vue from "vue"
import { MutationTree } from "vuex"
import { ProductDetails } from "api/models/products"
import { MutationType } from "lib/model/store/mutations"

const mutations: MutationTree<ProductDetails> = {
	[MutationType.HYDRATE](state: ProductDetails, payload: ProductDetails): void {
		for (const [field, value] of Object.entries(payload)) {
			Vue.set(state, field, value)
		}
	}
}

export default mutations
