import { Module } from "vuex"
import { RootState } from "../index"
import { Message, Messages } from "./types"

const documentsState: Messages = {
	messages: [],
	loaded: false
}

const messagesModule: Module<Messages, RootState> = {
	actions: {
		markMessageAsRead(state, message: Message): Message {
			state.commit("readMessage", message)
			return message
		}
	},
	getters: {
		unreadMessages: state => state.messages.filter(message => !message.read).length
	},
	mutations: {
		hydrate(state, payload: Array<Message>): void {
			state.messages = payload
		},
		readMessage(state, message: Message): void {
			const msg = state.messages.find(m => m.id === message.id)
			if (msg) {
				msg.read = true
			}
		}
	},
	namespaced: true,
	state: documentsState
}

export default messagesModule
