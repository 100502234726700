import { namespace } from "vuex-class"
import { namespaces } from "store/index"

export const productModule = namespace(namespaces.product)
export const agreementModule = namespace(namespaces.agreement)
export const authenticationModule = namespace(namespaces.authentication)
export const constraintsModule = namespace(namespaces.agreementConstraints)
export const companyModule = namespace(namespaces.company)
export const quotationModule = namespace(namespaces.quotation)
export const userModule = namespace(namespaces.user)
