import Vue from "vue"

export default abstract class LoadingComponent extends Vue {
	loading = false

	manageLoadingState<T>(promise: Promise<T>): Promise<T> {
		this.loading = true

		return promise.then(result => result)
		.catch(error => {
			throw new Error(error)
		})
		.finally(() => this.loading = false)
	}
}
