export interface OptionModel {
	value: string | number
	label: string
	disabled?: boolean
}

export interface MultiOptionModel {
	optionModels: Array<OptionModel>
}

export function toOptionModel(value: any, label: string = "", disabled?: boolean): OptionModel {
	return { value, label, disabled }
}
