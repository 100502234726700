import RestDataSource from "@lib/model/datasource/RestDataSource"
import { api } from "api/index"
import { resources } from "api/resources"
import { Cardinality } from "@lib/types/model"
import { Quotation } from "./types"
import convert from "./conversion"
import rules from "./rules"
import actions from "./actions"
import getters from "./getters"

const dataSource = new RestDataSource<Quotation, Cardinality.MANY>(
	api,
	resources.quotation.getQuotations,
	convert,
	Cardinality.MANY
)

export default {
	rules,
	actions,
	getters,
	dataSource
}
