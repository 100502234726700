
	import Vue from "vue"
	import Component from "vue-class-component"
	import IcsIcon from "custom/ui/IcsIcon.vue"
	import { Prop } from "vue-property-decorator"

	@Component({
		components: {
			IcsIcon
		}
	})
	export default class ContentHeader extends Vue {
		@Prop({ type: String }) backHref?: string
	}
