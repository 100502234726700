import { ActionTree, ActionContext } from "vuex"
import { RootState } from "store/index"
import { ProductDetails } from "api/models/products"
import { MutationType } from "lib/model/store/mutations"

type Context = ActionContext<ProductDetails, RootState>

const actions: ActionTree<ProductDetails, RootState> = {
	async setProduct(context: Context, product: ProductDetails): Promise<void> {
		context.commit(MutationType.HYDRATE, product)
	}
}

export default actions
