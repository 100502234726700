import { resources  } from "api/resources"
import { api } from "api/index"
import { ProductDetails, Product, ParticipantResponse } from "api/models/products"
import { convertParticipants, convertProductDetails, convertProducts } from "api/conversion/products/product-conversion"
import { manyDocuments, oneDocument, oneFileType } from "store/conversion"
import { Document, FileType } from "store/types"
import clientCache from "api/cache/client-cache"
import { InvestmentsInfo, Participant, PensionInfo, PersonalInfo } from "api/models/participant"
import { convertInvestmentsInfo, convertParticipant, convertPensionInfo, convertPersonalInfo } from "api/conversion/participant/participant-conversion"

const NO_PERMISSION = 403

class ProductClient {
	private readonly resources = resources.products

	async getProduct(id: string, companyId: string): Promise<ProductDetails> {
		const url = this.resources.getProduct(id, companyId)
		return convertProductDetails(await api.getJson(url))
	}

	async getProducts(): Promise<Array<Product>> {
		if (clientCache.products) {
			return clientCache.products
		}

		clientCache.products = convertProducts(await api.getJson(this.resources.getProducts))
		return clientCache.products
	}

	async getDocumentsByProductId(productId: string, companyId: string): Promise<Array<Document>> {
		const url = this.resources.getDocumentsByProductId(productId, companyId)
		const response = await api.getJson(url)
		return manyDocuments(response) ?? []
	}

	async getDocumentById(agreementId: string, documentId: string, companyId: string): Promise<Document | undefined> {
		const url = this.resources.getDocumentById(agreementId, documentId, companyId)
		const response = await api.getJson(url)
		return oneDocument(response)
	}

	async getDocumentContentById(agreementId: string, documentId: string, companyId: string): Promise<FileType | undefined> {
		const url = this.resources.getDocumentContentById(agreementId, documentId, companyId)
		const response = await api.getJson(url)
		return oneFileType(response)
	}

	async getParticipantsByProductId(id: string, companyId: string): Promise<ParticipantResponse> {
		const url = this.resources.getParticipantsByProductId(id, companyId)
		const response = await api.get(url).response

		if (!response.ok) {
			throw response.status
		}

		return {
			participants: convertParticipants(await response.json()),
			responseCode: response.status
		}
	}

	async getParticipantGeneralInfo(agreementId: string, participantId: string, companyId: string): Promise<Participant> {
		const generalInfoUrl = this.resources.getParticipantById(agreementId, participantId, companyId)
		const response = await api.get(generalInfoUrl).response

		if (response.status === NO_PERMISSION) {
			throw response
		}

		return convertParticipant(await response.json())!
	}

	async getParticipantPersonalInfo(agreementId: string, participantId: string, companyId: string): Promise<PersonalInfo> {
		const personalInfoUrl = this.resources.getParticipantPersonalInfo(agreementId, participantId, companyId)
		const response = await api.get(personalInfoUrl).response

		if (response.status === NO_PERMISSION) {
			throw response
		}

		return convertPersonalInfo(await response.json())!
	}

	async getParticipantPensionInfo(agreementId: string, participantId: string, companyId: string): Promise<PensionInfo> {
		const pensionInfoUrl = this.resources.getParticipantPension(agreementId, participantId, companyId)
		const response = await api.get(pensionInfoUrl).response

		if (response.status === NO_PERMISSION) {
			throw response
		}

		return convertPensionInfo(await response.json())!
	}

	async getParticipantInvestmentsInfo(agreementId: string, participantId: string, companyId: string): Promise<InvestmentsInfo> {
		const investmentInfoUrl = this.resources.getParticipantInvestments(agreementId, participantId, companyId)
		const response = await api.get(investmentInfoUrl).response

		if (response.status === NO_PERMISSION) {
			throw response
		}

		return convertInvestmentsInfo(await response.json())!
	}
}

export default new ProductClient()
