
import { GetterTree } from "vuex"
import { RootState } from "store/index"
import { ProductDetails } from "api/models/products"
import { ParticipantsDataChannel } from "store/request-product/types"

const gettersDefinition: GetterTree<ProductDetails, RootState> = {
	product(state: ProductDetails): ProductDetails | undefined {
		return !!state.externalIdentifier ? state : undefined
	},
	agreementId(state: ProductDetails): string {
		return state.externalIdentifier
	},
	dataDeliveryChannel(state: ProductDetails): ParticipantsDataChannel | undefined {
		return state.dataDeliveryChannel
	},
	clientName(state: ProductDetails): string | undefined {
		return state.clientName
	}
}

export default gettersDefinition
