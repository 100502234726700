import { resources } from "api/resources"
import { authenticationApi } from "api/index"
import { AccountActivation } from "api/models/account"
import { JwtToken } from "lib/types/security"

class UserClient {
	private readonly resources = resources.user

	async isDuplicateUsername(username: string): Promise<boolean> {
		const response =  await authenticationApi.post(this.resources.usernameUnique, { username }).response
		return response.json()
	}

	async sendVerificationMail(email: string): Promise<void> {
		await authenticationApi.post(this.resources.verificationMail, { email }).response
	}

	async checkTokenValidity(token: string): Promise<boolean> {
		const response = await authenticationApi.post(this.resources.checkTokenValidity, { token }).response
		return await response.json()
	}

	async activateUser(user: AccountActivation): Promise<Response> {
		return await authenticationApi.post(this.resources.activate, user).response
	}

	async forgotPassword(email: string): Promise<void> {
		await authenticationApi.post(this.resources.forgotPassword, { email }).response
	}

	async resetPassword(token: string, password: string): Promise<void> {
		await authenticationApi.post(this.resources.resetPassword, { token, password }).response
	}

	async forgotUsername(email: string): Promise<Response> {
		return await authenticationApi.post(this.resources.forgotUsername, { email }).response
	}

	resetUsername(token: string, username: string): Promise<Response> {
		return authenticationApi.post(this.resources.resetUsername, { token, username }).response
	}

	async changePassword(username: string): Promise<Response> {
		return await authenticationApi.post(this.resources.changePassword, { username }).response
	}

	async getImpersonateTokenByCompany(companyId: string): Promise<JwtToken> {
		const response = authenticationApi.post(this.resources.getImpersonateTokenByCompany, { company: companyId }).response
		return (await response).json()
	}

}

export default new UserClient()
