
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch } from "vue-property-decorator"
	import { iconMap, IconType } from "custom/icons"

	@Component
	export default class IcsIcon extends Vue {
		@Prop({type: String, default: "false"}) icon!: IconType

		iconSvg: any = null

		mounted(): void {
			this.setIconSvg()
		}

		@Watch("icon")
		iconChanged(): void {
			this.setIconSvg()
		}

		private setIconSvg(): void {
			this.iconSvg = iconMap[this.icon]
		}
	}
