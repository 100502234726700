import { Identifiable } from "@lib/types/model"
import { EnumOption, CoverageOption } from "store/agreement/constraints/types"
import { Franchise, MaximumSalary, ScaleType, InvestmentFreedom, SurvivorsPensionType } from "api/models/shared"

export interface Quotation extends Identifiable, ParticipantsFile {
	agreementQuoteId?: string
	status: QuotationStatus
	productAgreementId?: string
	startDate?: string
	quoteReference?: string
	company?: Company
	enrollmentAge?: number
	pensionAge?: number
	franchise?: EnumOption<Franchise, number>
	maximumSalary?: EnumOption<MaximumSalary, number>
	scale?: Scale
	paymentTerm?: PaymentTerm
	payrollPeriod?: PayrollPeriod
	pensionableSalaryComponents?: ReadonlyArray<SalaryComponent>
	insurance?: Insurance
	investmentFreedom?: InvestmentFreedom
	survivorsPension?: SurvivorsPension
	participantCount?: number
	participantsFileName?: string
	financialYield?: EnumOption<YieldType, number>
	employeeContributionPercentage?: number
	costs?: Costs
	lastUpdated?: Date
	agreementId?: string
	prognosisFileId?: string
}

export interface ParticipantsFile {
	participantsFileName?: string
}
export interface ParticipantsFileResponse extends ParticipantsFile {
	errors?: Array<string>
}

export interface Company {
	externalIdentifier?: string
	name: string
}

export interface Scale {
	type?: ScaleType
	percentage?: number
	utilizationRate?: number
	fixedBasePremium?: number
}

export interface SurvivorsPension {
	type?: SurvivorsPensionType
	partnerAccrual?: number
	orphanAccrual?: number
	indexation?: number
	yearsOfServiceDetermination?: YearsOfServiceDetermination
}

export interface Insurance {
	anwGap?: AnwGap
	premiumWaiver: boolean
	extraSavings: boolean
}

export enum CalculationStatus {
	CALCULATING = "CALCULATING",
	CALCULATED = "CALCULATED",
	CALCULATION_FAILED = "CALCULATION_FAILED"
}

export interface Costs {
	status: CalculationStatus
	pensionAmount?: number
	partnerPensionAmount?: number
	orphanPensionAmount?: number
	anwPensionAmount?: number
	administrationCosts?: number
	totalCosts?: number
	participantsContribution?: number
}

export enum QuotationStatus {
	CONCEPT = "CONCEPT",
	QUOTE = "QUOTE",
	EXPIRED = "EXPIRED",
	DECLINED = "DECLINED",
	REQUEST = "REQUEST",
	PENDING = "PENDING",
	FINALIZING = "FINALIZING",
	FINAL = "FINAL",
	UNKNOWN = "UNKNOWN"
}

export enum PaymentTerm {
	MONTHLY = "MONTHLY",
	QUARTERLY = "QUARTERLY",
	HALF_YEARLY = "HALF_YEARLY",
	YEARLY = "YEARLY"
}

export enum PayrollPeriod {
	MONTHLY = "MONTHLY",
	FOUR_WEEKLY = "FOUR_WEEKLY"
}

export enum SalaryComponent {
	HOLIDAY_PAY = "HOLIDAY_PAY",
	THIRTEENTH_MONTH = "THIRTEENTH_MONTH"
}

export enum YieldType {
	HISTORICAL = "HISTORICAL",
	CUSTOM = "CUSTOM"
}

export enum YearsOfServiceDetermination {
	IN_SERVICE_DATE = "IN_SERVICE_DATE",
	START_DATE_PENSION_SCHEME = "START_DATE_PENSION_SCHEME"
}

export interface AnwGap {
	active: boolean
	defaultInsured?: boolean
	coverageOptions?: Array<CoverageOption>
	coverageDefault?: CoverageDefault
	indexation?: number
	hasEmployeeContribution?: boolean
}

export interface CoverageDefault {
	percentage: number
	amount?: number
}

interface CalculationResult {
	quoteId: string
}

export interface CalculationSuccessResult extends CalculationResult {
	costs: Costs
}

export interface CalculationErrorResult extends CalculationResult {
	error: Error
	fileErrors?: Array<string>
}

export interface CalculationUpdate {
	quoteId: string
	status: CalculationStatus
}

export interface CalculationData {
	isCalculating: boolean
}

export const CALCULATION_DATA = "CALCULATION_DATA"
