export enum CivilStatusType {
	SINGLE = "SINGLE",
	COHABITATION = "COHABITATION" ,
	LEGAL_COHABITATION = "LEGAL_COHABITATION",
	CIVIL_PARTNERSHIP = "CIVIL_PARTNERSHIP",
	MARRIED = "MARRIED"
}

export enum Gender {
	FEMALE = "FEMALE",
	MALE = "MALE",
	THIRD = "THIRD",
	UNKNOWN = "UNKNOWN"
}

export enum RiskAppetiteType {
	NONE = "NONE",
	LOW = "LOW",
	NEUTRAL = "NEUTRAL",
	HIGH = "HIGH",
	UNKNOWN = "UNKNOWN",
	FREE_INVESTMENTS = "FREE_INVESTMENTS"
}

export enum PreferredBenefitType {
	FIXED = "FIXED",
	VARIABLE = "VARIABLE",
	UNKNOWN = "UNKNOWN"
}

export const PRODUCT_ID_PARAM = "product"
export const PARTICIPANT_ID_PARAM = "participant"
export const COMPANY_ID_PARAM = "company"

export interface Participant {
	externalIdentifier: string
	identificationNumber: string
	policyNumber: string
	initials: string
	fullName: string
	firstName: string
	lastName: string
	dateOfBirth: Date
	civilStatus: CivilStatusType
	salary: number
	parttimePercentage: number
	employmentStartDate: Date
	privateInfo?: PrivateParticipantInfo
}

export interface PrivateParticipantInfo {
	gender: Gender
	address: Address
	personal?: PersonalInfo
	pension?: PensionInfo
	investments?: InvestmentsInfo
}

export interface Address {
	countryCode: string
	street: string
	number: string
	postalCode: string
	city: string
}

export interface PersonalInfo {
	email?: string
	phoneNumber?: string
	partnerKnown: boolean
	childrenKnown: boolean
}

export interface PensionInfo {
	accruedPensionCapital: number
	expectedPensionCapital: number
	anwInsurance: number
	partnerPension: number
	orphanPension: number
	restitutionCapital: number
}

export interface InvestmentsInfo {
	currentRiskProfile?: RiskAppetiteType
	determinationDateRiskProfile?: Date
	preferredBenefit?: PreferredBenefitType
	determinationDatePreferredBenefit?: Date
	currentInvestmentMix: Array<Investment>
}
export interface Investment {
	investmentId: string
	units: number
	value: number
	currency: string
	allocation: InvestmentAllocation
	asset: InvestmentAsset
}
export interface InvestmentAllocation {
	target: number
	current: number
}

export interface InvestmentAsset {
	name: string
	price: number
	category: string
}
