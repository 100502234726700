
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	type AlertType =  "info" | "success" | "warning" | "error"

	@Component
	export default class IcsAlert extends Vue {
		@Prop({ type: String, default: "info" }) type!: AlertType

		get alertClass(): string {
			return `ics-alert__${ this.type }`
		}
	}
