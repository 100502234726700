
import { GetterTree } from "vuex"
import { CollectionState } from "@lib/types/model"
import { RootState, namespaces } from "store/index"
import { Quotation, Company } from "./types"
import { Agreement } from "store/agreement/types"

const gettersDefinition: GetterTree<CollectionState<Quotation>, RootState> = {
	selectedQuotation(state: CollectionState<Quotation>, _getters: any): Quotation | undefined {
		if (!state.id) {
			return undefined
		}

		return state.items.find(quotation => quotation.id === state.id)
	},
	agreement(_state: CollectionState<Quotation>, getters: any, _rootState: any, rootGetters: any): Agreement | undefined {
		const quotation: Quotation | undefined = getters.selectedQuotation
		const agreementId = quotation?.productAgreementId
		if (!agreementId) {
			return undefined
		}

		const agreements: ReadonlyArray<Agreement> = rootGetters[`${namespaces.agreement}/items`]
		return agreements.find(agreement => agreement.id === agreementId)
	},
	agreementNumber(_state: CollectionState<Quotation>, getters: any): string {
		return getters.agreement?.id || ""
	},
	productName(_state: CollectionState<Quotation>, getters: any): string {
		return getters.agreement?.name || ""
	},
	quotationProductType(_state: CollectionState<Quotation>, getters: any): string {
		return getters.agreement?.productType || "WTP"
	},
	selectedCompany(_state: CollectionState<Quotation>, getters: any): Company | undefined {
		const quotation: Quotation | undefined = getters.selectedQuotation
		return quotation?.company
	},
	loading(state: CollectionState<Quotation>): boolean {
		return state.loading
	}
}

export default gettersDefinition
