export interface IcsModalOptions {
	type?: ModalType
	position?: ModalPosition,
	backdrop?: boolean
	data?: any
}

export enum ModalPosition {
	TOP = "top",
	CENTER = "center",
	BOTTOM = "top"
}

export enum ModalType {
	DEFAULT = "default",
	ERROR = "error",
	SUCCESS = "success",
	INFORMATION = "information"
}
