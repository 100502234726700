
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Inject, Prop, Emit } from "vue-property-decorator"

	import { Permission } from "../../../src/api/models/company"
	import Card from "@components/panel/Card.vue"
	import { View, SortOrder } from "@components/data/view/types"
	import { ColValue, Row } from "./model/row"
	import { extractValue } from "./utils/utils"
	import { GridColumn } from "./model/grid-column"

	@Component({
		components: {
			Card
		}
	})
	export default class Grid<T> extends Vue {
		@Prop({ type: String, required: true }) idField!: string
		@Prop({ type: Array, required: true }) columns!: Array<GridColumn<T>>
		@Prop({ type: Array, required: false }) permissions!: Array<any>
		@Prop({ type: Boolean, default: false }) sortable!: boolean

		@Inject() view!: View<T>

		private sortField: string | null = null
		private sortOrder: SortOrder | null = null

		showActions: boolean = false

		beforeMount(): void {
			this.columns.map(column => {
				if (column.field === "actions") {
					this.showActions = true
				} else {
					this.showActions = false
				}
			})
		}

		get rows(): ReadonlyArray<Record<string, any>> {
			return this.view.data.range
		}

		canShowTooltip(col: GridColumn<Row>, rowData: Row): boolean {
			if (col.tooltip instanceof Function) {
				return col.tooltip(rowData)
			}

			return !!col.tooltip
		}

		canShowTooltipWithoutIcon(col: GridColumn<Row>): boolean {
			return !col.hideTooltipIcon
		}

		extractColValue(rowData: Row, key: string): ColValue {
			return extractValue(rowData, key)
		}

		sort(field: string): void {
			if (!this.sortable) {
				return
			}

			if (this.sortField && this.sortOrder) {
				if (field === this.sortField) {
					this.sortOrder *= -1
				} else {
					this.view.sort(this.sortField as keyof T, 0)
					this.sortOrder = 0
				}
			}
			this.sortField = field
			this.sortOrder = this.sortOrder || 1
			this.view.sort(this.sortField as keyof T, this.sortOrder)
		}

		sortClass(field: string): string | undefined {
			if (!this.sortable) {
				return ""
			}

			const sortClass = "sort"

			if (this.sortField === field && this.sortOrder !== null) {
				return `${ sortClass } ${ this.sortOrder === 1 ? "sort--up" : "sort--down" }`
			}
			return sortClass
		}

		isLast(items: Array<any>, index: number): boolean {
			return index === (items.length - 1)
		}

		checkPermission(item: string): boolean {
			return this.permissions.find(i => i.from === item && i.permission === Permission.HANDLE_EMPLOYER_ADMINISTRATION)
		}

		@Emit("row:clicked")
		rowClicked(row: Row): Row {
			return row
		}

		@Emit("action:clicked")
		actionClicked(row: Row, event: Event): Row {
			event.stopPropagation()
			return row
		}
	}
