import Vue from "vue"
import { ActionTree } from "vuex"

import { CollectionState, FieldMutation, ObjectId } from "@lib/types/model"
import { MutationType } from "@lib/model/store/mutations"

import { Quotation, CalculationSuccessResult, CalculationUpdate } from "./types"

import { RootState } from "store/index"
import { RequestProductModel, SignProductRequestModel } from "store/request-product/types"

import QuotationClient from "api/clients/quotation-client"
import eventBus, { FILE_UPLOAD_RESPONSE } from "custom/quotation/event"

const actions: ActionTree<CollectionState<Quotation>, RootState> = {
	async setQuotation(context, quotation: Quotation): Promise<void> {
		context.commit(MutationType.HYDRATE, quotation)
	},
	async storeCalculationUpdate(context, update: CalculationUpdate): Promise<void> {
		const quotation = context.state.items.find(quote => quote.id === update.quoteId)
		if (!quotation) {
			return
		}

		const costs = { ...quotation.costs, status: update.status }
		Vue.set(quotation, "costs", costs)
		context.commit(MutationType.SAVE, quotation)
	},
	async storeCalculationResult(context, result: CalculationSuccessResult): Promise<void> {
		const quotation = await QuotationClient.getQuotation(result.quoteId)
		context.commit(MutationType.SAVE, quotation)
	},
	async deleteCosts(context, id: string): Promise<void> {
		const findQuotationById = (identifier: string) => context.state.items.find(quote => quote.id === identifier)
		if (!findQuotationById(id)?.costs) { return }

		await QuotationClient.deleteCosts(id)

		// Find quotation again because if any fields have changed in the meantime, those changes would be reverted
		const quotation = findQuotationById(id)!
		Vue.set(quotation, "costs", undefined)
		context.commit(MutationType.SAVE, quotation)
	},
	async createQuote(context): Promise<void> {
		if (!context.state.id) {
			return
		}

		const quote = await QuotationClient.createQuote(context.state.id)
		context.commit(MutationType.SAVE, quote)
	},
	async deleteQuote(context, quotation: Quotation): Promise<void> {
		const success = await QuotationClient.deleteQuotation(quotation.id as string)
		if (!success) {
			throw new Error("Unable to store updated quote data in store")
		}

		context.commit(MutationType.DELETE, quotation.id)
	},
	async declineQuote(context): Promise<void> {
		if (!context.state.id) {
			return
		}

		const quote = await QuotationClient.declineQuote(context.state.id)
		context.commit(MutationType.SAVE, quote)
	},
	async createConceptCopy(context): Promise<ObjectId> {
		if (!context.state.id) {
			return
		}

		const copy = await QuotationClient.createConceptCopy(context.state.id)
		context.commit(MutationType.SAVE, copy)
		return copy.id
	},
	async requestProduct(context): Promise<void> {
		if (!context.state.id) {
			return
		}

		const quotation = await QuotationClient.requestProduct(context.state.id)
		context.commit(MutationType.SAVE, quotation)
	},
	async createImplementationAgreement(context, payload: RequestProductModel): Promise<void> {
		if (!context.state.id) {
			return
		}

		const quotation = await QuotationClient.createImplementationAgreement(context.state.id, payload)
		context.commit(MutationType.SAVE, quotation)
	},
	async processAgreementSignature(context, payload: SignProductRequestModel): Promise<void> {
		if (!context.state.id) {
			return
		}

		const quotation = await QuotationClient.createProcessAgreementSignature(context.state.id, payload)
		context.commit(MutationType.SAVE, quotation)
	},
	async uploadParticipantFile(context, file: File): Promise<void> {
		const quotation = context.state.items.find(quote => quote.id === context.state.id)

		if (!quotation) {
			return
		}

		const res = await QuotationClient.uploadParticipantFile(file, quotation.id as string)
		eventBus.$emit(FILE_UPLOAD_RESPONSE, res)
		if (!!res.errors?.length) { return }

		const mutation: FieldMutation<Quotation> = {
			id: quotation.id,
			field: "participantsFileName",
			value: res.participantsFileName
		}
		context.commit(MutationType.UPDATE, mutation)
	}
}

export default actions
