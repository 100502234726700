import { locale, plural } from "@lib/i18n/locale/nl-NL"
import { Options } from "@lib/types/i18n"

// tslint:disable:max-line-length

const options: Options = {
	locale,
	plural,
	texts: {
		general: {
			name: "Naam",
			gender: "Geslacht",
			male: "Man",
			female: "Vrouw",
			perMonth: "per maand",
			netPerMonth: "netto per maand",
			perWeek: "per week",
			hour: "uur",
			save: "Opslaan",
			next: "Verder",
			previous: "Terug",
			yes: "Ja",
			selectedButton: "Geselecteerd",
			selectButton: "Selecteer",
			no: "Nee",
			from: "Vanaf",
			to: "Tot",
			lifelong: "Levenslang",
			capital: "kapitaal",
			loading: "Laden...",
			retrievingData: "Uw gegevens worden opgehaald",
			send: "Verstuur",
			and: "en",
			unknown: "Onbekend",
			notKnown: "Niet bekend",
			true: "Ja",
			false: "Nee",
			dateFormat: {
				day: "dd",
				month: "mm",
				year: "jjjj"
			},
			error: "Er is een fout opgetreden, probeer het nog een keer.",
			backToOverview: "Terug naar overzicht",
			nvt: "N.v.t."
		},
		currency: {
			code: "EUR",
			symbol: "€"
		},
		impersonate: {
			title: "Impersonate",
			loading: "Laden...",
			failed: "Inloggen gefaald"
		},
		status: {
			disabled: "Arbeidsongeschikt",
			active: "Actief",
			inactive: "Inactief",
			pensioner: "Pensioengerechtigd",
			unknown: "Onbekend"
		},
		welcomeCard: {
			morning: "Goedemorgen",
			afternoon: "Goedemiddag",
			evening: "Goedenavond",
			night: "Goedenacht"
		},
		highlights: {
			newBusiness: {
				title: "Offertes",
				actionText: "Bekijk"
			},
			customers: {
				title: "Overzicht klanten",
				actionText: "Bekijk"
			},
			scildonPlaza: {
				title: "Bekijk onze nieuwe pensioenwebsite! ",
				actionText: "Bekijk"
			}
		},
		newQuotation: {
			tableOfContents: {
				concept: "Concept",
				quote: "Offerte",
				summary: "Samenvatting",
				baseData: "Basisgegevens",
				customerData: "Klantgegevens",
				pensionParticipation: "Deelname aan de pensioenregeling",
				enrollmentAge: "Toetredingsleeftijd",
				pensionAge: "Pensioenleeftijd",
				pensionableSalary: "Pensioengrondslagen",
				franchise: "Franchise",
				maxPensionableSalary: "Maximaal pensioengevend salaris",
				salaryComponents: "Pensioengevende salariscomponenten",
				salaryPeriod: "Verloningsperiode",
				availablePremium: "Beschikbare premie",
				scale: "Staffel",
				usagePercentage: "Benuttingspercentage",
				paymentTerm: "Betalingstermijn",
				employeeContribution: "Bijdrage medewerker",
				partnerAndOrphanPension: "Partner- en wezenpensioen",
				additionalArrangements: "Aanvullend regelen",
				investmentChoices: "Beleggingskeuzes",
				investmentFreedom: "Beleggingsvrijheid",
				fundChoice: "Fondskeuze",
				participantData: "Deelnemergegevens",
				numberOfParticipants: "Aantal deelnemers",
				uploadFile: "Upload deelnemerbestand",
				financialYield: "Rendement"
			},
			form: {
				loadingConstraints: "De offertekeuzes worden voor u klaargezet",
				title: "Vul de gegevens in",
				baseData: "Basisgegevens",
				productType: "Voor welk product maakt u een offerte?",
				startDate: "Startdatum",
				signature: "Offerte kenmerk",
				productTypeTooltip: "Het {{ productName }} kan uitsluitend gesloten worden als voortzetting van een voor {{ startDate }} al bestaande regeling.",
				signatureTooltip: "Elke offerte heeft een eigen kenmerk, vul hier uw kenmerk voor deze offerte in.",
				clientData: "Klantgegevens",
				client: "Voor welke klant maakt u een offerte?",
				pensionParticipation: "Deelname aan de pensioenregeling",
				enrollmentAge: "Toetredingsleeftijd",
				pensionAge: "Pensioenleeftijd",
				pensionableSalary: "Pensioengrondslagen",
				franchise: "Franchise",
				maxPensionableSalary: "Maximaal pensioengevend salaris",
				pensionableSalaryComponents: "Welke extra salariscomponenten zijn pensioengevend?",
				salaryPeriod: "Wat is de verloningsperiode?",
				availablePremium: "Beschikbare premie",
				utilizationRate: "Benuttingspercentage",
				paymentTerm: "Wat is de betalingstermijn?",
				yearsOfServiceDetermination: {
					label: "Hoe wordt het aantal dienstjaren bepaald?",
					IN_SERVICE_DATE: "Op basis van de datum	indiensttreding",
					START_DATE_PENSION_SCHEME: "Op basis van de startdatum van de pensioenregeling"
				},
				employeeContribution: "Bijdrage medewerker",
				employeeContributionLabel: "Wat is de werknemersbijdrage?",
				employeeContributionPercentage: "van de pensioengrondslag",
				partnerAndOrphanPension: "Partner- en wezenpensioen",
				survivorsPensionType: "Op risicobasis verzekerd",
				fixedBasePremium: "Gelijkblijvend percentage voor alle medewerkers (kies percentage van salaris {{ min }}%-{{ max }}%)",
				fixedBasePremiumNew: "Gelijkblijvend percentage voor nieuwe medewerkers ",
				fixedBasePremiumNewPercentage: "(kies percentage van de pensioengrondslag {{ min }}%-{{ max }}%)",
				WTP: {
					chooseScale: "Wilt u een gelijkblijvend percentage voor alle medewerkers?",
					chooseScaleTooltip: "Een offerte op basis van een overgangsregeling is op dit moment nog niet beschikbaar. Neem voor een indicatieve berekening contact op met Scildon.",
					transitionalChooseScale: "Kies een staffel",
					transitionalScalePlaceholder: "Kies staffel",
					partnerAccrual: "Verzekerd partnerpensioen",
					orphanAccrual: "Verzekerd wezenpensioen",
					indexationPayOut: "Percentage na-indexatie"
				},
				WNP: {
					chooseScale: "Kies een staffel",
					partnerAccrual: "Opbouwpercentage partnerpensioen",
					orphanAccrual: "Opbouwpercentage wezenpensioen",
					indexationPayOut: "Indexatie na uitkering"
				},
				salary: "van het salaris ({{ min }}-{{ max }}%)",

				additionalArrangements: "Aanvullend regelen",
				anwGap: "ANW hiaatpensioen",
				premiumWaiver: "Premievrijstelling bij arbeidsongeschiktheid",
				extraSavings: "Periodiek bijsparen",
				investmentChoices: "Beleggingskeuzes",
				investmentFreedom: "Hoeveel beleggingsvrijheid krijgen de deelnemers?",
				investmentFreedomTooltip: {
					NONE: "De beleggingen van de deelnemers worden standaard beheerd op basis van de lifecycle Neutraal. De deelnemers kunnen kiezen uit een neutrale lifecycle ten behoeve van een vast of een variabel pensioen. Default wordt belegd in de lifecycle Neutraal ten behoeve van een vaste pensioenuitkering. Verder geldt dat belegd wordt in een vaste fondscombinatie. De deelnemer kan geen andere fondskeuzes maken.",
					LIMITED: "De beleggingen van de deelnemers worden standaard beheerd op basis van de lifecycle Neutraal. De deelnemers kunnen kiezen uit een behoudende, een neutrale of een ambitieuze lifecycle. Daarnaast kunnen deelnemers kiezen voor een variant hierop ten behoeve van een vast of een variabel pensioen. Default wordt belegd in de lifecycle Neutraal ten behoeve van een vaste pensioenuitkering. Verder geldt dat belegd wordt in een vaste fondscombinatie. De deelnemer kan geen andere fondskeuzes maken.",
					COMPLETE: "De beleggingen van de deelnemers worden standaard beheerd op basis van de lifecycle Neutraal. De deelnemers kunnen kiezen uit een behoudende, een neutrale of een ambitieuze lifecycle. Daarnaast kunnen deelnemers kiezen voor een variant hierop ten behoeve van een vast of een variabel pensioen. Default wordt belegd in de lifecycle Neutraal ten behoeve van een vaste pensioenuitkering. Verder geldt dat standaard belegd wordt in een vaste fondscombinatie maar deelnemers kunnen hiervan afwijken en zelf fondskeuzes maken uit het fondsaanbod van Scildon Pensioen."
				},
				participantData: "Deelnemergegevens",
				numberOfParticipants: "Hoeveel deelnemers heeft de pensioenregeling bij aanvang?",
				participants: "deelnemers",
				downloadTemplate: "Download template",
				uploadTemplate: "Upload de gegevens van de deelnemers",
				financialYield: "Rendement",
				financialYieldQuestion: "Met welke voorbeeldrendement wilt u de pensioenindicatie berekenen?",
				sidePanel: {
					title: "De offerte",
					fillInData: "Vul eerst alle gegevens in",
					calculateQuote: "U kunt nu de offerte berekenen",
					calculateButton: "Bereken",
					calculateAgainButton: "Bereken opnieuw",
					calculating: "Uw offerte wordt nu berekend. Bij een hoog aantal deelnemers kan dit even duren. U hoeft hier niet op te wachten, u kunt gerust even verder werken in het portaal. Zodra de offerte klaar is wordt dit zichtbaar in het offerteoverzicht.",
					summaryButton: "Bekijk samenvatting"
				},
				error: "Helaas is er iets mis gegaan. Probeer het nog een keer of neem contact op met Scildon.",
				validation: {
					startDate: {
						min: "Startdatum mag niet voor {{ minDate }} liggen",
						max: "Startdatum mag niet na {{ maxDate }} liggen",
						valid: "De datum is ongeldig",
						firstOfMonth: "De startdatum moet de eerste dag van de maand zijn"
					},
					enrollmentAge: {
						min: "De toetredingsleeftijd is minimaal {{ min }} jaar",
						max: "De toetredingsleeftijd is maximaal {{ max }} jaar"
					},
					pensionAge: {
						min: "De pensioenleeftijd is minimaal {{ min }} jaar",
						max: "De pensioenleeftijd is maximaal {{ max }} jaar"
					},
					franchise: {
						min: "De franchise is minimaal {{ min | money(0) }}",
						max: "De franchise is maximaal {{ max | money(0) }}"
					},
					maximumSalary: {
						min: "Het bedrag is minimaal {{ min | money(0) }}",
						max: "Het bedrag is maximaal {{ max | money(0) }}"
					},
					utilizationRate: {
						maxDecimals: "Het percentage mag maximaal 2 decimalen bevatten",
						min: "Het percentage is minimaal {{ min | number(2) }}%",
						max: "Het percentage is maximaal {{ max | number(2) }}%",
						limitWarning: "Je hebt gekozen voor een gelijkblijvend percentage hoger dan {{ limit | number(1) }}%. Dit betekent dat deze regeling gebaseerd wordt op een marktrentestaffel. Een addendum met deze staffel en de rekenrente waarop deze is gebaseerd zal moeten worden toegevoegd aan de juridische documentatie bij deze offerte. Neem voor de definitieve offertedocumenten daarom contact op met het Client Serviceteam van Scildon."
					},
					fixedBasePremium: {
						maxDecimals: "Het percentage mag maximaal 2 decimalen bevatten",
						min: "Het percentage is minimaal {{ min | number(2) }}%",
						max: "Het percentage is maximaal {{ max | number(2) }}%"
					},
					partnerAccrual: {
						maxDecimals: "Het percentage mag maximaal 2 decimalen bevatten",
						WTP: {
							min: "Het Verzekerd partnerpensioen is minimaal {{ min | number(2) }}%",
							max: "Het Verzekerd partnerpensioen is maximaal {{ max | number(2) }}%"
						},
						WNP: {
							min: "Het opbouwpercentage partnerpensioen is minimaal {{ min }}%",
							max: "Het opbouwpercentage partnerpensioen is maximaal {{ max }}%"
						}
					},
					orphanAccrual: {
						maxDecimals: "Het percentage mag maximaal 2 decimalen bevatten",
						WTP: {
							min: "Het Verzekerd wezenpensioen is minimaal {{ min | number(2) }}%",
							max: "Het Verzekerd wezenpensioen is maximaal {{ max | number(2) }}%"
						},
						WNP: {
							min: "Het opbouwpercentage wezenpensioen is minimaal {{ min }}%",
							max: "Het opbouwpercentage wezenpensioen is maximaal {{ max }}%"
						}
					},
					employeeContributionPercentage: {
						min: "De werknemersbijdrage is minimaal {{ min }}%",
						max: "De werknemersbijdrage is maximaal {{ max }}%"
					},
					financialYield: {
						min: "Het voorbeeldpercentage mag niet kleiner zijn dan {{ min }}",
						max: "Het voorbeeldpercentage mag niet groter zijn dan {{ max }}%"
					},
					participants: {
						min: "Het minimum aantal deelnemers is {{ min }}",
						max: "Gezien het aantal deelnemers willen wij graag de offerte zo goed mogelijk afstemmen op uw wensen. Neem dan ook even contact op met uw Accountmanager Pensioen."
					}
				},
				scale: {
					ageDependant: {
						columns: {
							ageRange: "Leeftijd",
							percentage: "Rekenrente premiestaffel",
							derivedPercentage: "Afgeleid staffelpercentage"
						},
						year: "jaar",
						title: "Leeftijdsafhankelijke staffels"
					}
				}
			},
			summary: {
				title: "Samenvatting",
				baseData: "Basisgegevens",
				product: "Product",
				startDate: "Startdatum",
				signature: "Offerte kenmerk",
				customerData: "Klantgegevens",
				customerName: "Naam klant",
				pensionParticipation: "Deelname aan de pensioenregeling",
				enrollmentAge: "Toetredingsleeftijd",
				pensionAge: "Pensioenleeftijd",
				year: "jaar",
				pensionableSalary: "Pensioengrondslagen",
				franchise: "Franchise",
				maximumSalary: "Maximaal pensioengevend salaris",
				pensionableSalaryComponents: "Pensioengevende salariscomponenten",
				payrollPeriod: "Verloningsperiode",
				availablePremium: "Beschikbare premie",
				relativeToSalary: "van het salaris",
				premiumPercentage: "van de pensioengrondslag",
				scale: {
					WTP: {
						type: "Wilt u een gelijkblijvend percentage voor alle medewerkers?"
					},
					WNP: {
						type: "Type staffel"
					},
					transitionPercentage: "Staffel",
					ageDependentPercentage: "Rekenrente premiestaffel",
					utilizationRate: "Benuttingspercentage",
					gradingPercentage: "Staffelpercentage",
					fixedBasePremium: "Gelijkblijvend percentage voor alle medewerkers",
					fixedBasePremiumNew: "Gelijkblijvend percentage voor nieuwe medewerkers"
				},
				paymentTerm: "Betaaltermijn",
				employeeContribution: "Bijdrage medewerker",
				survivorsPension: {
					title: "Partner- en wezenpensioen",
					type: "Type partner- en wezenpensioen",
					WTP: {
						partnerAccrual: "Verzekerd partnerpensioen",
						orphanAccrual: "Verzekerd wezenpensioen",
						indexation: "Percentage na-indexatie"
					},
					WNP: {
						partnerAccrual: "Opbouwpercentage partnerpensioen",
						orphanAccrual: "Opbouwpercentage wezenpensioen",
						indexation: "Indexatie na uitkering"
					},
					yearsOfServiceDetermination: {
						label: "Aantal dienstjaren",
						IN_SERVICE_DATE: "Op basis van de datum	indiensttreding",
						START_DATE_PENSION_SCHEME: "Op basis van de startdatum van de pensioenregeling"
					}
				},
				additionalArrangements: "Aanvullend regelen",
				anwGap: {
					title: "ANW hiaatpensioen",
					defaultInsured: "Is de deelnemer standaard verzekerd?",
					coverageOptions: "Welke bedragen kan de deelnemer kiezen om te verzekeren?",
					coverageDefault: "Welk bedrag wordt standaard voor de deelnemer verzekerd?",
					indexation: "Indexatie na uitkering",
					hasEmployeeContribution: "Betaalt de deelnemer een eigen bijdrage?",
					defaultInsuredOptions: {
						true: "Ja (de deelnemer kan kiezen om de verzekering op te zeggen)",
						false: "Nee (de deelnemer kan kiezen om de verzekering af te sluiten)"
					},
					coverageOption: "{{ percentage }}% van de uitkering (in {{ year }}: {{ amount | money }})"
				},
				premiumWaiver: "Premievrijstelling bij arbeidsongeschiktheid",
				extraSavings: "Periodiek bijsparen",
				investmentChoices: "Beleggingskeuzes",
				investmentFreedom: "Beleggingsvrijheid",
				participantData: "Deelnemergegevens",
				participantCount: "Aantal deelnemers",
				participantOverview: "Overzicht deelnemers",
				financialYield: "Rendement",
				exampleYield: "Voorbeeldrendement",
				createQuote: "Maak een offerte",
				saveAsConcept: "Opslaan als concept",
				back: "Terug",
				none: "Geen",
				franchiseValues: {
					CUSTOM: "Kies zelf een bedrag",
					UNMARRIED_AVERAGE_PAY: "100/75 AOW ongehuwd {{ unMarriedAveragePayValue | money(0) }}",
					MARRIED_AVERAGE_PAY: "100/75 AOW gehuwd {{ marriedAveragePayValue | money(0) }}",
					UNMARRIED_FINAL_PAY: "100/66,28 AOW ongehuwd {{ unMarriedFinalPayValue | money(0) }}",
					MARRIED_FINAL_PAY: "100/66,28 AOW gehuwd {{ marriedFinalPayValue | money(0) }}",
					UNMARRIED_WITTEVEEN: "10/7 AOW ongehuwd {{ unMarriedWitteveenValue | money(0) }}",
					MARRIED_WITTEVEEN: "10/7 AOW gehuwd {{ marriedWitteveenValue | money(0) }}"
				},
				maximumSalaryValues: {
					CUSTOM: "Kies zelf een bedrag",
					FISCAL_MAXIMUM: "Fiscaal maximum (in {{ year }}: {{ fiscalMax | money(0) }})"
				},
				pensionableSalaryComponentValues: {
					HOLIDAY_PAY: "Vakantiegeld",
					THIRTEENTH_MONTH: "13e maand"
				},
				payrollPeriodValues: {
					MONTHLY: "Maandelijks",
					FOUR_WEEKLY: "Vierwekelijks"
				},
				scaleTypeValues: {
					WNP: {
						AGE_DEPENDENT: "Leeftijdsafhankelijke staffel",
						FIXED_PREMIUM: "Gelijkblijvend percentage"
					},
					WTP: {
						FIXED_PREMIUM: "Ja",
						TRANSITIONAL_ARRANGEMENT: "Nee, ik kies voor de overgangsregeling"
					}
				},
				paymentTermValues: {
					MONTHLY: "Maandelijks",
					QUARTERLY: "Kwartaal",
					HALF_YEARLY: "Halfjaarlijks",
					YEARLY: "Jaarlijks"
				},
				survivorsPensionValues: {
					RISK_INSURANCE: "Op risicobasis verzekerd",
					RESTITUTION: "90% restitutie van het kapitaal"
				},
				investmentFreedomValues: {
					NONE: "Geen beleggingsvrijheid",
					LIMITED: "Beperkte beleggingsvrijheid",
					COMPLETE: "Volledige beleggingsvrijheid"
				},
				financialYieldValues: {
					HISTORICAL: "Historisch rendement"
				}
			},
			result: {
				title: "Kosten {{ year }}",
				agePension: "Ouderdomspensioen",
				partnerPension: "Partnerpensioen",
				orphanPension: "Wezenpensioen",
				anwPension: "ANW hiaatpensioen",
				administrationCosts: "Administratiekosten",
				totalCosts: "Totaal",
				participantsContribution: "Bijdrage medewerkers",
				prognosis: "Prognoseberekening",
				pensionIndicationPerParticipant: "Indicatie pensioenresultaten en lasten"
			},
			quote: {
				title: "Offerte | {{ quoteId }} | {{ companyName }}",
				summary: "Bekijk de samenvatting van de offerte",
				documents: "Offerte documenten",
				requestProduct: "Vraag product aan",
				declineQuote: "Wijs offerte af",
				adjustQuote: "Pas offerte aan"
			},
			anwGap: {
				defaultInsured: "Is de deelnemer standaard verzekerd?",
				coverageOptions: "Welke bedragen kan de deelnemer kiezen om te verzekeren?",
				coverageDefault: "Welk bedrag wordt standaard voor de deelnemer verzekerd?",
				indexation: "Indexatie na uitkering",
				hasEmployeeContribution: "Betaalt de deelnemer een eigen bijdrage?",
				defaultInsuredOptions: {
					true: "Ja (de deelnemer kan kiezen om de verzekering op te zeggen)",
					false: "Nee (de deelnemer kan kiezen om de verzekering af te sluiten)"
				},
				coverageOption: "{{ percentage }}% van de uitkering (in {{ year }}: {{ amount | money }})"
			}
		},
		newBusiness: {
			title: "Offertes",
			new: "Maak nieuwe offerte",
			open: "Openen",
			delete: "Verwijderen",
			filters: {
				customer: "Klant",
				product: "Alle producten",
				status: "Alle statussen"
			},
			column: {
				quote: "Offerte",
				product: "Product",
				customer: "Klant",
				lastUpdated: "Laatste update",
				signature: "Offerte Kenmerk",
				status: "Status"
			},
			statusTooltip: "De uitvoeringsovereenkomst staat klaar. U kunt de aanvraag afronden.",
			calculationFailedTooltip: "Er is iets misgegaan met de berekening. Probeer het opnieuw.",
			calculationSuccessTooltip: "De kosten en pensioenindicaties staan voor u klaar",
			concept: "Concept",
			status: {
				concept: "Concept",
				quote: "Offerte",
				request: "Aanvraag",
				final: "Afgerond",
				pending: "In behandeling",
				finalizing: "Afronden",
				expired: "Verlopen",
				declined: "Afgewezen",
				unknown: "Onbekend",
				calulating: "Aan het berekenen",
				finishedCalculation: "Berekening is klaar"
			},
			modal: {
				header: "Concept verwijderen?",
				content: "Weet u zeker dat u het concept voor {{ customer }} wilt verwijderen?",
				cancel: "Annuleren",
				confirm: "Verwijderen"
			},
			loading: "De gegevens worden opgehaald"
		},
		requestProduct: {
			tableOfContents: {
				request: "Aanvraag",
				finalize: "Afronden",
				pending: "In behandeling",
				final: "Afgerond",
				customerData: "Klantgegevens",
				contactPerson: "Contactpersoon",
				participantData: "Aanlevering deelnemergegevens",
				summary: "Samenvatting",
				waiting: "Wacht op de overeenkomst",
				uploadDocuments: "Upload documenten",
				confirmation: "Bevestiging"
			}
		},
		requestProductForm: {
			title: "Aanvraag",
			customerData: {
				title: "Klantgegevens",
				companyName: "Bedrijfsnaam",
				address: {
					street: "Straat",
					streetOrPostOfficeBox: "Straat of Postbus",
					number: "Nummer",
					postalCode: "Postcode",
					city: "Plaats"
				},
				companyAddress: "Vestigingsadres",
				postalAddress: "Postadres",
				postalAddressSame: "Het postadres is hetzelfde als het vestigingsadres",
				chamberOfCommerceNumber: "Kamer van Koophandel nummer",
				wageTaxNumber: "Loonheffingennummer"
			},
			contactPerson: {
				title: "Contactpersoon",
				contactPersonExplanation: "De contactpersoon krijgt toegang tot het werkgeversportaal",
				initials: "Voorletters",
				infix: "Tussenvoegsel",
				surname: "Achternaam",
				phoneNumber: "Telefoonnummer",
				email: "E-mailadres"
			},
			participantData: {
				title: "Aanlevering deelnemergegevens",
				dataSource: "Hoe worden de deelnemergegevens aangeleverd?",
				options: {
					UPA: "De werkgever levert deelnemergegevens via UPA",
					PORTAL: "De werkgever levert deelnemergegevens via het werkgeversportaal",
					BENEFITS_PLAZA: "De intermediair levert deelnemergegevens via BenefitsPlaza"
				}
			},
			validation: {
				address: {
					street: {
						required: "Straat is verplicht"
					},
					number: {
						required: "Huisnummer is verplicht"
					},
					postalCode: {
						required: "Postcode is verplicht",
						valid: "Postcode is ongeldig"
					},
					city: {
						required: "Plaats is verplicht"
					}
				},
				chamberOfCommerceNumber: {
					valid: "Het Kamer van Koophandel nummer bestaat uit 8 of 12 cijfers"
				},
				wageTaxNumber: {
					valid: "Het Loonheffingennummer is niet juist"
				}
			},
			submitButton: "Bekijk de samenvatting"
		},
		requestProductSummary: {
			title: "Aanvraag",
			requesterror: "De combinatie van postcode {{postalcode}} en huisnummer {{postalnumber}} is niet geldig.",
			customerData: {
				title: "Klantgegevens",
				companyName: "Bedrijfsnaam",
				companyAddress: "Vestigingsadres",
				postalAddress: "Postadres",
				postalAddressSame: "Het postadres is hetzelfde als het vestigingsadres",
				chamberOfCommerceNumber: "Kamer van Koophandel nummer",
				wageTaxNumber: "Loonheffingennummer"
			},
			contactPerson: {
				title: "Contactpersoon",
				contactPersonExplanation: "De contactpersoon krijgt toegang tot het werkgeversportaal",
				initials: "Voorletters",
				infix: "Tussenvoegsel",
				surname: "Achternaam",
				phoneNumber: "Telefoonnummer",
				email: "E-mailadres"
			},
			participantData: {
				title: "Aanlevering deelnemergegevens",
				options: {
					UPA: "De werkgever levert deelnemergegevens via UPA",
					PORTAL: "De werkgever levert deelnemergegevens via het werkgeversportaal",
					BENEFITS_PLAZA: "De intermediair levert deelnemergegevens via BenefitsPlaza"
				}
			},
			submitButton: "Maak de overeenkomst",
			back: "Terug"
		},
		productPending: {
			text: "Scildon maakt alle documenten in orde. We controleren de onderneming en doen een aantal standaard checks voordat we de onderneming kunnen accepteren. Dit duurt ongeveer 5 werkdagen. Zodra de uitvoeringsovereenkomst beschikbaar is, kunt u de documenten hier downloaden.",
			summary: "Bekijk de samenvatting van de offerte"
		},
		finalizeProductForm: {
			uploadError: "Het bestand kan niet worden verstuurd omdat de grootte van het bestand groter is dan het limiet van 10 MB",
			uploadtext: "U kunt een bestand uploaden van maximaal 10 MB",
			title: "Uitvoeringsovereenkomst | {{ agreementNumber }} | {{ companyName }}",
			agreement: {
				title: "De uitvoeringsovereenkomst",
				agreementName: "Uitvoeringsovereenkomst - {{ agreementNumber }} - {{ companyName }}"
			},
			uploadDocuments: {
				title: "Upload documenten",
				signedAgreement: "Ondertekende uitvoeringsovereenkomst",
				idScan: "Kopie legitimatiebewijs tekenbevoegde"
			},
			submitButton: "Verstuur"
		},
		productRequestSuccess: {
			title: "Gefeliciteerd! {{ companyName }} is nu klant bij Scildon!",
			text: "Wij sturen de contactpersoon een e-mail met alle belangrijke acties die ondernomen moeten worden, voor een vlekkeloze pensioenstart bij Scildon.",
			submitButton: "Afsluiten"
		},
		portfolio: {
			title: "Portfolio",
			tabs: {
				products: "Producten",
				customers: "Klanten"
			},
			customers: {
				empty: "Er zijn nog geen klanten",
				customer: "Klant",
				customerNumber: "Klantnummer",
				search: "Zoek",
				details: "Details",
				column: {
					customerNumber: "Klantnummer",
					name: "Klant",
					clientSince: "Klant sinds",
					productAmount: "Aantal producten",
					actions: "Acties"
				}
			},
			products: {
				empty: "Er zijn nog geen producten",
				column: {
					schemeNumber: "Regelingnummer",
					customer: "Klant",
					startDate: "Startdatum",
					amountOfParticipants: "Aantal deelnemers",
					actions: "Acties"
				}
			}
		},
		customer: {
			tabs: {
				details: "Details",
				contactPerson: "Contactpersoon",
				products: "Producten"
			},
			customerData: {
				companyName: "Bedrijfsnaam",
				address: "Adres"
			},
			contactPerson: {
				name: "Naam",
				initials: "Voorletters",
				infix: "Tussenvoegsel",
				surname: "Achternaam",
				email: "E-mail",
				phoneNumber: "Telefoonnummer"
			},
			customerProducts: {
				scildonPension: "Scildon pensioen"
			}
		},
		product: {
			title: "Scildon Pensioen",
			tabs: {
				details: "Details",
				participants: "Deelnemers",
				file: "Documenten Archief",
				changes: "Wijzigingen"
			},
			productDetails: {
				product: "Type regeling",
				basicData: "Basisgegevens",
				startDate: "Startdatum",
				endDate: "Einddatum",
				participationInThePensionScheme: "Deelname aan de pensioenregeling",
				accessionAge: "Toetredingsleeftijd",
				pensionAge: "Pensioenleeftijd",
				pensionableSalary: "Pensioengrondslagen",
				franchise: "Franchise",
				franchiseValues: {
					CUSTOM: "Kies zelf een bedrag",
					UNMARRIED_AVERAGE_PAY: "100/75 AOW ongehuwd {{ value | money(0) }}",
					MARRIED_AVERAGE_PAY: "100/75 AOW gehuwd {{ value | money(0) }}",
					UNMARRIED_FINAL_PAY: "100/66,28 AOW ongehuwd {{ value | money(0) }}",
					MARRIED_FINAL_PAY: "100/66,28 AOW gehuwd {{ value | money(0) }}",
					UNMARRIED_WITTEVEEN: "10/7 AOW ongehuwd {{ value | money(0) }}",
					MARRIED_WITTEVEEN: "10/7 AOW gehuwd {{ value | money(0) }}"
				},
				maximumPensionableSalary: "Maximum pensioengevend salaris",
				availablePremium: "Beschikbare premie",
				relativeToSalary: "van het salaris",
				premiumPercentage: "van de pensioengrondslag",
				scale: {
					transitionalPercentage: "Staffel",
					label: "Type staffel",
					existingLabel: "Bestaande medewerkers",
					AGE_DEPENDENT: "Leeftijdsafhankelijke staffel",
					FIXED_PREMIUM: "Gelijkblijvend percentage",
					TRANSITIONAL_ARRANGEMENT: "Leeftijdsafhankelijke staffel",
					ageDependentPercentage: "Rekenrente premiestaffel",
					gradingPercentage: "Staffelpercentage"
				},
				fixedBasePremium: "Gelijkblijvend percentage voor alle medewerkers",
				fixedBasePremiumNew: "Nieuwe medewerkers: gelijkblijvend percentage",
				utilizationRate: "Benuttingspercentage",
				employeeContribution: "Bijdrage medewerker",
				employeeContributionOwn: "Eigen bijdrage medewerker",
				ofPremium: "{{ percentage }}% van de premie",
				ofPension: "{{ percentage }}% van de pensioengrondslag",
				partnerAndOrphanPension: "Partner- en wezenpensioen",
				typeOfPartnerAndOrphanPension: {
					label: "Type partner- en wezenpensioen",
					RISK_INSURANCE: "Op risicobasis verzekerd",
					RESTITUTION: "90% restitutie van het kapitaal"
				},
				survivorsPension: {
					WTP: {
						partnerAccrual: "Verzekerd partnerpensioen",
						orphanAccrual: "Verzekerd wezenpensioen",
						indexation: "Percentage na-indexatie"
					},
					WNP: {
						partnerAccrual: "Opbouwpercentage partnerpensioen",
						orphanAccrual: "Opbouwpercentage wezenpensioen",
						indexation: "Indexatie na uitkering"
					}
				},
				additionRegulation: "Aanvullend regelen",
				anwGap: {
					title: "ANW hiaatpensioen",
					defaultInsured: "Is de deelnemer standaard verzekerd?",
					coverageOptions: "Welke bedragen kan de deelnemer kiezen om te verzekeren?",
					coverageDefault: "Welk bedrag wordt standaard voor de deelnemer verzekerd?",
					indexation: "Indexatie na uitkering",
					hasEmployeeContribution: "Betaalt de deelnemer een eigen bijdrage?",
					defaultInsuredOptions: {
						true: "Ja (de deelnemer kan kiezen om de verzekering op te zeggen)",
						false: "Nee (de deelnemer kan kiezen om de verzekering af te sluiten)"
					},
					coverageOption: "{{ percentage }}% van de uitkering"
				} ,
				savePeriodically: "Periodiek bijsparen",
				waiverOfPremiumIncapacityOfWork: "Premievrijstelling bij arbeidsongeschiktheid",
				investmentChoices: "Beleggingskeuzes",
				investmentFreedom: {
					label: "Beleggingsvrijheid",
					NONE: "Geen beleggingsvrijheid",
					LIMITED: "Beperkte beleggingsvrijheid",
					COMPLETE: "Volledige beleggingsvrijheid"
				},
				contractDetails: "Contractdetails",
				schemeNumber: "Regelingnummer",
				schemeCharacteristic: "Regelingkenmerk",
				schemeVariant: "Regelingvariant",
				dataDelivery: "Methode van data-aanlevering",
				dataDeliveryMethod: {
					UPA: "Werkgever via UPA",
					PORTAL: "Werkgever via portaal",
					BENEFITS_PLAZA: "Intermediair via BenefitsPlaza"
				},
				yearsOfServiceDetermination: {
					label: "Aantal dienstjaren",
					IN_SERVICE_DATE: "Op basis van de datum	indiensttreding",
					START_DATE_PENSION_SCHEME: "Op basis van de startdatum van de pensioenregeling"
				},
				paymentTerm: "Betaaltermijn",
				paymentTermValues: {
					MONTHLY: "Maandelijks",
					QUARTERLY: "Kwartaal",
					YEARLY: "Jaarlijks",
					BI_ANNUALLY: "Halfjaarlijks"
				},
				maximumSalaryValues: {
					CUSTOM: "Kies zelf een bedrag",
					FISCAL_MAXIMUM: "Fiscaal maximum (in {{ year }}: {{ fiscalMax | money(0) }})"
				},
				year: "jaar",
				impersonate: "Naar werkgeverportaal"
			},
			participants: {
				empty: "Er zijn nog geen deelnemers",
				noPermissionToViewData: "{{ companyName }} moet toestemming geven om inzicht te krijgen in de werknemergegevens. De werkgever kan toestemming geven via het werkgeverportaal.",
				employerDeniedWarning: "De werkgever heeft geen toestemming gegeven om de gegevens van de deelnemers in te zien. U ziet hier alleen de deelnemers die zelf toestemming hebben gegeven om de gegevens te delen. Het kan dus zijn dat u niet alle deelnemers in de pensioenregeling ziet.",
				column: {
					policyNumber: "Deelnamenummer",
					name: "Naam",
					dateOfBirth: "Geboortedatum",
					civilStatus: "Burgerlijke staat",
					startDateEmployment: "In dienst",
					salary: "Salaris",
					fte: "FTE"
				},
				civilStatus: {
					SINGLE: "Alleenstaand",
					COHABITATION: "Samenwonend",
					LEGAL_COHABITATION: "Samenwonend met contract",
					CIVIL_PARTNERSHIP: "Geregistreerd partnerschap",
					MARRIED: "Gehuwd"
				}
			},
			productFile: {
				column: {
					date: "Datum",
					name: "Naam document"
				},
				filters: {
					date: "Datum",
					year: "Jaar"
				},
				noFiles: "Er zijn nog geen documenten beschikbaar."
			},
			productChanges: {
				changeParticipantData: "Deelnemergegevens wijzigen",
				changePensionScheme: "Pensioenregeling wijzigen",
				endPensionScheme: "Pensioenregeling beëindigen",
				changePensionSchemeText: "Wilt u een pensioenregeling verlengen of wijzigen? Neem dan contact op met Scildon."
			}
		},
		inbox: {
			title: "Inbox",
			noMessages: "U heeft nog geen berichten van ons ontvangen."
		},
		profileWidget: {
			title: "Profiel",
			email: "E-mailadres",
			telephone: "Telefoonnummer",
			unknown: "Niet bekend",
			toProfile: "Naar profiel"
		},
		activityWidget: {
			title: "Laatst gewijzigd",
			noActivity: "U heeft nog niets gewijzigd.",
			concept: "Concept",
			quoteTitle: "Offerte",
			customerTitle: "Klant",
			statusTitle: "Status",
			status: {
				CONCEPT: "Concept",
				QUOTE: "Offerte",
				REQUEST: "Aanvraag",
				FINAL: "Afgerond",
				PENDING: "In behandeling",
				FINALIZING: "Afronden",
				EXPIRED: "Verlopen",
				DECLINED: "Afgewezen"
			}
		},
		messagesWidget: {
			title: "Inbox",
			noMessages: "Er zijn nog geen berichten beschikbaar in uw inbox.",
			toMessages: "Naar inbox"
		},
		footer: {
			wwft: "WWFT: ken uw klant",
			disclaimer: "Disclaimer",
			cookies: "Cookieverklaring",
			privacy: "Privacyverklaring",
			contact: "Contact",
			termsOfUse: "Gebruiksvoorwaarden"
		},
		profile: {
			title: "Profiel",
			tabs: {
				myDetails: "Mijn gegevens",
				myCompany: "Mijn bedrijf",
				insurerContact: "Contact met verzekeraar"
			},
			myDetails: {
				name: "Naam",
				email: "E-mailadres",
				save: "Opslaan",
				success: "Uw contactgegevens zijn opgeslagen.",
				error: "Er is iets fout gegaan tijdens het opslaan van de contactgegevens.",
				validation: {
					name: {
						required: "De naam is verplicht"
					},
					email: {
						required: "Het e-mailadres is verplicht",
						pattern: "Het e-mailadres is niet geldig"
					}
				}
			},
			myCompany: {
				companyName: "Bedrijfsnaam",
				address: "Adres",
				license: "Licentie",
				unknown: "Niet bekend"
			},
			insurerContact: {
				name: "Naam",
				initials: "Initialen",
				infix: "Tussenvoegsel",
				surname: "Achternaam",
				phoneNumber: "Telefoonnummer",
				names: "Namen",
				contactScildonForChange: "Wilt u de contactgegevens aanpassen, neem dan contact op met Scildon.",
				accountManagerAtScildon: "Accountmanagers bij Scildon",
				contactPersonForScildon: "Contactpersoon voor Scildon",
				save: "Opslaan",
				email: "E-mailadres",
				success: "Uw contactgegevens zijn opgeslagen.",
				error: "Er is iets fout gegaan tijdens het opslaan van de contactgegevens.",
				validation: {
					initials: {
						required: "De initialen zijn verplicht"
					},
					surname: {
						required: "De achternaam is verplicht"
					},
					email: {
						required: "Het e-mailadres is verplicht",
						pattern: "Het e-mailadres is niet geldig"
					}
				}
			}
		},
		validation: {
			required: "Dit veld is verplicht",
			invalidEmail: "Dit is geen geldig e-mailadres",
			makeAChoice: "Maak een keuze"
		},
		login: {
			title: "Inloggen bij Scildon Online Pensioenen",
			body: "U regelt de pensioenzaken voor uw klanten bij Scildon digitaal. Als adviseur kunt u hier inloggen bij Scildon Online Pensioenen.",
			username: "Gebruikersnaam",
			password: "Wachtwoord",
			submit: "Log in",
			serverError: "Er is iets fout gegaan tijdens het ophalen van uw gegevens, probeer het nog een keer.",
			loading: "Uw gegevens worden opgehaald...",
			error: {
				UNKNOWN: "Uw inloggegevens zijn niet juist.",
				ACCOUNT_LOCKED: "Uw account is voor 15 minuten geblokkeerd, omdat u 5 keer de verkeerde inloggegevens heeft ingevuld.",
				USER_DISABLED: "Uw account is geblokkeerd.",
				PASSWORD_EXPIRED: "Uw wachtwoord is verlopen.",
				ACCOUNT_EXPIRED: "Uw account is verlopen."
			},
			responseCode: {
				label: "Response code",
				submit: "Inloggen",
				description: "Bij het activeren van uw account heeft u een QR-code gescand met een app die tweestapsverificatie ondersteunt. Open de app en vul de response code in die u ziet.",
				twoStepVerification: "Tweestapsverificatie opnieuw instellen?",
				contact: "Neem contact op met uw beheerder",
				error: "Uw responsecode is niet geldig."
			},
			links: {
				activate: "Eerste bezoek? Activeer uw account",
				forgotPassword: "Wachtwoord vergeten?",
				forgotUsername: "Gebruikersnaam vergeten?"
			},
			emailVerification: {
				verificationCode: "Verificatiecode",
				title: "Vul uw verificatiecode in",
				body: "U heeft een e-mail ontvangen. In de e-mail staat een code. Vul deze code hieronder in.",
				verificationError: "Uw verificatiecode is niet geldig.",
				nextButton: "Verder"
			},
			eRecognition: {
				link: "Inloggen met eHerkenning",
				description: "Meer zekerheid over uw online identiteit, daarom gebruiken wij eHerkenning. Met eHerkenning identificeert u zich veilig en eenvoudig online. Het grote gemak is dat u met eHerkenning bij meerdere organisaties kunt inloggen. U hoeft dus minder wachtwoorden te onthouden. Veilig, makkelijk en betrouwbaar. Ga voor meer informatie naar www.eherkenning.nl."
			},
			browserCompatibility: "De pensioenportalen van Scildon zijn geoptimaliseerd voor de meest recente versies van de volgende webbrowsers: Google Chrome, MS Edge en Mozilla Firefox."
		},
		activateAccount: {
			email: {
				emailAddress: "E-mailadres",
				title: "Vul uw e-mailadres in",
				body: "Vul uw e-mailadres in. U ontvangt vervolgens een e-mail met een verificatiecode."
			},
			usernamePassword: {
				title: "Kies een gebruikersnaam en wachtwoord",
				body: "U gaat nu een gebruikersnaam en wachtwoord instellen. Dit wachtwoord moet minimaal 14 karakters lang zijn. Dit zijn uw persoonlijke toeganggegevens voor Scildon Online Pensioenen.",
				username: "Gebruikersnaam",
				password: "Wachtwoord",
				min: "Minimaal 14 tekens",
				usernameDuplicate: "Deze gebruikersnaam bestaat al"
			},
			qrCode: {
				title: "Stel de tweestapverificatie in",
				body: "Scan de QR-code met een app die tweestapsverificatie ondersteunt. U kunt bijvoorbeeld Google Authenticator gebruiken. Na het scannen van de QR-code krijgt u een code te zien op uw mobiele telefoon. Vul deze code hieronder in.",
				scanCode: "Scan de QR-code",
				responseCode: "Response code"
			},
			nextButton: "Verder"
		},
		pageHeader: {
			logout: "uitloggen",
			menu: {
				toWork: {
					title: "Aan de slag",
					createQuote: "Maak een nieuwe offerte",
					portfolio: "Bekijk portfolio"
				},
				moreInformation: {
					title: "Meer informatie",
					aboutCollectivePension: "Over collectief pensioen"
				},
				contact: {
					title: "Contact",
					getInTouch: "Neem contact met ons op"
				}
			}
		},
		participant: {
			noPermissionToViewData: "De deelnemer heeft geen toestemming gegeven om deze informatie in te zien",
			tabs: {
				general: "Algemene gegevens",
				personal: "Persoonlijke gegevens",
				pension: "Pensioengegevens",
				investments: "Beleggingen",
				events: "Gebeurtenissen"
			},
			pensionDetails: {
				accruedPensionCapital: "Opgebouwd pensioenkapitaal",
				expectedPensionCapital: "Te bereiken pensioenkapitaal",
				deathCover: "Dekking bij overlijden",
				anwInsurance: "Anw-hiaatverzekering",
				partnerPension: "Partnerpensioen",
				orphanPension: "Wezenpensioen",
				restitutionCapital: "Restitutie kapitaal bij overlijden",
				pensionGoal: "Pensioendoel"
			},
			participantDetails: {
				participantNumber: "Deelnamenummer",
				name: "Naam",
				dateOfBirth: "Geboortedatum",
				civilStatus: "Burgerlijke staat",
				civilStatusValues: {
					SINGLE: "Alleenstaand",
					COHABITATION: "Samenwonend",
					LEGAL_COHABITATION: "Samenwonend met contract",
					CIVIL_PARTNERSHIP: "Geregistreerd partnerschap",
					MARRIED: "Gehuwd"
				},
				startDateEmployment: "Startdatum dienstverband",
				salary: "Salaris",
				parttimePercentage: "FTE",
				toOverview: "Terug naar overzicht deelnemers"
			},
			participantInvestments: {
				empty: "Er zijn nog geen deelnemers",
				riskProfile: "Risicoprofiel",
				riskProfileValues: {
					NONE: "Geen",
					LOW: "Conservatef",
					NEUTRAL: "Neutraal",
					HIGH: "Ambitieus",
					UNKNOWN: "Onbekend",
					FREE_INVESTMENTS: "Vrij beleggen"
				},
				determinationDateRiskProfile: "Datum vaststelling risicoprofiel",
				preferredBenefit: "Voorkeursuitkering",
				preferredBenefitValues: {
					FIXED: "Vast",
					VARIABLE: "Variabel",
					UNKNOWN: "Onbekend"
				},
				determinationDatePreferredBenefit: "Datum vaststelling voorkeursuitkering",
				currentLifecycle: "Huidige lifecycle",
				currentLifecycleInfo: "Meer informatie over de lifecycles? Klik <a target='_blank' href='https://www.scildon.nl/pensioenregeling/assetallocaties-scildon-lifecycles/'>hier</a>.",
				currentInvestmentMix: "Huidige beleggingsmix",
				column: {
					assetName: "Fondsnaam",
					units: "Units",
					assetPrice: "Koers",
					investmentValue: "Beleggingswaarde"
				}
			},
			personalDetails: {
				email: "E-mailadres",
				phoneNumber: "Telefoonnummer",
				partnerKnown: "Partner bekend",
				partnerKnownValues: {
					true: "Ja",
					false: "Nee"
				},
				childrenKnown: "Kinderen bekend",
				childrenKnownValues: {
					true: "Ja",
					false: "Nee"
				}
			},
			participantEvents: {
				customerJourney: "Customer journey",
				newParticipation: "Start deelname",
				endParticipation: "Einde deelname",
				divorce: "Uit elkaar",
				riskProfile: "Check risicoprofiel",
				preparePension: "Voorbereiden op pensioen",
				planPension: "Pensioen plannen",
				investFreely: "Vrij beleggen",
				saveUp: "Bijsparen",
				requests: "Aanvragen",
				changeAnwGap: "Wijziging ANW hiaatpensioen",
				requestIncomingValueTransfer: "Aanvraag inkomende waardeoverdracht",
				signUpPartner: "Partner aanmelden",
				divorceDivision: "Verdeling scheiding doorgeven",
				changedInvestmentMix: "Wijziging belegginsmix",
				saveUpOnce: "Eenmalig bijsparen",
				changedSaveUp: "Wijziging bijsparen"
			}
		},
		forgotPassword: {
			title: "Wachtwoord vergeten",
			invalidEmail: "Het e-mailadres is niet geldig",
			form: {
				description: "Vul uw gebruikersnaam in. U ontvangt vervolgens een e-mail met een verificatiecode.",
				email: "Gebruikersnaam",
				submit: "Verzend"
			},
			changePassword: {
				description: "U kunt nu een nieuw wachtwoord instellen. Dit wachtwoord moet minimaal 14 karakters lang zijn.",
				password: "Wachtwoord",
				submit: "Wijzig",
				min: "Minimaal 14 tekens"
			},
			success: "Uw wachtwoord is gewijzigd. U kunt inloggen met uw nieuwe inloggegevens."
		},
		forgotUsername: {
			title: "Gebruikersnaam vergeten",
			invalidEmail: "Het e-mailadres is niet geldig",
			form: {
				description: "Vul uw e-mailadres in. U ontvangt vervolgens een e-mail met de gebruikersnaam dat bij ons bekend is.",
				email: "E-mailadres",
				submit: "Verzend"
			},
			changeUsername: {
				description: "U kunt nu een nieuwe gebruikersnaam instellen.",
				username: "Gebruikersnaam",
				errorUsernameInUse: "Deze gebruikersnaam bestaat al",
				submit: "Wijzig"
			},
			success: "Uw gebruikersnaam is naar het opgegeven emailadres verstuurd."
		}
	}
}

export default options
