
	import VueModel from "@lib/model/VueModel"
	import Component from "vue-class-component"
	import { Emit } from "vue-property-decorator"

	import IcsIcon from "custom/ui/IcsIcon.vue"
	import IconLabel from "@components/supplemental/IconLabel.vue"
	import ActionButton from "@components/button/ActionButton.vue"
	import Badge from "@components/supplemental/Badge.vue"
	import Card from "@components/panel/Card.vue"
	import LinkButton from "@components/button/LinkButton.vue"
	import Modal from "@components/modal/Modal.vue"
	import globalEventBus from "@lib/vue/eventBus"

	@Component({
		components: {
			ActionButton,
			Badge,
			Card,
			IconLabel,
			IcsIcon,
			LinkButton,
			Modal
		}
	})
	export default class MenuModal extends VueModel {
		readonly baseTranslationKey = "pageHeader.menu"

		open = false

		@Emit("opened")
		opened(): void {
			this.open = true
		}

		@Emit("closed")
		closed(): void {
			this.open = false
		}

		mounted(): void {
			globalEventBus.on("menuModal", () => {
				if (this.open) {
					(this.$refs.modal as Modal).close()
					this.open = false
				} else {
					(this.$refs.modal as Modal).display()
					this.open = true
				}
			})
		}
	}
