import Vue from "vue"

export enum ToastType {
	SUCCESS = "success",
	ERROR = "error",
	INFO = "info"
}

export interface Toast {
	title: string
	type: ToastType,
	durationMs: number
}

export interface ToastConfig {
	title: string
	durationMs?: number
}

export const SHOW_TOAST = "SHOW_TOAST"
export const eventBus = new Vue()

class Toaster {
	private readonly defaultDuration = 3000

	success(config: ToastConfig): void {
		const toast: Toast = {
			title: config.title,
			type: ToastType.SUCCESS,
			durationMs: config.durationMs || this.defaultDuration
		}

		eventBus.$emit(SHOW_TOAST, toast)
	}

	error(config: ToastConfig): void {
		const toast: Toast = {
			title: config.title,
			type: ToastType.ERROR,
			durationMs: config.durationMs || this.defaultDuration
		}

		eventBus.$emit(SHOW_TOAST, toast)
	}

	info(config: ToastConfig): void {
		const toast: Toast = {
			title: config.title,
			type: ToastType.INFO,
			durationMs: config.durationMs || this.defaultDuration
		}

		eventBus.$emit(SHOW_TOAST, toast)
	}
}

export default new Toaster()
