import { ObjectRules, Datatype } from "@lib/types/validation"
import { RequestProductModel, Address, ParticipantsDataChannel } from "./types"
import { email } from "@lib/validation/formats"
import { enumConstant } from "@lib/import/convert"
import { postalCode } from "store/validations"

export interface RequestProductContext {
	postalAddressRequired: boolean
}

const getAddressFields: (postalAddress: boolean) => ObjectRules<Address, RequestProductContext> = (postalAdress: boolean = false) => ({
	street: {
		required: context => postalAdress ? context.postalAddressRequired : true,
		type: Datatype.STRING
	},
	number: {
		required: context => postalAdress ? context.postalAddressRequired : true,
		type: Datatype.STRING
	},
	postalCode: {
		required: context => postalAdress ? context.postalAddressRequired : true,
		type: Datatype.STRING,
		custom: {
			valid: postalCode
		}
	},
	city: {
		required: context => postalAdress ? context.postalAddressRequired : true,
		type: Datatype.STRING
	}
})

export const rules: ObjectRules<RequestProductModel, RequestProductContext> = {
	id: {
		required: false,
		type: Datatype.STRING
	},
	company: {
		required: true,
		type: Datatype.OBJECT,
		fields: {
			name: {
				required: false,
				type: Datatype.STRING
			},
			address: {
				required: true,
				type: Datatype.OBJECT,
				fields: {
					business: {
						required: true,
						type: Datatype.OBJECT,
						fields: getAddressFields(false)
					},
					postal: {
						required: false,
						type: Datatype.OBJECT,
						fields: getAddressFields(true)
					}
				}
			},
			contact: {
				required: true,
				type: Datatype.OBJECT,
				fields: {
					initials: {
						required: true,
						type: Datatype.STRING
					},
					infix: {
						required: false,
						type: Datatype.STRING
					},
					surname: {
						required: true,
						type: Datatype.STRING
					},
					phoneNumber: {
						required: false,
						type: Datatype.STRING
					},
					email: {
						required: true,
						type: Datatype.STRING,
						custom: {
							valid: email
						}
					}
				}
			},
			chamberOfCommerceNumber: {
				required: true,
				type: Datatype.STRING,
				pattern: /(^[0-9]{8}$)|(^[0-9]{12}$)/
			},
			wageTaxNumber: {
				required: true,
				type: Datatype.STRING,
				custom: {
					valid(value: string): boolean {
						if (!/^[0-9]{9}L[0-9]{2}$/.test(value)) {
							return false
						}

						const numberParts: Array<string> = value.split("L")
						if (numberParts[0].substr(0, 3) === "000") {
							// First 3 numbers may not be 000
							return false
						}

						if (numberParts[1] === "00") {
							// Last 2 numbers may not be 00
							return false
						}

						// Unfortunately the last number in the array should be -1 instead of 1, otherwise i could just use the index of the .map directly
						const multiplications: Array<number> = [9, 8, 7, 6, 5, 4, 3, 2, -1]
						const cumulative: number = numberParts[0].split("")
							.map((val, index) => multiplications[index] * parseInt(val, 10))
							.reduce((cumulativeVal, val) => cumulativeVal + val)
						return cumulative % 11 === 0
					}
				}
			}
		}
	},
	participantsDataChannel: {
		required: true,
		type: enumConstant(ParticipantsDataChannel)
	}
}
