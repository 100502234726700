import { ActionTree, ActionContext } from "vuex"
import { RootState } from "store/index"
import { User, UserState } from "./types"
import { authenticationApi as api } from "api/index"
import { resources } from "api/resources"
import convertUser from "./conversion"
import { USER_MUTATIONS } from "."

type Context = ActionContext<UserState, RootState>

const userResources = resources.user

const actions: ActionTree<UserState, RootState> = {
	async getCurrentUser(context: Context): Promise<void> {
		const response = await api.get(userResources.getCurrentUser).response
		const user = convertUser(await response.json())

		context.commit(USER_MUTATIONS.HYDRATE, user)
	},
	async updateCurrentUser(context: Context, payload: User): Promise<void> {
		const response = await api.put(userResources.updateUser, payload).response
		const user = convertUser(await response.json())

		context.commit(USER_MUTATIONS.HYDRATE, user)
	},
	resetErrorState(context: Context): void {
		context.commit(USER_MUTATIONS.SET_ERROR, false)
	}
}

export default actions
