
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch, Emit } from "vue-property-decorator"
	import { isArray, isUndefined } from "lodash-es"

	interface FileUpload extends EventTarget {
		files: FileList
	}

	@Component
	export default class IcsFileUpload extends Vue {
		@Prop({ type: String, default: "*.*" }) accepted!: string
		@Prop({ type: Boolean, default: false }) multiple!: boolean
		@Prop({ type: Boolean, default: false }) noLabel!: boolean
		@Prop({ type: Boolean, default: false }) readonly!: boolean
		@Prop({ type: Number, default: 43 }) maxFileNameLength!: number
		@Prop() value: Array<File> | File | undefined

		private readonly INFINITE_FILE_NAME_LENGTH_INDICATOR = -1

		internalValue: Array<File> = []

		@Watch("value", { immediate: true })
		valueChange(newValue: Array<File> | File | undefined): void {
			this.internalValue = isUndefined(newValue) ? [] : isArray(newValue) ? newValue : [newValue]
		}

		@Emit("input")
		@Emit("change")
		onChange(event: Event): Array<File> | File | undefined {
			this.internalValue = this.extractFiles(event.target as FileUpload)

			return this.multiple ? this.internalValue : this.internalValue[0]
		}

		get fileNames(): string {
			const combined = this.internalValue.map(file => file.name).join(", ")
			return this.shortenFileName(combined)
		}

		private extractFiles(target: FileUpload): Array<File> {
			return Array.from(target.files)
		}

		private shortenFileName(name: string): string {
			if (this.maxFileNameLength === this.INFINITE_FILE_NAME_LENGTH_INDICATOR || name.length < this.maxFileNameLength) {
				return name
			}

			const overflowLength = 3
			const textLength = (this.maxFileNameLength - overflowLength) / 2

			const start = name.substring(0, textLength)
			const end = name.substring(name.length - textLength, name.length)
			return `${ start }...${ end }`
		}
	}
