import { resources } from "api/resources"
import { Module } from "vuex"
import { MutationType, singletonMutations } from "@lib/model/store/mutations"
import { api } from "api/index"
import { RootState } from "store/index"

import convert from "./conversion"
import { AgreementConstraints, AnwGapConstraints } from "./types"
import { ProductFrameworkType } from "api/models/products"

export interface AgreementConstraintsPayload {
	agreementId: string,
	date?: string
}

const constraintsModule: Module<AgreementConstraints, RootState> = {
	state: {
		strict: false,
		id: undefined,
		productType: ProductFrameworkType.WTP,
		startDate: {
			boundaries: {
				minimum: undefined,
				maximum: undefined
			}
		},
		enrollmentAge: {
			default: undefined,
			boundaries: {
				minimum: undefined,
				maximum: undefined
			}
		},
		pensionAge: {
			default: undefined,
			boundaries: {
				minimum: undefined,
				maximum: undefined
			}
		},
		franchise: {
			default: undefined,
			options: [],
			boundaries: {
				minimum: undefined,
				maximum: undefined
			}
		},
		maximumSalary: {
			options: [],
			boundaries: {
				minimum: undefined,
				maximum: undefined
			}
		},
		payrollPeriod: {
			default: undefined,
			options: []
		},
		paymentTerm: {
			default: undefined,
			options: []
		},
		pensionableSalaryComponents: {
			default: [],
			options: []
		},
		scale: {
			type: {
				default: undefined,
				options: []
			},
			ageDependentOptions: {
				values: {
					default: undefined,
					options: []
				},
				percentages: {
					"2.0": [],
					// tslint:disable-next-line: object-literal-key-quotes
					"2.5": [],
					"3.0": [],
					"4.0": []
				}
			},
			transitionalArrangementOptions: {
				default: undefined,
				options: []
			},
			ageDependentUtilizationRate: {
				default: undefined,
				boundaries: {
					minimum: undefined,
					maximum: undefined
				}
			},
			fixedBasePremium: {
				default: undefined,
				boundaries: {
					minimum: undefined,
					maximum: undefined
				}
			},
			fixedPremiumUtilizationRate: {
				default: undefined,
				boundaries: {
					minimum: undefined,
					maximum: undefined
				}
			}
		},
		investmentFreedom: {
			default: undefined,
			options: []
		},
		survivorsPension: {
			type: {
				default: undefined,
				options: []
			},
			partnerAccrual: {
				boundaries: {
					minimum: undefined,
					maximum: undefined
				}
			},
			orphanAccrual: {
				boundaries: {
					minimum: undefined,
					maximum: undefined
				}
			},
			indexation: {
				default: undefined,
				options: []
			},
			yearsOfServiceDetermination: {
				options: []
			}
		},
		amountOfParticipants: {
			boundaries: {
				minimum: undefined,
				maximum: 50
			}
		},
		financialYield: {
			default: undefined,
			options: [],
			boundaries: {
				minimum: undefined,
				maximum: undefined
			}
		},
		employeeContributionPercentage: {
			boundaries: {
				minimum: undefined,
				maximum: undefined
			}
		},
		insuranceConstraints: {
			anwGap: {
				coverage: [],
				indexation: {
					options: []
				}
			}
		}
	},
	mutations: {
		strict(state, strict: boolean): void {
			state.strict = strict
		},
		...singletonMutations()
	},
	actions: {
		async loadByAgreementId(context, { agreementId, date }: AgreementConstraintsPayload): Promise<void> {
			const data = await api.getJson(resources.agreement.constraints(agreementId, date))
			const constraints = convert(data)
			context.commit(MutationType.HYDRATE, constraints)
		}
	},
	getters: {
		state(state: AgreementConstraints): AgreementConstraints {
			return state
		},
		anwGapConstraints(state: AgreementConstraints): AnwGapConstraints {
			return state.insuranceConstraints.anwGap
		}
	},
	namespaced: true
}

export default constraintsModule
